import { Injectable } from '@angular/core'
import { ArtistId } from '../models/artist.model'
import { AngularFireFunctions } from '@angular/fire/functions'
import { Observable } from 'rxjs'
import { HomepagesUser } from '../models/homepagesUser.model'
import * as moment from 'moment'

interface Criteria {
  artistId: ArtistId
  from: string
  to: string
}

@Injectable({
  providedIn: 'root',
})
export class HomepagesUserGraphService {
  constructor(private functions: AngularFireFunctions) {}

  findHomepagesUserGraph(artistId: ArtistId, from: Date, to: Date): Observable<HomepagesUser[]> {
    return this.functions.httpsCallable<Criteria, HomepagesUser[]>('findHomepagesUserGraph')({
      artistId,
      from: moment(from).format('YYYY-MM-DD'),
      to: moment(to).format('YYYY-MM-DD'),
    })
  }
}
