import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as fromArtist from '../reducers/artist.reducer'
import * as fromFavorite from '../reducers/favorite.reducer'
import { Artist } from 'src/app/models/artist.model'

export const getArtistState = createFeatureSelector<fromArtist.State>('artists')

export const getArtists = createSelector(
  getArtistState,
  state => state.artists
)

export const getSelectedArtist = createSelector(
  getArtistState,
  state => {
    let history = JSON.parse(localStorage.getItem('artistHistory'))
    if (!history) {
      history = []
    }
    if (state.selectedArtist && !history.some(h => h.globalId === state.selectedArtist.globalId)) {
      history.push(state.selectedArtist)
      if (history.length > 6) {
        history.shift()
      }
    }
    localStorage.setItem('artistHistory', JSON.stringify(history))
    return state.selectedArtist
  }
)

export const getLoading = createSelector(
  getArtistState,
  state => state.loading
)

export const getLoaded = createSelector(
  getArtistState,
  state => state.loaded
)

export const getArtistsLoading = createSelector(
  getArtistState,
  state => state.artistsLoading
)

export const getArtistsLoaded = createSelector(
  getArtistState,
  state => state.artistsLoaded
)

export const getNoArtistsFound = createSelector(
  getArtistState,
  state => state.artistsLoaded && state.artists.length === 0
)

export const getFavoritesState = createFeatureSelector<fromFavorite.State>('favorites')
export const getFavoritesArtistByGenre = createSelector(
  getFavoritesState,
  (state): [string, Artist[]][] => {
    const favoritesByGenre = state.favorites.reduce((acc, artist) => {
      const genre = artist.genre
      acc.set(genre, acc.has(genre) ? [...acc.get(genre), artist] : [artist])
      return acc
    }, new Map<string, Artist[]>())

    const reserved = ['邦楽', '洋楽', 'クラシック', 'ジャズ', 'ディズニー']

    const reservedFavorites: [string, Artist[]][] = reserved.map(key => [
      key,
      favoritesByGenre.get(key) || [],
    ])

    reserved.forEach(key => favoritesByGenre.delete(key))

    return reservedFavorites
      .concat(Array.from(favoritesByGenre.entries()))
      .map(([key, values]) => [
        key,
        values.sort((a, b) => (a.sortingName > b.sortingName ? 1 : -1)),
      ])
  }
)
