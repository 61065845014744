import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core'
import { TrackSale } from 'src/app/models/track-sale.model'
import { environment } from 'src/environments/environment'
import { NgModule } from '@angular/core'

@Component({
  selector: 'app-track-sale',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <mat-card>
      <div
        *ngIf="!loading && !trackSales[0].title"
        class="no-product"
        fxLayout
        fxLayoutAlign="center center"
      >
        リリース済みのトラックはありません。
      </div>
      <div fxLayout *ngIf="loading || trackSales[0].title">
        <div fxFlex="1 1 auto" fxLayout="column" fxLayoutGap="16px">
          <div fxLayout fxLayoutAlign="space-between" fxLayoutGap="16px">
            <app-data-field legend="曲名" fxFlex="1 1 30%">
              <div *ngFor="let trackSale of trackSales">
                <div
                  class="trend-item"
                  fxLayout="row wrap"
                  fxLayoutAlign="start baseline"
                  fxLayoutGap="8px"
                >
                  <ng-container *ngIf="!loading; else inProgress">
                    <div class="trend-item-title">
                      {{ trackSale.title }}
                    </div>
                  </ng-container>
                </div>
              </div>
            </app-data-field>
            <app-data-field legend="売上（NET）" fxFlex="1 1 16%">
              <div *ngFor="let trackSale of trackSales">
                <div
                  class="trend-item"
                  fxLayout="row wrap"
                  fxLayoutAlign="start baseline"
                  fxLayoutGap="8px"
                >
                  <ng-container *ngIf="!loading; else inProgress">
                    <div class="trend-item-amount">
                      {{ trackSale.salesCurrencyAmount | number }}
                    </div>
                    <div fxLayout fxLayoutAlign="start center">
                      <ng-container *ngIf="trackSale.salesCurrencyAmountRate !== null; else noRate">
                        <div>{{ trackSale.salesCurrencyAmountRate | number: '1.0-0' }}%</div>
                        <mat-icon *ngIf="trackSale.salesCurrencyAmountRate > 100"
                          >trending_up</mat-icon
                        >
                        <mat-icon *ngIf="trackSale.salesCurrencyAmountRate < 100"
                          >trending_down</mat-icon
                        >
                      </ng-container>
                    </div>
                  </ng-container>
                </div>
              </div>
            </app-data-field>
            <app-data-field legend="アルバム換算数" fxFlex="1 1 16%">
              <div *ngFor="let trackSale of trackSales">
                <div
                  class="trend-item"
                  fxLayout="row wrap"
                  fxLayoutAlign="start baseline"
                  fxLayoutGap="8px"
                >
                  <ng-container *ngIf="!loading; else inProgress">
                    <div class="trend-item-amount">
                      {{ trackSale.trackAdjustedUnits | number }}
                    </div>
                    <div fxLayout fxLayoutAlign="start center">
                      <ng-container *ngIf="trackSale.trackAdjustedUnitsRate !== null; else noRate">
                        <div>{{ trackSale.trackAdjustedUnitsRate | number: '1.0-0' }}%</div>
                        <mat-icon *ngIf="trackSale.trackAdjustedUnitsRate > 100"
                          >trending_up</mat-icon
                        >
                        <mat-icon *ngIf="trackSale.trackAdjustedUnitsRate < 100"
                          >trending_down</mat-icon
                        >
                      </ng-container>
                    </div>
                  </ng-container>
                </div>
              </div>
            </app-data-field>
            <app-data-field legend="トラックダウンロード数" fxFlex="1 1 16%">
              <div *ngFor="let trackSale of trackSales">
                <div
                  class="trend-item"
                  fxLayout="row wrap"
                  fxLayoutAlign="start baseline"
                  fxLayoutGap="8px"
                >
                  <ng-container *ngIf="!loading; else inProgress">
                    <div class="trend-item-amount">
                      {{ trackSale.trackDownloads | number }}
                    </div>
                    <div fxLayout fxLayoutAlign="start center">
                      <ng-container *ngIf="trackSale.trackDownloadsRate !== null; else noRate">
                        <div>{{ trackSale.trackDownloadsRate | number: '1.0-0' }}%</div>
                        <mat-icon *ngIf="trackSale.trackDownloadsRate > 100">trending_up</mat-icon>
                        <mat-icon *ngIf="trackSale.trackDownloadsRate < 100"
                          >trending_down</mat-icon
                        >
                      </ng-container>
                    </div>
                  </ng-container>
                </div>
              </div>
            </app-data-field>
            <app-data-field legend="ストリーム数" fxFlex="1 1 16%">
              <div *ngFor="let trackSale of trackSales">
                <div
                  class="trend-item"
                  fxLayout="row wrap"
                  fxLayoutAlign="start baseline"
                  fxLayoutGap="8px"
                >
                  <ng-container *ngIf="!loading; else inProgress">
                    <div class="trend-item-amount">
                      {{ trackSale.streams | number }}
                    </div>
                    <div fxLayout fxLayoutAlign="start center">
                      <ng-container *ngIf="trackSale.streamsRate !== null; else noRate">
                        <div>{{ trackSale.streamsRate | number: '1.0-0' }}%</div>
                        <mat-icon *ngIf="trackSale.streamsRate > 100">trending_up</mat-icon>
                        <mat-icon *ngIf="trackSale.streamsRate < 100">trending_down</mat-icon>
                      </ng-container>
                    </div>
                  </ng-container>
                </div>
              </div>
            </app-data-field>
          </div>
        </div>
      </div>
    </mat-card>

    <ng-template #inProgress>
      <app-music-note></app-music-note>
    </ng-template>

    <ng-template #noRate>
      <div>- %</div>
    </ng-template>
  `,
  styleUrls: ['./track-sale.component.scss'],
})
export class TrackSaleComponent implements OnInit {
  @Input()
  trackSales: TrackSale

  @Input()
  loading = true

  constructor() {}

  ngOnInit() {}
}
