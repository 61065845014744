import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as fromDownloadUnitGraph from '../reducers/download-unit-graph.reducer'
import { getCriteriaFrom, getCriteriaTo } from './criteria.selector'
import * as moment from 'moment'

export const getDownloadUnitGraphState = createFeatureSelector<fromDownloadUnitGraph.State>(
  'downloadUnitsGraph'
)

export const getDownloadUnitsGraph = createSelector(
  getDownloadUnitGraphState,
  state => state.downloadUnits
)

export const getLoading = createSelector(
  getDownloadUnitGraphState,
  state => state.loading
)

export const getLoaded = createSelector(
  getDownloadUnitGraphState,
  state => state.loaded
)

export const getDounloadsCsv = createSelector(
  getDownloadUnitGraphState,
  getCriteriaFrom,
  getCriteriaTo,
  (state, from, to) => {
    const data = []
    data.push([
      '集計期間',
      moment(from).format('YYYY-MM-DD') + ' ~ ' + moment(to).format('YYYY-MM-DD'),
    ])
    data.push([])
    data.push(['日付'].concat([...new Set(state.downloadUnits.map(item => item.subAccountName))]))
    const charts = convert2ChartData(state.downloadUnits)
    charts.map(item => data.push(item))
    return data
  }
)

function convert2ChartData(obj: any[]): any[] {
  const dates = [...new Set(obj.map(item => item.transactionDate))]
  const column = []
  dates.forEach(d => {
    const data: any[] = [d]
    column.push(data)
    obj.map(units => {
      if (d === units.transactionDate) {
        data.push(units.units)
      }
    })
  })
  return column
}
