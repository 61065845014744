import { Action, createReducer, on } from '@ngrx/store'
import { StreamsBySubAccount } from '../models/streams-by-sub-account.model'
import { refreshData } from '../actions/criteria.actions'
import {
  loadStreamsGraphsSuccess,
  loadStreamsGraphsFailure,
} from '../actions/streams-graph.actions'

export interface State {
  streams: StreamsBySubAccount[]
  loading: boolean
  loaded: boolean
}

export const initialState: State = { streams: [], loading: false, loaded: false }

const streamsGraphReducer = createReducer(
  initialState,
  on(refreshData, (state, action) => ({ ...state, loading: true, loaded: false })),
  on(loadStreamsGraphsSuccess, (state, action) => ({
    streams: action.streams,
    loading: false,
    loaded: true,
  })),
  on(loadStreamsGraphsFailure, (state, action) => state)
)

export function reducer(state: State | undefined, action: Action) {
  return streamsGraphReducer(state, action)
}
