import { Component, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/reducers'
import { Artist } from 'src/app/models/artist.model'
import { getSelectedArtist } from 'src/app/selectors/artist.selector'
import {
  getCriteriaFrom,
  getCriteriaTo,
  getCriteriaPreviousFrom,
  getCriteriaPreviousTo,
  getCriteriaFromBehindOneDay,
} from 'src/app/selectors/criteria.selector'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { TrackSale } from 'src/app/models/track-sale.model'
import {
  getTrackSales,
  getTrackSaleLoading,
  getTrackSaleLoaded,
  getTrackTrendsCsv,
} from 'src/app/selectors/track-sale.selector'
import { Title } from '@angular/platform-browser'
import {
  getDataCollectByStreams,
  getDataCollectByDownloads,
  getDataCollectLoading,
} from 'src/app/selectors/data-collections.selector'

@Component({
  selector: 'app-sp-track-sales',
  templateUrl: './sp-track-sales.component.html',
  styleUrls: ['./sp-track-sales.component.scss'],
})
export class SpTrackSalesComponent implements OnInit {
  criteriaFrom$: Observable<Date>
  criteriaTo$: Observable<Date>
  criteriaPreviousFrom$: Observable<Date>
  criteriaPreviousTo$: Observable<Date>
  criteriaFromBehindOneDay$: Observable<Date>

  trackSales$: Observable<TrackSale[]>
  loading$: Observable<boolean>
  loaded$: Observable<boolean>
  today = new Date()

  csvId = 'trend'
  csvFileName = '直近 5 トラック動向'
  csvData$: Observable<any[]>
  csvLoading$: Observable<boolean>

  selectedArtist$: Observable<Artist>

  dataCollectionsStreams$: Observable<boolean>
  dataCollectionsDownloads$: Observable<boolean>
  dataCollectLoading$: Observable<boolean>

  constructor(private store: Store<AppState>, private titleService: Title) {}

  ngOnInit() {
    this.criteriaFrom$ = this.store.select(getCriteriaFrom)
    this.criteriaTo$ = this.store.select(getCriteriaTo)
    this.criteriaPreviousFrom$ = this.store.select(getCriteriaPreviousFrom)
    this.criteriaPreviousTo$ = this.store.select(getCriteriaPreviousTo)
    this.criteriaFromBehindOneDay$ = this.store.select(getCriteriaFromBehindOneDay)

    this.selectedArtist$ = this.store.select(getSelectedArtist)

    this.trackSales$ = this.store.select(getTrackSales)
    this.loading$ = this.store.select(getTrackSaleLoading)
    this.loaded$ = this.store.select(getTrackSaleLoaded)
    this.csvData$ = this.store.select(getTrackTrendsCsv)
    this.selectedArtist$ = this.store.select(getSelectedArtist)
    this.selectedArtist$.subscribe(artist =>
      this.titleService.setTitle(artist.nameMain + ' | ' + environment.title)
    )

    this.dataCollectionsStreams$ = this.store.select(getDataCollectByStreams)
    this.dataCollectionsDownloads$ = this.store.select(getDataCollectByDownloads)
    this.dataCollectLoading$ = this.store.select(getDataCollectLoading)
  }
}
