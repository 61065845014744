import { Action, createReducer, on } from '@ngrx/store'
import { PrAnalyzerGraph } from '../models/pr-analyzer-graph.model'
import { refreshData } from '../actions/criteria.actions'
import {
  loadPrAnalyzerGraphsSuccess,
  loadPrAnalyzerGraphsFailure,
} from '../actions/pr-analyzer-graph.actions'

export interface State {
  prAnalyzer: PrAnalyzerGraph[]
  loading: boolean
  loaded: boolean
}

export const initialState: State = { prAnalyzer: [], loading: false, loaded: false }

const prAnalyzerGraphReducer = createReducer(
  initialState,
  on(refreshData, (state, action) => ({ ...state, loading: true, loaded: false })),
  on(loadPrAnalyzerGraphsSuccess, (state, action) => ({
    prAnalyzer: action.prAnalyzer,
    loading: false,
    loaded: true,
  })),
  on(loadPrAnalyzerGraphsFailure, (state, action) => state)
)

export function reducer(state: State | undefined, action: Action) {
  return prAnalyzerGraphReducer(state, action)
}
