import * as fromUnitsGraph from '../reducers/units-graph.reducer'
import { createSelector, createFeatureSelector } from '@ngrx/store'
import { getCriteriaFrom, getCriteriaTo } from './criteria.selector'
import * as moment from 'moment'

export const getUnitsGraph = createFeatureSelector<fromUnitsGraph.State>('unitsGraph')

export const getUnits = createSelector(
  getUnitsGraph,
  state => state.units
)

export const getLoading = createSelector(
  getUnitsGraph,
  state => state.loading
)

export const getLoaded = createSelector(
  getUnitsGraph,
  state => state.loaded
)

export const getUnitsCsv = createSelector(
  getUnitsGraph,
  getCriteriaFrom,
  getCriteriaTo,
  (state, from, to) => {
    const data = []
    data.push([
      '集計期間',
      moment(from).format('YYYY-MM-DD') + ' ~ ' + moment(to).format('YYYY-MM-DD'),
    ])
    data.push([])
    data.push(['日付', 'フィジカル消化数（実売数）', 'ストリーム数', 'ダウンロード数'])
    state.units.map(item => {
      data.push([item.transactionDate, item.pos, item.streams, item.downloads])
    })
    return data
  }
)
