import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { PrAnalyzerGraphService } from '../services/pr-analyzer-graph.service'
import { refreshData } from '../actions/criteria.actions'
import { switchMap, catchError, map } from 'rxjs/operators'
import {
  loadPrAnalyzerGraphsSuccess,
  loadPrAnalyzerGraphsFailure,
} from '../actions/pr-analyzer-graph.actions'
import { of } from 'rxjs'

@Injectable()
export class PrAnalyzerGraphEffects {
  constructor(private actions$: Actions, private service: PrAnalyzerGraphService) {}
  loadPrAnalyzerGraph$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshData),
      switchMap(action =>
        this.service.findPrAnalyzerGraph(action.artistId, action.from, action.to).pipe(
          map(prAnalyzer => loadPrAnalyzerGraphsSuccess({ prAnalyzer })),
          catchError(error => of(loadPrAnalyzerGraphsFailure({ error })))
        )
      )
    )
  )
}
