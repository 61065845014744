import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/reducers'
import { AudienceInfo } from 'src/app/models/audience.model'
import { GoogleChartComponent } from 'angular-google-charts'
import { isEventDrawerOpened } from 'src/app/selectors/shell.selector'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-audience-pie-chart',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <mat-card>
      <mat-list>
        <div #container>
          <div fxLayout="row wrap" fxLayoutGap="8px" fxLayoutAlign="start center">
            <div mat-subheader>{{ chartName }}</div>
            <div fxFlex="1 1 auto"></div>
            <mat-icon class="help" *ngIf="help" [matTooltip]="help">help_outline</mat-icon>
            <div fxLayoutAlign="right end">
              <ng-container *ngIf="!dataCollectLoading; else dateNoFix">
                <ng-container *ngIf="dataCollections; then dateFix; else dateNoFix"></ng-container>
              </ng-container>
            </div>
          </div>
          <div fxLayout fxLayoutAlign="center baseline">
            <google-chart
              class="chart"
              #chart
              type="PieChart"
              [data]="info"
              [options]="options"
              [height]="350"
            >
            </google-chart>
          </div>
        </div>
      </mat-list>
    </mat-card>

    <ng-template #dateFix>
      <img
        class="datafix_icon"
        matTooltip="各項目において選択した期間のデータが全部揃っている場合に、チェックマークが表示されます。"
        src="./assets/data_collections.png"
      />
    </ng-template>

    <ng-template #dateNoFix>
      <img class="datanofix_icon" src="./assets/data_collections.png" />
    </ng-template>
  `,
  host: {
    '(window:resize)': 'onResize()',
  },
  styleUrls: ['./audience-pie-chart.component.scss'],
})
export class AudiencePieChartComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('chart', { static: false })
  chart: GoogleChartComponent

  @ViewChild('container', { static: false })
  container: ElementRef

  constructor(private store: Store<AppState>) {}

  @Input()
  chartName: string

  @Input()
  help: string

  @Input()
  info: AudienceInfo[]

  @Input()
  options: string

  @Input()
  dataCollections = false

  @Input()
  dataCollectLoading = true

  private subscriptions: Subscription[] = []

  private widthOpened = false
  private widthWhenOpened = 0
  private widthWhenClosed = 0

  ngOnInit() {
    this.subscriptions.push(
      this.store.select(isEventDrawerOpened).subscribe(opened => {
        if (!this.chart) {
          return
        }
        this.widthOpened = opened
        this.chart.options.width = opened ? this.widthWhenOpened : this.widthWhenClosed
        this.chart.ngOnChanges()
      })
    )
  }

  ngAfterViewInit() {
    if (!this.container) {
      return
    }
    this.widthWhenOpened = this.container.nativeElement.offsetWidth
    this.widthWhenClosed = this.widthWhenOpened + 100 /* 出来事を開いた際のサイズを考慮 */
    if (this.chart) {
      this.chart.options.width = this.widthWhenOpened
      this.chart.ngOnChanges()
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(it => it.unsubscribe())
  }

  onResize() {
    if (!this.chart) {
      return
    }
    // リサイズ時、出来事の開閉状態によって算出が異なる
    if (this.widthOpened) {
      this.widthWhenOpened = this.container.nativeElement.offsetWidth - 200
      this.widthWhenClosed = this.widthWhenOpened + 100
      this.chart.options.width = this.widthWhenOpened
    } else {
      this.widthWhenOpened = this.container.nativeElement.offsetWidth
      this.widthWhenClosed = this.widthWhenOpened + 100
      this.chart.options.width = this.widthWhenClosed
    }
    this.chart.ngOnChanges()
  }
}
