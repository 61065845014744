import { Action, createReducer, on } from '@ngrx/store'
import * as CriteriaActions from '../actions/criteria.actions'
import * as AudienceActions from '../actions/audience.actions'
import { AudienceInfo } from '../models/audience.model'

export interface State {
  currentFrom: string
  currentTo: string
  appleMusicInfo: AudienceInfo[]
  youtubeInfo: AudienceInfo[]
  spotifyInfo: AudienceInfo[]
  spotifyRegionInfo: AudienceInfo[]
  umhpInfo: AudienceInfo[]
  umhpRegionInfo: AudienceInfo[]
  enqueteInfo: AudienceInfo[]
  newsletterInfo: AudienceInfo[]
  lineInfo: AudienceInfo[]
  lineRegionInfo: AudienceInfo[]
  loading: boolean
  loaded: boolean
}

export const initialState: State = {
  currentFrom: '',
  currentTo: '',
  appleMusicInfo: [],
  youtubeInfo: [],
  spotifyInfo: [],
  spotifyRegionInfo: [],
  umhpInfo: [],
  umhpRegionInfo: [],
  enqueteInfo: [],
  newsletterInfo: [],
  lineInfo: [],
  lineRegionInfo: [],
  loading: true,
  loaded: false,
}

const audienceReducer = createReducer(
  initialState,
  on(CriteriaActions.refreshData, (state, action) => ({ ...state, loading: true, loaded: false })),
  on(AudienceActions.loadAudienceSuccess, (state, action) => ({
    currentFrom: action.audience.currentFrom,
    currentTo: action.audience.currentTo,
    appleMusicInfo: action.audience.appleMusicInfo,
    youtubeInfo: action.audience.youtubeInfo,
    spotifyInfo: action.audience.spotifyInfo,
    spotifyRegionInfo: action.audience.spotifyRegionInfo,
    umhpInfo: action.audience.umhpInfo,
    umhpRegionInfo: action.audience.umhpRegionInfo,
    enqueteInfo: action.audience.enqueteInfo,
    newsletterInfo: action.audience.newsletterInfo,
    lineInfo: action.audience.lineInfo,
    lineRegionInfo: action.audience.lineRegionInfo,
    loading: false,
    loaded: true,
  })),
  on(AudienceActions.loadAudienceFailure, (state, action) => state)
)

export function reducer(state: State | undefined, action: Action) {
  return audienceReducer(state, action)
}
