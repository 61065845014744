import { Component, OnInit } from '@angular/core'
import { Input } from '@angular/core'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/reducers'
import { Observable } from 'rxjs'
import { CountableSourceSummary } from 'src/app/models/latest-aggregate-amounts.model'
import {
  getTwitterFollowers,
  getLoading,
} from 'src/app/selectors/latest-aggregate-amounts.selector'
import { Artist } from 'src/app/models/artist.model'
import { getSelectedArtist } from 'src/app/selectors/artist.selector'
import {
  getDataCollectByTwitterFollower,
  getDataCollectLoading,
} from 'src/app/selectors/data-collections.selector'

@Component({
  selector: 'app-aggregated-amounts-twitter-follower',
  templateUrl: './aggregated-amounts-twitter-follower.component.html',
  styleUrls: ['./aggregated-amounts-twitter-follower.component.scss'],
})
export class AggregatedAmountsTwitterFollowerComponent implements OnInit {
  twitterFollowers$: Observable<CountableSourceSummary[]>
  selectedArtist$: Observable<Artist>
  loading$: Observable<boolean>

  dataCollections$: Observable<boolean>
  dataCollectLoading$: Observable<boolean>

  constructor(private store: Store<AppState>) {}

  @Input() url: string

  ngOnInit() {
    this.twitterFollowers$ = this.store.select(getTwitterFollowers)
    this.selectedArtist$ = this.store.select(getSelectedArtist)
    this.loading$ = this.store.select(getLoading)

    this.dataCollections$ = this.store.select(getDataCollectByTwitterFollower)
    this.dataCollectLoading$ = this.store.select(getDataCollectLoading)
  }
}
