import { Injectable } from '@angular/core'
import { ArtistId } from '../models/artist.model'
import { AngularFireFunctions } from '@angular/fire/functions'
import { Observable } from 'rxjs'
import { LatestAggregateAmounts } from '../models/latest-aggregate-amounts.model'
import * as moment from 'moment'

interface Criteria {
  artistId: ArtistId
  from: string
  to: string
}

@Injectable({
  providedIn: 'root',
})
export class LatestAggregateAmountsService {
  constructor(private functions: AngularFireFunctions) {}

  findLatestAggregateAmounts(
    artistId: ArtistId,
    from: Date,
    to: Date
  ): Observable<LatestAggregateAmounts> {
    return this.functions.httpsCallable<Criteria, LatestAggregateAmounts>('findLatestValue')({
      artistId,
      from: moment(from).format('YYYY-MM-DD'),
      to: moment(to).format('YYYY-MM-DD'),
    })
  }
}
