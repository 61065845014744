import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { PeriodAggregateAmountsService } from '../services/period-aggregate-amounts.service'
import * as periodAggregateAmountsActions from '../actions/period-aggregate-amounts.actions'
import * as CriteriaActions from '../actions/criteria.actions'
import { switchMap, map, catchError } from 'rxjs/operators'
import { of } from 'rxjs'

@Injectable()
export class PeriodAggregateAmountsEffects {
  loadPeriodAggregateAmounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CriteriaActions.refreshData),
      switchMap(action =>
        this.periodAggregateAmountsService
          .findPeriodAggregateAmounts(action.artistId, action.from, action.to)
          .pipe(
            map(periodAggregateAmountsModel =>
              periodAggregateAmountsActions.loadPeriodAggregateAamountsSuccess({
                periodAggregateAmounts: periodAggregateAmountsModel,
              })
            ),
            catchError(error =>
              of(periodAggregateAmountsActions.loadPeriodAggregateAamountsFailure({ error }))
            )
          )
      )
    )
  )

  constructor(
    private actions$: Actions,
    private periodAggregateAmountsService: PeriodAggregateAmountsService
  ) {}
}
