import { Component, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/reducers'
import { Observable } from 'rxjs'
import { ProjectSale } from 'src/app/models/project-sale.model'
import {
  getProjectSales,
  getProjectSaleLoading,
  getProjectSaleLoaded,
} from 'src/app/selectors/project-sale.selector'
import { map } from 'rxjs/operators'
import { getCriteriaTo } from 'src/app/selectors/criteria.selector'

@Component({
  selector: 'app-recent-project-trend',
  templateUrl: './recent-project-trend.component.html',
  styleUrls: ['./recent-project-trend.component.scss'],
})
export class RecentProjectTrendComponent implements OnInit {
  projectSale$: Observable<ProjectSale>
  loading$: Observable<boolean>
  loaded$: Observable<boolean>
  today = new Date()

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.projectSale$ = this.store.select(getProjectSales).pipe(map(sales => sales[0]))
    this.loading$ = this.store.select(getProjectSaleLoading)
    this.loaded$ = this.store.select(getProjectSaleLoaded)
  }
}
