import { createAction, props } from '@ngrx/store'
import { ArtistId } from 'src/app/models/artist.model'

export const updateDateRange = createAction(
  '[Criteria] Update Date Range',
  props<{ from: Date; to: Date }>()
)

export const refreshData = createAction(
  '[Criteria] Refresh All Data & View',
  props<{ artistId: ArtistId; from: Date; to: Date }>()
)
