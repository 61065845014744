export type EventCategoryIds =
  | 'release'
  | 'media'
  | 'ad'
  | 'event'
  | 'sns'
  | 'push'
  | 'campaign'
  | 'ownedmedia'
  | 'other'
  | 'web'
  | 'television'

export const categoryIdToLabel: { [categoryId in EventCategoryIds]: string } = {
  release: 'リリース',
  media: 'メディア',
  ad: '広告',
  event: 'イベント',
  sns: 'SNS',
  push: 'プッシュ通知',
  campaign: 'キャンペーン',
  ownedmedia: '自社メディア',
  other: 'その他',
  web: 'メディア',
  television: 'メディア',
}

export interface Event {
  recordId: string
  categoryId: string
  date: string
  title: string
  url: string
  priorityMedia: string
  programName: string
  programCategory: string
  facebook: string
  publicationAmount: string
  toneAnalysis: string
  quantitativeConversionCost: string
  qualitativeConversionCost: string
  reachCount: string
  spread: string
  articleTitle: string
}
