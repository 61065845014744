export const lineChartStyleDefault = {
  animation: { duration: 200, startup: true },
  colors: ['#ffc107'],
  curveType: 'none',
  backgroundColor: { fill: '#FFFFFF' },
  legend: { position: 'top', maxLines: 2, textStyle: { color: '#333333' } },
  hAxis: {
    textStyle: {
      color: '#333333',
    },
  },
  vAxis: {
    textStyle: {
      color: '#333333',
    },
  },
  chartArea: {
    left: 80,
    width: '100%',
  },
}

export const lineChartStyle2Lines = {
  ...lineChartStyleDefault,
  colors: ['#ffc107', '#e040fb'],
}

export const lineChartStyle2Lines2Axes = {
  ...lineChartStyle2Lines,
  series: {
    0: { targetAxisIndex: 0 },
    1: { targetAxisIndex: 1 },
  },
}

export const lineChartStyle3Lines = {
  ...lineChartStyleDefault,
  colors: ['#ffc107', '#e040fb', '#4caf50'],
}

export const lineChartStyle4Lines = {
  ...lineChartStyleDefault,
  colors: ['#cb5393', '#00a5e7', '#00984b', '#f2cf01'],
}

export const lineChartStyle5Lines = {
  ...lineChartStyleDefault,
  colors: ['#039BE5', '#EF5350', '#FFC107', '#9CCC65', '#9C27B0'],
}

export const lineChartStyle8Lines = {
  ...lineChartStyleDefault,
  colors: ['#cb5393', '#a0c238', '#00a5e7', '#0168b3', '#d685b0', '#00984b', '#f2cf01', '#6bb6bb'],
}

export const lineChartStyle10Lines = {
  ...lineChartStyleDefault,
  colors: [
    '#ff5722',
    '#ff9800',
    '#cb5393',
    '#a0c238',
    '#00a5e7',
    '#0168b3',
    '#d685b0',
    '#00984b',
    '#f2cf01',
    '#6bb6bb',
  ],
}

export const lineChartStyle13Lines = {
  ...lineChartStyleDefault,
  legend: { position: 'top', maxLines: 2, textStyle: { color: '#333333' } },
  colors: [
    '#ff5722',
    '#ff9800',
    '#ffc107',
    '#ffeb3b',
    '#cddc39',
    '#8bc34a',
    '#4caf50',
    '#009688',
    '#00bcd4',
    '#03a9f4',
    '#2196f3',
    '#3f51b5',
    '#673ab7',
  ],
}
