import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'

import { switchMap } from 'rxjs/operators'
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service'
import { ROUTER_NAVIGATED, RouterAction, MinimalRouterStateSnapshot } from '@ngrx/router-store'

@Injectable()
export class ShellEffects {
  constructor(private actions$: Actions, private ga: GoogleAnalyticsService) {}

  loadProjectSales$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      switchMap((action: RouterAction<MinimalRouterStateSnapshot>) =>
        this.ga.onPageVisit(action.payload.event.url)
      )
    )
  )
}
