import * as fromPhysicalsGraph from '../reducers/physicals-graph.reducer'
import { createFeatureSelector, createSelector } from '@ngrx/store'
import { getCriteriaFrom, getCriteriaTo } from './criteria.selector'
import * as moment from 'moment'

export const getPhysicalsGraph = createFeatureSelector<fromPhysicalsGraph.State>('physicalsGraph')

export const getPhysicals = createSelector(
  getPhysicalsGraph,
  state => state.physicals
)

export const getLoading = createSelector(
  getPhysicalsGraph,
  state => state.loading
)

export const getLoaded = createSelector(
  getPhysicalsGraph,
  state => state.loaded
)

export const getPhysicalsCsv = createSelector(
  getPhysicalsGraph,
  getCriteriaFrom,
  getCriteriaTo,
  (state, from, to) => {
    const data = []
    data.push([
      '集計期間',
      moment(from).format('YYYY-MM-DD') + ' ~ ' + moment(to).format('YYYY-MM-DD'),
    ])
    data.push([])
    data.push(['日付'].concat([...new Set(state.physicals.map(item => item.customer))]))
    const dates = [...new Set(state.physicals.map(item => item.salesDate))]
    const column = []
    dates.forEach(d => {
      const c: any[] = [d]
      column.push(c)
      state.physicals.map(units => {
        if (d === units.salesDate) {
          c.push(units.units)
        }
      })
    })
    column.map(item => data.push(item))
    return data
  }
)
