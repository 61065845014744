import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  TemplateRef,
  ViewChild,
  OnDestroy,
  ViewChildren,
  QueryList,
} from '@angular/core'
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout'
import {
  map,
  startWith,
  distinctUntilChanged,
  tap,
  withLatestFrom,
  take,
  debounceTime,
} from 'rxjs/operators'
import { ScrollDispatcher, CdkScrollable } from '@angular/cdk/overlay'
import { Observable, Subscription } from 'rxjs'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/reducers'
import { getTitle, isArtistPage, isEventDrawerOpened } from 'src/app/selectors/shell.selector'
import { Router, NavigationEnd } from '@angular/router'
import { filter } from 'rxjs/operators'
import {
  MatSidenav,
  MatAutocompleteSelectedEvent,
  MatMenuTrigger,
  MatSidenavContainer,
} from '@angular/material'
import { openEventDrawer, closeEventDrawer } from 'src/app/actions/shell.actions'
import { unselectEventDate } from 'src/app/actions/event.actions'
import { getCriteriaFrom, getCriteriaTo } from 'src/app/selectors/criteria.selector'
import { FormControl, Validators } from '@angular/forms'
import { updateDateRange } from 'src/app/actions/criteria.actions'
import * as moment from 'moment'
import { loadArtistsNamed, selectArtist } from '../../../actions/artist.actions'
import { Artist } from 'src/app/models/artist.model'
import {
  getArtists,
  getNoArtistsFound,
  getArtistsLoading,
  getArtistsLoaded,
  getSelectedArtist,
  getLoaded,
} from 'src/app/selectors/artist.selector'
import { MatBottomSheet } from '@angular/material/bottom-sheet'
import { EventsComponent } from '../events/events.component'

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit, OnDestroy {
  @ViewChild('drawer', { static: false })
  drawer: MatSidenav

  @ViewChild('events', { static: false })
  events: MatSidenav

  @ViewChild(MatSidenavContainer, { static: false }) container: MatSidenavContainer

  @ViewChildren(MatMenuTrigger)
  searchMenuTriggers: QueryList<MatMenuTrigger>

  @Input()
  menu: TemplateRef<any>

  title$: Observable<string>
  isArtistPage$: Observable<boolean>
  isEventDrawerOpened$: Observable<boolean>

  artists$: Observable<Artist[]>
  isArtistsLoading$: Observable<boolean>
  isArtistsLoaded$: Observable<boolean>
  noArtistsFound$: Observable<boolean>

  isHandset$ = this.breakpointObserver.observe(Breakpoints.Handset).pipe(map(it => it.matches))
  // iPad の縦位置や PIXEL XL まではスマホと同様にするため、 max-width: 960px としています。iPad の横位置は PC と同等の表示です。
  isIpadVertical$ = this.breakpointObserver
    .observe('(max-width: 960px)')
    .pipe(map(it => it.matches))
  isPC$ = this.breakpointObserver.observe('(min-width: 1366px)').pipe(map(it => it.matches))
  // スマホ縦の場合
  isXSmallVertical$ = this.breakpointObserver
    .observe(Breakpoints.XSmall)
    .pipe(map(it => it.matches))

  isScrolled$ = this.scrollDispatcher.scrolled().pipe(
    map((event: CdkScrollable) => event.getElementRef().nativeElement.scrollTop),
    map(it => it > 0),
    distinctUntilChanged(),
    tap(_ => setTimeout(() => this.changeDetectorRef.detectChanges())),
    startWith(false)
  )

  criteriaFrom$: Observable<Date>
  criteriaTo$: Observable<Date>

  criteriaFromField: FormControl
  artistQuery: FormControl

  private subscriptions: Subscription[] = []

  selectedArtist$: Observable<Artist>
  loaded$: Observable<boolean>
  http: any

  constructor(
    private breakpointObserver: BreakpointObserver,
    private scrollDispatcher: ScrollDispatcher,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private store: Store<AppState>,
    private bottomSheet: MatBottomSheet
  ) {}

  ngOnInit() {
    this.title$ = this.store.select(getTitle)
    this.isArtistPage$ = this.store.select(isArtistPage)
    this.isEventDrawerOpened$ = this.store.select(isEventDrawerOpened)
    this.criteriaFrom$ = this.store.select(getCriteriaFrom)
    this.criteriaTo$ = this.store.select(getCriteriaTo)

    this.artists$ = this.store.select(getArtists)
    this.isArtistsLoading$ = this.store.select(getArtistsLoading)
    this.isArtistsLoaded$ = this.store.select(getArtistsLoaded)
    this.noArtistsFound$ = this.store.select(getNoArtistsFound)
    this.selectedArtist$ = this.store.select(getSelectedArtist)
    this.loaded$ = this.store.select(getLoaded)

    this.artistQuery = new FormControl('')

    this.subscriptions.push(
      this.criteriaFrom$.subscribe(
        it => (this.criteriaFromField = new FormControl(moment(it), [Validators.required]))
      )
    )

    this.subscriptions.push(
      this.artistQuery.valueChanges
        .pipe(
          debounceTime(500),
          distinctUntilChanged()
        )
        .subscribe(name => {
          this.store.dispatch(loadArtistsNamed({ name }))
        })
    )

    this.subscriptions.push(
      this.router.events
        .pipe(
          withLatestFrom(this.isHandset$),
          filter(([a, b]) => b && a instanceof NavigationEnd)
        )
        .subscribe(_ => this.drawer.close())
    )
  }

  ngOnDestroy() {
    this.subscriptions.forEach(it => it.unsubscribe())
  }

  private refillDateRangeFromStore(): void {
    this.criteriaFrom$
      .pipe(take(1))
      .subscribe(
        it => (this.criteriaFromField = new FormControl(moment(it), [Validators.required]))
      )
  }

  onDateRangeChange(): void {
    const from = this.criteriaFromField.value as moment.Moment
    const to = moment(from).add(6, 'days')

    if (!from || !to) {
      return
    }
  }

  openEventBottomSheet() {
    this.bottomSheet.open(EventsComponent)
  }

  toggleEventsDrawer() {
    if (this.events.opened) {
      this.store.dispatch(closeEventDrawer())
      this.store.dispatch(unselectEventDate())
    } else {
      this.store.dispatch(openEventDrawer())
    }
  }

  onDateRangeMenuClose(): void {
    if (this.criteriaFromField.pristine) {
      return
    }

    const from = this.criteriaFromField.value as moment.Moment
    const to = moment(from).add(6, 'days')

    if (!from || !to) {
      this.refillDateRangeFromStore()
      return
    }

    const now = moment()
    this.store.dispatch(
      updateDateRange({
        from: from.toDate(),
        to: to.toDate(),
      })
    )
    this.criteriaFromField.markAsPristine()
  }

  onArtistQueryClosed(): void {
    this.artistQuery.setValue('')
  }

  onArtistsResultSelected(event: MatAutocompleteSelectedEvent): void {
    const artist = event.option.value
    this.store.dispatch(selectArtist({ artist }))
    this.router.navigate(['artists', artist.globalId])
    this.searchMenuTriggers.forEach(it => it.closeMenu())
  }

  formatOptionValue(artist?: Artist): string | undefined {
    return artist ? artist.nameMain : undefined
  }

  onSearchArtistClick(): void {
    const name = this.artistQuery.value
    this.store.dispatch(loadArtistsNamed({ name }))
  }

  onInfoClick(): void {
    // tslint:disable max-line-length
    const url =
      'https://universalmusicgroup.sharepoint.com/sites/JapanPortal/Lists/Manuals/DispForm.aspx?ID=160&Source=https%3A%2F%2Funiversalmusicgroup%2Esharepoint%2Ecom%2Fsites%2FJapanPortal%2FLists%2FManuals%2FManuals%2Easpx&ContentTypeId=0x0104004A9D471BBF445B41B728A23868934862'
    // tslint:enable
    window.open(url)
  }

  downloadPDF(): void {
    const printHtml = document.getElementsByTagName('mat-sidenav-content')[0].innerHTML

    const headHtml = document.head.innerHTML
    const printStart = document.getElementById('print-container')
    if (!!printStart) {
      document.querySelector('body').removeChild(printStart)
    }

    const oHiddFrame = window.document.createElement('iframe')
    oHiddFrame.setAttribute('id', 'print-container')
    window.document.body.appendChild(oHiddFrame)
    const orgWidth = document.body.clientWidth
    oHiddFrame.style.position = 'fixed'
    oHiddFrame.style.right = '0'
    oHiddFrame.style.bottom = '0'
    oHiddFrame.style.width = '0'
    oHiddFrame.style.height = '0'
    oHiddFrame.style.border = '0'
    oHiddFrame.contentWindow.document.head.innerHTML = headHtml
    oHiddFrame.contentWindow.document.body.innerHTML = printHtml
    oHiddFrame.contentWindow.document.body.style.width = orgWidth + 'px'
    oHiddFrame.contentWindow.document.body.style.zoom = (1050 / orgWidth).toString()

    setTimeout(() => {
      oHiddFrame.contentWindow.print()
    }, 1500)
    oHiddFrame.contentWindow.close()
  }

  onInquiryClick(): void {
    // tslint:disable max-line-length
    const url = 'https://umusic.cybozu.com/k/560/edit'
    // tslint:enable
    window.open(url)
  }

  dateRangeInputFilter(date: moment.Moment): boolean {
    return (
      date.weekday() === 1 &&
      date.isBefore(
        moment()
          .startOf('day')
          .weekday(1)
      )
    ) // Monday
  }

  // Date Picker が this の値を書き換えて呼び出してしまうので、
  // this を ShellCompoent にバインドした状態で呼び出されるようにしています。
  highlightDate = (date: moment.Moment): string => {
    const from = this.criteriaFromField.value as moment.Moment
    const to = moment(from).add(6, 'days')

    let dateClass = date.isSameOrAfter(from) && date.isSameOrBefore(to) ? 'date-in-range' : ''
    if (
      dateClass === '' &&
      date.isBefore(
        moment()
          .startOf('day')
          .weekday(1)
      )
    ) {
      dateClass = date.day() === 1 ? 'selectable-monday' : ''
    }
    return dateClass
  }
}
