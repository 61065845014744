import { Component, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/reducers'
import { Observable, Subscription } from 'rxjs'
import { TrackSale } from 'src/app/models/track-sale.model'
import {
  getTrackSales,
  getTrackSaleLoading,
  getTrackSaleLoaded,
  getTrackTrendsCsv,
} from 'src/app/selectors/track-sale.selector'
import { Artist } from 'src/app/models/artist.model'
import { getSelectedArtist } from 'src/app/selectors/artist.selector'
import { getCriteriaFrom, getCriteriaTo } from 'src/app/selectors/criteria.selector'
import {
  getDataCollectByStreams,
  getDataCollectByDownloads,
  getDataCollectLoading,
} from 'src/app/selectors/data-collections.selector'
import { MediaObserver, MediaChange } from '@angular/flex-layout'

@Component({
  selector: 'app-recent-track-trends',
  templateUrl: './recent-track-trends.component.html',
  styleUrls: ['./recent-track-trends.component.scss'],
})
export class RecentTrackTrendsComponent implements OnInit {
  trackSales$: Observable<TrackSale[]>
  loading$: Observable<boolean>
  loaded$: Observable<boolean>
  today = new Date()
  criteriaFrom$: Observable<Date>
  criteriaTo$: Observable<Date>

  csvId = 'trend'
  csvFileName = 'TOP TRACK 5'
  csvData$: Observable<any[]>
  csvLoading$: Observable<boolean>
  selectedArtist$: Observable<Artist>
  dataCollectionsStreams$: Observable<boolean>
  dataCollectionsDownloads$: Observable<boolean>
  dataCollectLoading$: Observable<boolean>
  private subscriptions: Subscription[] = []

  constructor(private store: Store<AppState>, public media: MediaObserver) {
    this.subscriptions.push(media.media$.subscribe((_: MediaChange) => {}))
  }

  ngOnInit() {
    this.criteriaFrom$ = this.store.select(getCriteriaFrom)
    this.criteriaTo$ = this.store.select(getCriteriaTo)
    this.trackSales$ = this.store.select(getTrackSales)
    this.loading$ = this.store.select(getTrackSaleLoading)
    this.loaded$ = this.store.select(getTrackSaleLoaded)
    this.csvData$ = this.store.select(getTrackTrendsCsv)
    this.selectedArtist$ = this.store.select(getSelectedArtist)

    this.dataCollectionsStreams$ = this.store.select(getDataCollectByStreams)
    this.dataCollectionsDownloads$ = this.store.select(getDataCollectByDownloads)
    this.dataCollectLoading$ = this.store.select(getDataCollectLoading)
  }
}
