import { Component, OnInit } from '@angular/core'
import { Observable } from 'rxjs'
import { CountableSummary } from 'src/app/models/period-aggregate-amounts.model'
import { AppState } from 'src/app/reducers'
import { Store } from '@ngrx/store'
import {
  getSpecialUmhpPageUserviews,
  getLoading,
} from 'src/app/selectors/period-aggregate-amounts.selector'
import { getSelectedArtist } from 'src/app/selectors/artist.selector'
import { Artist } from 'src/app/models/artist.model'
import {
  getDataCollectBySpecialUmhpUserviews,
  getDataCollectLoading,
} from 'src/app/selectors/data-collections.selector'

@Component({
  selector: 'app-aggregated-amounts-special-umhp-userview',
  templateUrl: './aggregated-amounts-special-umhp-userview.component.html',
  styleUrls: ['./aggregated-amounts-special-umhp-userview.component.scss']
})
export class AggregatedAmountsSpecialUmhpUserviewComponent implements OnInit {

  specialUmhpPageUserView$: Observable<CountableSummary>
  loading$: Observable<boolean>
  selectedArtist$: Observable<Artist>

  dataCollections$: Observable<boolean>
  dataCollectLoading$: Observable<boolean>

  constructor(private store: Store<AppState>) { }

  ngOnInit() {
    this.specialUmhpPageUserView$ = this.store.select(getSpecialUmhpPageUserviews)
    this.selectedArtist$ = this.store.select(getSelectedArtist)
    this.loading$ = this.store.select(getLoading)

    this.dataCollections$ = this.store.select(getDataCollectBySpecialUmhpUserviews)
    this.dataCollectLoading$ = this.store.select(getDataCollectLoading)
  }

}
