import { Injectable } from '@angular/core'
import { Artist, ArtistId } from 'src/app/models/artist.model'
import { Observable } from 'rxjs'
import { AngularFireFunctions } from '@angular/fire/functions'

@Injectable({ providedIn: 'root' })
export class ArtistService {
  constructor(private functions: AngularFireFunctions) {}

  findArtistsNamed(name: string): Observable<Artist[]> {
    return this.functions.httpsCallable<{ name: string }, Artist[]>('findArtistLikeName')({ name })
  }

  findArtistById(artistId: ArtistId): Observable<Artist> {
    return this.functions.httpsCallable<{ artistId: string }, Artist>('findArtistById')({
      artistId,
    })
  }

  findFavoriteArtists(): Observable<Artist[]> {
    return this.functions.httpsCallable<void, Artist[]>('findFavoriteArtists')()
  }
}
