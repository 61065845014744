import { Component } from '@angular/core'
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout'
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  isHandsetLandscape$: Observable<boolean>

  constructor(private breakpointObserver: BreakpointObserver) {
    this.isHandsetLandscape$ = this.breakpointObserver
      .observe(Breakpoints.HandsetLandscape)
      .pipe(map(it => it.matches))
  }
}
