import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'

import { withLatestFrom, map } from 'rxjs/operators'

import { Store } from '@ngrx/store'
import { AppState } from 'src/app/reducers'
import { getSelectedArtist } from 'src/app/selectors/artist.selector'
import { updateDateRange, refreshData } from 'src/app/actions/criteria.actions'

@Injectable()
export class CriteriaEffects {
  private selectedArtist$ = this.store.select(getSelectedArtist)

  updateDateRange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateDateRange),
      withLatestFrom(this.selectedArtist$),
      map(([action, artist]) =>
        refreshData({
          artistId: artist.globalId,
          from: action.from,
          to: action.to,
        })
      )
    )
  )

  constructor(private actions$: Actions, private store: Store<AppState>) {}
}
