import * as fromPeriodAggregateAmounts from '../reducers/period-aggregate-amounts.reducer'
import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as moment from 'moment'
import { getCriteriaFrom, getCriteriaTo } from './criteria.selector'

export const getPeriodAggregateAmountsState = createFeatureSelector<
  fromPeriodAggregateAmounts.State
>('periodAggregateAmounts')

// フィジカル売り上げ枚数
export const getPhysicalShipments = createSelector(
  getPeriodAggregateAmountsState,
  state => state.physicalShipments
)

// フィジカル消化数
export const getPosPhysicalEstimates = createSelector(
  getPeriodAggregateAmountsState,
  state => state.posPhysicalEstimates
)

// ストリーム数
export const getStreams = createSelector(
  getPeriodAggregateAmountsState,
  state => state.streams
)

// ダウンロード数
export const getDownloads = createSelector(
  getPeriodAggregateAmountsState,
  state => state.downloads
)

// UMホームページビュー
export const getUmhpPageviews = createSelector(
  getPeriodAggregateAmountsState,
  state => state.umhpPageviews
)

// Google検索数
export const getGoogleSearch = createSelector(
  getPeriodAggregateAmountsState,
  state => state.googleSearch
)

// UM特設ページビュー
export const getSpecialUmhpPageviews = createSelector(
  getPeriodAggregateAmountsState,
  state => state.specialUmhpPageviews
)

// UMストアページビュー
export const getUmstorePageviews = createSelector(
  getPeriodAggregateAmountsState,
  state => state.umstorePageviews
)

// uDiscoverページビュー
export const getUdiscoverPageviews = createSelector(
  getPeriodAggregateAmountsState,
  state => state.udiscoverPageviews
)

// Wikipedia
export const getWikipediaPageviews = createSelector(
  getPeriodAggregateAmountsState,
  state =>
    state.wikipediaPageviews.slice().sort((a, b) => {
      return a.lang < b.lang ? 1 : -1
    })
)

// Twitterキーワード
export const getTwitterKeywords = createSelector(
  getPeriodAggregateAmountsState,
  state => state.twitterKeywords
)

// shazams
export const getShazams = createSelector(
  getPeriodAggregateAmountsState,
  state => state.shazams
)

// UMホームページユーザービュー
export const getUmhpPageUserviews = createSelector(
  getPeriodAggregateAmountsState,
  state => state.umhpUserviews
)

// UM特設ページユーザービュー
export const getSpecialUmhpPageUserviews = createSelector(
  getPeriodAggregateAmountsState,
  state => state.specialUmhpUserviews
)

export const getLoading = createSelector(
  getPeriodAggregateAmountsState,
  state => state.loading
)

export const getLoaded = createSelector(
  getPeriodAggregateAmountsState,
  state => state.loaded
)

export const getPeriodAggregatedAmounts = createSelector(
  getPeriodAggregateAmountsState,
  getCriteriaFrom,
  getCriteriaTo,
  (state, from, to) => {
    const data = []
    data.push([
      '集計期間',
      moment(from).format('YYYY-MM-DD') + ' ~ ' + moment(to).format('YYYY-MM-DD'),
    ])
    data.push([])
    data.push(['集計種別', '単位', '数量', '先週比（％）'])
    data.push(
      convert2AggregatedDataArray(
        'フィジカル売上枚数（NET）',
        '出荷数',
        state.physicalShipments.units,
        state.physicalShipments.rate,
        state.physicalShipments.hasUnits
      )
    )
    data.push(
      convert2AggregatedDataArray(
        'フィジカル消化数',
        '消化数（実売数）',
        state.posPhysicalEstimates.units,
        state.posPhysicalEstimates.rate,
        state.posPhysicalEstimates.hasUnits
      )
    )
    data.push(
      convert2AggregatedDataArray(
        'ストリーム数',
        'ストリーム数',
        state.streams.units,
        state.streams.rate,
        state.streams.hasUnits
      )
    )
    data.push(
      convert2AggregatedDataArray(
        'ダウンロード数',
        'アルバム換算数',
        state.downloads.units,
        state.downloads.rate,
        state.downloads.hasUnits
      )
    )
    data.push(
      convert2AggregatedDataArray(
        'UM ホームページページビュー',
        'ページビュー数',
        state.umhpPageviews.units,
        state.umhpPageviews.rate,
        state.umhpPageviews.hasUnits
      )
    )
    data.push(
      convert2AggregatedDataArray(
        'UMホームページユーザー数',
        'ページユーザー数',
        state.umhpUserviews.units,
        state.umhpUserviews.rate,
        state.umhpUserviews.hasUnits
      )
    )
    data.push(
      convert2AggregatedDataArray(
        'Google 検索数',
        '検索数',
        state.googleSearch.units,
        state.googleSearch.rate,
        state.googleSearch.hasUnits
      )
    )
    data.push(
      convert2AggregatedDataArray(
        'UM 特設ページビュー',
        'ページビュー数',
        state.specialUmhpPageviews.units,
        state.specialUmhpPageviews.rate,
        state.specialUmhpPageviews.hasUnits
      )
    )
    data.push(
      convert2AggregatedDataArray(
        'UM特設ページユーザー数',
        'ユーザー数',
        state.specialUmhpUserviews.units,
        state.specialUmhpUserviews.rate,
        state.specialUmhpUserviews.hasUnits
      )
    )
    data.push(
      convert2AggregatedDataArray(
        'UM ストアページビュー',
        'ページビュー数',
        state.umstorePageviews.units,
        state.umstorePageviews.rate,
        state.umstorePageviews.hasUnits
      )
    )
    data.push(
      convert2AggregatedDataArray(
        'uDiscover ページビュー',
        'ページビュー数',
        state.udiscoverPageviews.units,
        state.udiscoverPageviews.rate,
        state.udiscoverPageviews.hasUnits
      )
    )
    state.wikipediaPageviews
      .slice()
      .sort((a, b) => {
        return a.lang < b.lang ? 1 : -1
      })
      .map(item =>
        data.push(
          convert2AggregatedDataArray(
            item.lang == null
              ? 'Wikipedia'
              : 'Wikipedia ' +
              item.lang.replace('ja', '日本').replace('en', '英語') +
              '（' +
              item.searchWords +
              '）',
            'ページビュー数',
            item.units,
            item.rate,
            item.hasUnits
          )
        )
      )
    data.push(
      convert2AggregatedDataArray(
        'Twitterキーワード',
        '推定投稿数',
        state.twitterKeywords.units,
        state.twitterKeywords.rate,
        state.twitterKeywords.hasUnits
      )
    )
    data.push(
      convert2AggregatedDataArray(
        'Shazam数',
        'Shazam数',
        state.shazams.units,
        state.shazams.rate,
        state.shazams.hasUnits
      )
    )
    return data
  }
)

function convert2AggregatedDataArray(
  legend: string,
  unit: string,
  units: number,
  rate: number,
  hasUnits: boolean
): any[] {
  if (hasUnits) {
    return [legend, unit, units, rate]
  }
  return [legend, unit, '', '']
}
