import { Component, OnInit, Input } from '@angular/core'

@Component({
  selector: 'app-progress-indicator',
  styleUrls: ['./progress-indicator.component.scss'],
  template: `
    <div class="cssload-container" [class.not-center]="!center">
      <div class="cssload-circle"></div>
      <div class="cssload-circle"></div>
    </div>
  `,
})
export class ProgressIndicatorComponent implements OnInit {
  @Input()
  center = true

  constructor() {}

  ngOnInit() {}
}
