import { Action, createReducer, on } from '@ngrx/store'
import { Reactions } from '../models/reactions.model'
import {
  loadReactionsGraphsSuccess,
  loadReactionsGraphsFailure,
} from '../actions/reactions-graph.actions'
import { refreshData } from '../actions/criteria.actions'

export interface State {
  reactions: Reactions[]
  loading: boolean
  loaded: boolean
}

export const initialState: State = { reactions: [], loading: false, loaded: false }

const reactionsGraphReducer = createReducer(
  initialState,
  on(refreshData, (state, action) => ({ ...state, loading: true, loaded: false })),
  on(loadReactionsGraphsSuccess, (state, action) => ({
    reactions: action.reactions,
    loading: false,
    loaded: true,
  })),
  on(loadReactionsGraphsFailure, (state, action) => state)
)

export function reducer(state: State | undefined, action: Action) {
  return reactionsGraphReducer(state, action)
}
