import { Component, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/reducers'
import { Observable } from 'rxjs'
import {
  getCriteriaFrom,
  getCriteriaTo,
  getCriteriaFromBehindOneDay,
  getCriteriaPreviousFrom,
  getCriteriaPreviousTo,
} from 'src/app/selectors/criteria.selector'
import { Artist } from 'src/app/models/artist.model'
import { CountableLinkSummary } from 'src/app/models/latest-aggregate-amounts.model'
import { getSelectedArtist } from 'src/app/selectors/artist.selector'
import {
  getPeriodAggregatedAmounts,
  getLoading as getCsvLoadingTotal,
} from '../../../selectors/period-aggregate-amounts.selector'
import {
  getTwitterFollowers,
  getFacebookFollowers,
  getInstagramFollowers,
  getLatestAggregatedAmounts,
  getLoading as getCsvLoadingLatest,
} from '../../../selectors/latest-aggregate-amounts.selector'
import {
  getDataCollectByTwitterFollower,
  getDataCollectLoading,
} from 'src/app/selectors/data-collections.selector'
@Component({
  selector: 'app-aggregated-amounts',
  templateUrl: './aggregated-amounts.component.html',
  styleUrls: ['./aggregated-amounts.component.scss'],
})
export class AggregatedAmountsComponent implements OnInit {
  criteriaFrom$: Observable<Date>
  criteriaTo$: Observable<Date>
  criteriaFromBehindOneDay$: Observable<Date>
  criteriaPreviousFrom$: Observable<Date>
  criteriaPreviousTo$: Observable<Date>

  selectedArtist$: Observable<Artist>

  twitterFollowers$: Observable<CountableLinkSummary[]>
  facebookFollowers$: Observable<CountableLinkSummary[]>
  instagramFollowers$: Observable<CountableLinkSummary[]>
  loading$: Observable<boolean>
  dataCollections$: Observable<boolean>
  dataCollectLoading$: Observable<boolean>

  constructor(private store: Store<AppState>) {}

  csvIdRecent = 'recent'
  csvFileNameRecent = '最新値'
  csvDataRecent$: Observable<any[]>
  csvLoadingRecent$: Observable<boolean>
  csvIdTotal = 'total'
  csvFileNameTotal = '期間集計値'
  csvDataTotal$: Observable<any[]>
  csvLoadingTotal$: Observable<boolean>

  ngOnInit() {
    this.criteriaFrom$ = this.store.select(getCriteriaFrom)
    this.criteriaTo$ = this.store.select(getCriteriaTo)
    this.criteriaFromBehindOneDay$ = this.store.select(getCriteriaFromBehindOneDay)
    this.criteriaPreviousFrom$ = this.store.select(getCriteriaPreviousFrom)
    this.criteriaPreviousTo$ = this.store.select(getCriteriaPreviousTo)
    this.selectedArtist$ = this.store.select(getSelectedArtist)
    this.twitterFollowers$ = this.store.select(getTwitterFollowers)
    this.facebookFollowers$ = this.store.select(getFacebookFollowers)
    this.instagramFollowers$ = this.store.select(getInstagramFollowers)

    this.csvLoadingRecent$ = this.store.select(getCsvLoadingLatest)
    this.csvDataRecent$ = this.store.select(getLatestAggregatedAmounts)
    this.csvLoadingTotal$ = this.store.select(getCsvLoadingTotal)
    this.csvDataTotal$ = this.store.select(getPeriodAggregatedAmounts)

    this.loading$ = this.store.select(getCsvLoadingLatest)
    this.dataCollections$ = this.store.select(getDataCollectByTwitterFollower)
    this.dataCollectLoading$ = this.store.select(getDataCollectLoading)
  }

  isZero(i: number): boolean {
    return i === 0
  }
}
