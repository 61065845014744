import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core'
import { Artist } from 'src/app/models/artist.model'

@Component({
  selector: 'app-search-artist-result-item',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div fxLayout fxLayoutAlign="start center" fxLayoutGap="16px">
      <img *ngIf="artist.arsha" [src]="artist.arsha" class="arsha" />
      <div *ngIf="!artist.arsha" class="arsha-empty" fxLayout fxLayoutAlign="center center">
        <div class="empty-label">{{ artist.nameMain }}</div>
      </div>
      <div fxFlex fxLayout="column">
        <span class="name-main">{{ artist.nameMain }}</span>
        <span class="label">{{ artist.label }}</span>
      </div>
      <div fxFlex="1 1 auto" fxLayoutAlign="end center">
        <span class="genre">{{ artist.genre }}</span>
      </div>
    </div>
  `,
  styleUrls: ['./search-artist-result-item.component.scss'],
})
export class SearchArtistResultItemComponent implements OnInit {
  @Input()
  artist: Artist

  constructor() {}

  ngOnInit() {}
}
