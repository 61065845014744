import { Injectable } from '@angular/core'
import { AngularFireFunctions } from '@angular/fire/functions'
import { ArtistId } from '../models/artist.model'
import { Observable } from 'rxjs'
import { Nullable } from '../types/nullable'
import { TwitterTagCloud } from '../models/twitter-tag-cloud.model'
import * as moment from 'moment'

@Injectable({
  providedIn: 'root',
})
export class TwitterTagCloudService {
  constructor(private functions: AngularFireFunctions) {}

  findTwitterTagCloud(
    artistId: ArtistId,
    from: Date,
    to: Date
  ): Observable<Nullable<TwitterTagCloud[]>> {
    return this.functions.httpsCallable<
      { artistId: ArtistId; from: string; to: string },
      Nullable<TwitterTagCloud[]>
    >('findTwitterTagCloud')({
      artistId,
      from: moment(from).format('YYYY-MM-DD'),
      to: moment(to).format('YYYY-MM-DD'),
    })
  }
}
