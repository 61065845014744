import { Action, createReducer, on } from '@ngrx/store'
import { TiktokGraphPoint } from '../models/tiktok-graph.model'
import { refreshData } from '../actions/criteria.actions'
import { loadTiktokGraphsSuccess, loadTiktokGraphsFailure } from '../actions/tiktok-graph.actions'

export interface State {
  tiktok: TiktokGraphPoint[]
  loading: boolean
  loaded: boolean
}

export const initialState: State = { tiktok: [], loading: false, loaded: false }

const youtubeGraphReducer = createReducer(
  initialState,
  on(refreshData, (state, action) => ({ ...state, loading: true, loaded: false })),
  on(loadTiktokGraphsSuccess, (state, action) => ({
    tiktok: action.tiktok,
    loading: false,
    loaded: true,
  })),
  on(loadTiktokGraphsFailure, (state, action) => state)
)

export function reducer(state: State | undefined, action: Action) {
  return youtubeGraphReducer(state, action)
}
