import { Action, createReducer, on } from '@ngrx/store'
import * as FavoriteActions from '../actions/favorite.actions'
import { Artist } from 'src/app/models/artist.model'

export interface State {
  favorites: Artist[]
  loading: boolean
  loaded: boolean
}

export const initialState: State = {
  favorites: [],
  loading: false,
  loaded: false,
}

const favoriteReducer = createReducer(
  initialState,

  on(FavoriteActions.loadFavorites, state => ({ ...state, loading: true, loaded: false })),
  on(FavoriteActions.loadFavoritesSuccess, (state, action) => ({
    ...state,
    favorites: action.favorites,
    loading: false,
    loaded: true,
  })),
  on(FavoriteActions.loadFavoritesFailure, state => ({ ...state, loading: false, loaded: false }))
)

export function reducer(state: State | undefined, action: Action) {
  return favoriteReducer(state, action)
}
