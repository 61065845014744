import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as fromChart from 'src/app/reducers/chart.reducer'
import * as moment from 'moment'
import { ChartInfo, ChartPart } from '../models/chart.model'

export const getChartState = createFeatureSelector<fromChart.State>('chart')

export const getChartSpotifyRank = createSelector(
  getChartState,
  state => state.spotifyInfo
)

export const getChartOriconPhysicalDailySingleInfo = createSelector(
  getChartState,
  state => state.oriconPhysicalDailySingleInfo
)

export const getChartOriconPhysicalDailyAlbumInfo = createSelector(
  getChartState,
  state => state.oriconPhysicalDailyAlbumInfo
)

export const getChartOriconPhysicalWeeklySingleInfo = createSelector(
  getChartState,
  state => state.oriconPhysicalWeeklySingleInfo
)

export const getChartOriconPhysicalWeeklyAlbumInfo = createSelector(
  getChartState,
  state => state.oriconPhysicalWeeklyAlbumInfo
)

export const getChartOriconPhysicalWeeklyDVDInfo = createSelector(
  getChartState,
  state => state.oriconPhysicalWeeklyDVDInfo
)

export const getChartOriconStreamingDailyInfo = createSelector(
  getChartState,
  state => state.oriconStreamingDailyInfo
)

export const getChartOriconStreamingWeeklyInfo = createSelector(
  getChartState,
  state => state.oriconStreamingWeeklyInfo
)

export const getChartTiktokWeeklyInfo = createSelector(
  getChartState,
  state => state.tiktokWeeklyInfo
)

export const getChartLineMusicRank = createSelector(
  getChartState,
  state => {
    return state.lineMusicInfo
  }
)

export const getChartiTunes = createSelector(
  getChartState,
  state => {
    return state.iTunesInfo
  }
)

export const getChartAppleMusic = createSelector(
  getChartState,
  state => {
    return state.appleMusicInfo
  }
)

export const getChartAmazon = createSelector(
  getChartState,
  state => {
    return state.amazonInfo
  }
)

export const getChartShazamInfo = createSelector(
  getChartState,
  state => state.shazamInfo
)

export const getLoading = createSelector(
  getChartState,
  state => state.loading
)

export const getLoaded = createSelector(
  getChartState,
  state => state.loaded
)

export const getAllEmpty = createSelector(
  getChartState,
  state =>
    (state.spotifyInfo == null ||
      state.spotifyInfo.length === 0 ||
      state.spotifyInfo[0].chartItems == null ||
      state.spotifyInfo[0].chartItems.length === 0) &&
    (state.oriconPhysicalDailySingleInfo == null ||
      state.oriconPhysicalDailySingleInfo.length === 0) &&
    (state.oriconPhysicalDailyAlbumInfo == null ||
      state.oriconPhysicalDailyAlbumInfo.length === 0) &&
    (state.oriconPhysicalWeeklySingleInfo == null ||
      state.oriconPhysicalWeeklySingleInfo.length === 0) &&
    (state.oriconPhysicalWeeklyAlbumInfo == null ||
      state.oriconPhysicalWeeklyAlbumInfo.length === 0) &&
    (state.oriconPhysicalWeeklyDVDInfo == null || state.oriconPhysicalWeeklyDVDInfo.length === 0) &&
    (state.oriconStreamingDailyInfo == null || state.oriconStreamingDailyInfo.length === 0) &&
    (state.oriconStreamingWeeklyInfo == null || state.oriconStreamingWeeklyInfo.length === 0) &&
    (state.tiktokWeeklyInfo == null ||
      state.tiktokWeeklyInfo.length === 0 ||
      state.tiktokWeeklyInfo[0].chartItems == null ||
      state.tiktokWeeklyInfo[0].chartItems.length === 0) &&
    (state.lineMusicInfo == null ||
      state.lineMusicInfo.length === 0 ||
      state.lineMusicInfo[0].chartItems == null ||
      state.lineMusicInfo[0].chartItems.length === 0) &&
    (state.iTunesInfo == null ||
      state.iTunesInfo.length === 0 ||
      state.iTunesInfo[0].chartItems == null ||
      state.iTunesInfo[0].chartItems.length === 0) &&
    (state.appleMusicInfo == null ||
      state.appleMusicInfo.length === 0 ||
      state.appleMusicInfo[0].chartItems == null ||
      state.appleMusicInfo[0].chartItems.length === 0) &&
    (state.amazonInfo == null ||
      state.amazonInfo.length === 0 ||
      state.amazonInfo[0].chartItems == null ||
      state.amazonInfo[0].chartItems.length === 0) &&
    (state.shazamInfo == null ||
      state.shazamInfo.length === 0 ||
      state.shazamInfo[0].chartItems == null ||
      state.shazamInfo[0].chartItems.length === 0)
)

export const getOriconAllEmpty = createSelector(
  getChartState,
  state =>
    (state.oriconPhysicalDailySingleInfo == null ||
      state.oriconPhysicalDailySingleInfo.length === 0) &&
    (state.oriconPhysicalDailyAlbumInfo == null ||
      state.oriconPhysicalDailyAlbumInfo.length === 0) &&
    (state.oriconPhysicalWeeklySingleInfo == null ||
      state.oriconPhysicalWeeklySingleInfo.length === 0) &&
    (state.oriconPhysicalWeeklyAlbumInfo == null ||
      state.oriconPhysicalWeeklyAlbumInfo.length === 0) &&
    (state.oriconPhysicalWeeklyDVDInfo == null || state.oriconPhysicalWeeklyDVDInfo.length === 0) &&
    (state.oriconStreamingDailyInfo == null || state.oriconStreamingDailyInfo.length === 0) &&
    (state.oriconStreamingWeeklyInfo == null || state.oriconStreamingWeeklyInfo.length === 0)
)

export const getCSVData = createSelector(
  getChartState,
  state => {
    const data = []

    data.push([
      '集計期間',
      moment(state.currentFrom).format('YYYY-MM-DD') +
        ' ~ ' +
        moment(state.currentTo).format('YYYY-MM-DD'),
    ])
    data.push([])
    data.push(['チャート', 'タイトル', '順位', '前週', '最高', 'Creations'])
    state.oriconPhysicalDailyAlbumInfo.map(item =>
      data.push(convert2ChartArray('Oricon CD アルバム（Daily）', item))
    )
    state.oriconPhysicalWeeklyAlbumInfo.map(item =>
      data.push(convert2ChartArray('Oricon CD アルバム（Weekly）', item))
    )
    state.oriconPhysicalDailySingleInfo.map(item =>
      data.push(convert2ChartArray('Oricon CD シングル（Daily）', item))
    )
    state.oriconPhysicalWeeklySingleInfo.map(item =>
      data.push(convert2ChartArray('Oricon CD シングル（Weekly）', item))
    )
    state.oriconPhysicalWeeklyDVDInfo.map(item =>
      data.push(convert2ChartArray('Oricon DVD（Weekly）', item))
    )
    state.oriconStreamingDailyInfo.map(item =>
      data.push(convert2ChartArray('Oricon Streaming（Daily）', item))
    )
    state.oriconStreamingWeeklyInfo.map(item =>
      data.push(convert2ChartArray('Oricon Streaming（Weekly）', item))
    )
    state.iTunesInfo.map(item => {
      for (const info of item.chartItems) {
        data.push(convert2ChartArrayForRank('iTunes', item, info))
      }
    })
    state.amazonInfo.map(item => {
      for (const info of item.chartItems) {
        data.push(convert2ChartArrayForRank('Amazon', item, info))
      }
    })
    state.appleMusicInfo.map(item => {
      for (const info of item.chartItems) {
        data.push(convert2ChartArrayForRank('Apple Music', item, info))
      }
    })
    state.spotifyInfo.map(item => {
      for (const info of item.chartItems) {
        data.push(convert2ChartArrayForRank('Spotify', item, info))
      }
    })
    state.lineMusicInfo.map(item => {
      for (const info of item.chartItems) {
        data.push(convert2ChartArrayForRank('LINE MUSIC', item, info))
      }
    })
    state.tiktokWeeklyInfo.map(item => {
      for (const info of item.chartItems) {
        data.push(convert2ChartArrayForRank('TikTok', item, info))
      }
    })
    state.shazamInfo.map(item => {
      for (const info of item.chartItems) {
        data.push(convert2ChartArrayForRank('Shazam', item, info))
      }
    })

    return data
  }
)

function convert2ChartData(obj: any[]): any[] {
  const dates = [...new Set(obj.map(item => item.transactionDate))]
  const column = []
  dates.forEach(d => {
    const data: any[] = [d]
    column.push(data)
    obj.map(units => {
      if (d === units.transactionDate) {
        data.push(units.units)
      }
    })
  })
  return column
}

function convert2ChartArray(chartType: string, chart: ChartInfo): any[] {
  return [
    chartType,
    chart.title,
    chart.currentRank,
    chart.previousRank,
    chart.rankTop,
    chart.creations_last_7_days,
  ]
}

function convert2ChartArrayForRank(
  chartType: string,
  chartPart: ChartPart,
  chart: ChartInfo
): any[] {
  return [
    chartType + ' ' + chartPart.chartNameJp,
    chart.title,
    chart.currentRank,
    chart.previousRank,
    chart.rankTop,
    chart.creations_last_7_days,
  ]
}
