import { Injectable } from '@angular/core'
import { AngularFireFunctions } from '@angular/fire/functions'

import { ArtistId } from '../models/artist.model'
import { Audience } from '../models/audience.model'
import { Observable } from 'rxjs'
import * as moment from 'moment'

@Injectable({
  providedIn: 'root',
})
export class AudienceService {
  constructor(private functions: AngularFireFunctions) {}

  find(artistId: ArtistId, from: Date, to: Date): Observable<Audience> {
    return this.functions.httpsCallable<{ artistId: ArtistId; from: string; to: string }, Audience>(
      'findAudience'
    )({
      artistId,
      from: moment(from).format('YYYY-MM-DD'),
      to: moment(to).format('YYYY-MM-DD'),
    })
  }
}
