import { createAction, props } from '@ngrx/store'
import { ArtistId, Artist } from 'src/app/models/artist.model'

export const loadArtistsNamed = createAction(
  '[Artist] Load Artists By Name',
  props<{ name: string }>()
)

export const loadArtistsNamedSuccess = createAction(
  '[Artist] Load Artists By Name Success',
  props<{ artists: Artist[] }>()
)

export const loadArtistsNamedFailure = createAction(
  '[Artist] Load Artists By Name Failure',
  props<{ error: any }>()
)

export const selectArtist = createAction('[Artist] Select Artist', props<{ artist: Artist }>())

export const loadAndSelectArtist = createAction(
  '[Artist] Load and Select Artist',
  props<{ artistId: ArtistId }>()
)

export const loadAndSelectArtistSuccess = createAction(
  '[Artist] Load And Select Artist Success',
  props<{ artist: Artist }>()
)

export const loadAndSelectArtistFailure = createAction(
  '[Artist] Load And Select Artist Failure',
  props<{ error: any }>()
)

export const clearSearchResult = createAction('[Artist] Clear Search Result')
