import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core'

@Component({
  selector: 'app-sp-data-field',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./sp-data-field.component.scss'],
  template: `
    <div class="field" fxLayout="row" fxLayoutAlign="start space-between">
      <div class="legend">{{ legend }}</div>
      <div fxFlex="1 1 auto"></div>
      <div class="value"><ng-content></ng-content></div>
    </div>
  `,
})
export class SpDataFieldComponent implements OnInit {
  @Input()
  legend: string

  constructor() {}

  ngOnInit() {}
}
