import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { YoutubeGraphService } from '../services/youtube-graph.service'
import { refreshData } from '../actions/criteria.actions'
import { switchMap, catchError, map } from 'rxjs/operators'
import {
  loadYoutubeGraphsSuccess,
  loadYoutubeGraphsFailure,
} from '../actions/youtube-graph.actions'
import { of } from 'rxjs'

@Injectable()
export class YoutubeGraphEffects {
  constructor(private actions$: Actions, private service: YoutubeGraphService) {}

  loadYoutubeGraph$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshData),
      switchMap(action =>
        this.service.findYoutubeGraph(action.artistId, action.from, action.to).pipe(
          map(youtube => loadYoutubeGraphsSuccess({ youtube })),
          catchError(error => of(loadYoutubeGraphsFailure({ error })))
        )
      )
    )
  )
}
