import { Injectable } from '@angular/core'
import { ArtistId } from '../models/artist.model'
import { AngularFireFunctions } from '@angular/fire/functions'
import { Observable } from 'rxjs'
import { PeriodAggregateAmounts } from '../models/period-aggregate-amounts.model'
import * as moment from 'moment'

interface Criteria {
  artistId: ArtistId
  from: string
  to: string
}

@Injectable({
  providedIn: 'root',
})
export class PeriodAggregateAmountsService {
  constructor(private functions: AngularFireFunctions) {}

  findPeriodAggregateAmounts(
    artistId: ArtistId,
    from: Date,
    to: Date
  ): Observable<PeriodAggregateAmounts> {
    return this.functions.httpsCallable<Criteria, PeriodAggregateAmounts>(
      'findPeriodAggregateAmounts'
    )({
      artistId,
      from: moment(from).format('YYYY-MM-DD'),
      to: moment(to).format('YYYY-MM-DD'),
    })
  }
}
