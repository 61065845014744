import { createAction, props } from '@ngrx/store'
import { HomepagesUser } from '../models/homepagesUser.model'

export const loadHomepagesUserGraphsSuccess = createAction(
  '[HomepagesUserGraph] Load HomepagesUserGraphs Success',
  props<{ homepagesUser: HomepagesUser[] }>()
)

export const loadHomepagesUserGraphsFailure = createAction(
  '[HomepagesUserGraph] Load HomepagesUserGraphs Failure',
  props<{ error: any }>()
)
