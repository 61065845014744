import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core'

@Component({
  selector: 'app-music-note',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="music-note" fxLayout fxLayoutAlign="center baseline">
      <img src="./assets/music-note-AAAAAA.png" class="note jump1" />
      <img src="./assets/music-note-AAAAAA.png" class="note jump2" />
      <img src="./assets/music-note-AAAAAA.png" class="note jump3" />
    </div>
  `,
  styleUrls: ['./music-note.component.scss'],
})
export class MusicNoteComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
