import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as fromAudience from 'src/app/reducers/audience.reducer'
import { AudienceInfo } from '../models/audience.model'
import * as moment from 'moment'
import { getCriteriaFrom, getCriteriaTo } from './criteria.selector'

export const getAudienceState = createFeatureSelector<fromAudience.State>('audience')

export const getAudienceAppleMusic = createSelector(
  getAudienceState,
  state => {
    const maleStreams = state.appleMusicInfo
      .filter(it => it.gender === 'Male')
      .reduce((streams, it) => streams + it.streams, 0)
    const femaleStreams = state.appleMusicInfo
      .filter(it => it.gender === 'Female')
      .reduce((streams, it) => streams + it.streams, 0)
    const unknownStreams = state.appleMusicInfo
      .filter(it => it.gender === 'Data Unknown')
      .reduce((streams, it) => streams + it.streams, 0)
    if (maleStreams === 0 && femaleStreams === 0 && unknownStreams === 0) {
      return []
    }
    return [['男性', maleStreams], ['女性', femaleStreams], ['不明', unknownStreams]]
  }
)

export const getAudienceSpotify = createSelector(
  getAudienceState,
  state => {
    const maleStreams = state.spotifyInfo
      .filter(it => it.gender === 'male')
      .reduce((streams, it) => streams + it.streams, 0)
    const femaleStreams = state.spotifyInfo
      .filter(it => it.gender === 'female')
      .reduce((streams, it) => streams + it.streams, 0)
    const unknownStreams = state.spotifyInfo
      .filter(it => it.gender === '')
      .reduce((streams, it) => streams + it.streams, 0)
    if (maleStreams === 0 && femaleStreams === 0 && unknownStreams === 0) {
      return []
    }
    return [['男性', maleStreams], ['女性', femaleStreams], ['不明', unknownStreams]]
  }
)

export const getAudienceYoutube = createSelector(
  getAudienceState,
  state => {
    const maleStreams = state.youtubeInfo
      .filter(it => it.gender === 'MALE')
      .reduce((streams, it) => streams + it.streams, 0)
    const femaleStreams = state.youtubeInfo
      .filter(it => it.gender === 'FEMALE')
      .reduce((streams, it) => streams + it.streams, 0)
    const unknownStreams = state.youtubeInfo
      .filter(it => it.gender === 'GENDER_OTHER')
      .reduce((streams, it) => streams + it.streams, 0)
    if (maleStreams === 0 && femaleStreams === 0 && unknownStreams === 0) {
      return []
    }
    return [['男性', maleStreams], ['女性', femaleStreams], ['不明', unknownStreams]]
  }
)

export const getAudienceUmhp = createSelector(
  getAudienceState,
  state => {
    const maleStreams = state.umhpInfo
      .filter(it => it.gender === 'male')
      .reduce((streams, it) => streams + it.streams, 0)
    const femaleStreams = state.umhpInfo
      .filter(it => it.gender === 'female')
      .reduce((streams, it) => streams + it.streams, 0)
    const unknownStreams = state.umhpInfo
      .filter(it => it.gender === null)
      .reduce((streams, it) => streams + it.streams, 0)
    if (maleStreams === 0 && femaleStreams === 0 && unknownStreams === 0) {
      return []
    }
    return [['男性', maleStreams], ['女性', femaleStreams], ['不明', unknownStreams]]
  }
)
export const getAudienceEnquete = createSelector(
  getAudienceState,
  state => {
    const maleStreams = state.enqueteInfo
      .filter(it => it.gender === '男性')
      .reduce((streams, it) => streams + it.streams, 0)
    const femaleStreams = state.enqueteInfo
      .filter(it => it.gender === '女性')
      .reduce((streams, it) => streams + it.streams, 0)
    const unknownStreams = state.enqueteInfo
      .filter(it => it.gender === '不明')
      .reduce((streams, it) => streams + it.streams, 0)
    if (maleStreams === 0 && femaleStreams === 0 && unknownStreams === 0) {
      return []
    }
    return [['男性', maleStreams], ['女性', femaleStreams], ['不明', unknownStreams]]
  }
)

export const getAudienceNewsletter = createSelector(
  getAudienceState,
  state => {
    const maleStreams = state.newsletterInfo
      .filter(it => it.gender === '男性')
      .reduce((streams, it) => streams + it.streams, 0)
    const femaleStreams = state.newsletterInfo
      .filter(it => it.gender === '女性')
      .reduce((streams, it) => streams + it.streams, 0)
    const unknownStreams = state.newsletterInfo
      .filter(it => it.gender === '不明')
      .reduce((streams, it) => streams + it.streams, 0)
    if (maleStreams === 0 && femaleStreams === 0 && unknownStreams === 0) {
      return []
    }
    return [['男性', maleStreams], ['女性', femaleStreams], ['不明', unknownStreams]]
  }
)

export const getAudienceLine = createSelector(
  getAudienceState,
  state => {
    const maleStreams = state.lineInfo
      .filter(it => it.gender === 'm')
      .reduce((streams, it) => streams + it.streams, 0)
    const femaleStreams = state.lineInfo
      .filter(it => it.gender === 'f')
      .reduce((streams, it) => streams + it.streams, 0)
    const unknownStreams = state.lineInfo
      .filter(it => it.gender === 'unknown')
      .reduce((streams, it) => streams + it.streams, 0)
    if (maleStreams === 0 && femaleStreams === 0 && unknownStreams === 0) {
      return []
    }
    return [['男性', maleStreams], ['女性', femaleStreams], ['不明', unknownStreams]]
  }
)

// apple music年齢
const ageAppMusicArr = ['18-24', '25-34', '35-44', '45-54', '55-64', '65+', 'Data Unknown']
const genderAppMusicArr = ['Male', 'Female', 'Data Unknown']
const genderArr = ['男性', '女性', '不明']

export const getAudienceAppleMusicAge = createSelector(
  getAudienceState,
  state => {
    const resultAll = []
    let exitAppMusicAge = false
    for (let i = 0; i < ageAppMusicArr.length; i++) {
      const resultArr = []
      if (ageAppMusicArr[i] === 'Data Unknown') {
        resultArr[0] = '不明'
      } else {
        resultArr[0] = ageAppMusicArr[i]
      }

      for (let j = 0; j < genderAppMusicArr.length; j++) {
        resultArr[j + 1] = state.appleMusicInfo
          .filter(it => it.ageGroup === ageAppMusicArr[i] && it.gender === genderAppMusicArr[j])
          .reduce((streams, it) => streams + it.streams, 0)
        if (resultArr[j + 1] !== 0 && exitAppMusicAge === false) {
          exitAppMusicAge = true
        }
      }
      resultAll[i] = resultArr
    }
    if (exitAppMusicAge === false) {
      return []
    }
    return resultAll
  }
)

// spotify 年齢
const genderSpotifyArr = ['male', 'female', '']
export const getAudienceSpotifyAge = createSelector(
  getAudienceState,
  state => {
    const resultAll = []
    const ageGroupSpotifyArr = getAgeGroup(state.spotifyInfo)
    let exitSpotifyAge = false
    for (let i = 0; i < ageGroupSpotifyArr.length; i++) {
      const resultArr = []
      if (ageGroupSpotifyArr[i] === 'Unknown') {
        resultArr[0] = '不明'
      } else {
        resultArr[0] = ageGroupSpotifyArr[i]
      }

      for (let j = 0; j < genderSpotifyArr.length; j++) {
        resultArr[j + 1] = state.spotifyInfo
          .filter(it => it.ageGroup === ageGroupSpotifyArr[i] && it.gender === genderSpotifyArr[j])
          .reduce((streams, it) => streams + it.streams, 0)
        if (resultArr[j + 1] !== 0 && exitSpotifyAge === false) {
          exitSpotifyAge = true
        }
      }
      resultAll[i] = resultArr
    }
    if (exitSpotifyAge === false) {
      return []
    }
    return resultAll
  }
)

// 年齢範囲を取得する
function getAgeGroup(audience: AudienceInfo[]) {
  // 年齢
  const ageArr = []
  // 年齢範囲を取得(重複な年齢範囲を一つ取得)
  for (let i = 0; i < audience.length; i++) {
    if (i === 0) {
      ageArr.push(audience[i].ageGroup)
    } else {
      let uniqueAgeGroup = true
      for (const ageGroup of ageArr) {
        if (ageGroup === audience[i].ageGroup) {
          uniqueAgeGroup = false
          break
        }
      }
      if (uniqueAgeGroup) {
        ageArr.push(audience[i].ageGroup)
      }
    }
  }
  return ageArr
}

// line 年齢
const genderLineArr = ['m', 'f', 'unknown']
export const getAudienceLineAge = createSelector(
  getAudienceState,
  state => {
    const resultAll = []
    const ageGroupLineArr = getAgeGroup(state.lineInfo)
    let exitLineAge = false
    for (let i = 0; i < ageGroupLineArr.length; i++) {
      const resultArr = []
      if (ageGroupLineArr[i] === 'unknown') {
        resultArr[0] = '不明'
      } else {
        resultArr[0] = ageGroupLineArr[i]
      }

      for (let j = 0; j < genderLineArr.length; j++) {
        resultArr[j + 1] = state.lineInfo
          .filter(it => it.ageGroup === ageGroupLineArr[i] && it.gender === genderLineArr[j])
          .reduce((streams, it) => streams + it.streams, 0)
        if (resultArr[j + 1] !== 0 && exitLineAge === false) {
          exitLineAge = true
        }
      }
      resultAll[i] = resultArr
    }
    if (exitLineAge === false) {
      return []
    }
    return resultAll
  }
)

// youtube 年齢
const keyAgeYoutubeArr = [
  'AGE_13_17',
  'AGE_18_24',
  'AGE_25_34',
  'AGE_35_44',
  'AGE_45_54',
  'AGE_55_64',
  'AGE_65_',
  'Unknown',
]
const ageYoutubeArr = ['13-17', '18-24', '25-34', '35-44', '45-54', '55-64', '65+', '不明']
const genderYoutubeArr = ['MALE', 'FEMALE', 'GENDER_OTHER']
export const getAudienceYoutubeAge = createSelector(
  getAudienceState,
  state => {
    const resultAll = []
    let exitYoutubeAge = false
    for (let i = 0; i < keyAgeYoutubeArr.length; i++) {
      const resultArr = []
      resultArr[0] = ageYoutubeArr[i]

      for (let j = 0; j < genderYoutubeArr.length; j++) {
        resultArr[j + 1] = state.youtubeInfo
          .filter(it => it.ageGroup === keyAgeYoutubeArr[i] && it.gender === genderYoutubeArr[j])
          .reduce((streams, it) => streams + it.streams, 0)
        if (resultArr[j + 1] !== 0 && exitYoutubeAge === false) {
          exitYoutubeAge = true
        }
      }
      resultAll[i] = resultArr
    }
    if (exitYoutubeAge === false) {
      return []
    }
    return resultAll
  }
)

// UM ホームページ 年齢
const ageUmhpArr = ['18-24', '25-34', '35-44', '45-54', '55-64', '65+']
const genderUmhpArr = ['male', 'female']
const genderUmhpViewArr = ['男性', '女性']

export const getAudienceUmhpAge = createSelector(
  getAudienceState,
  state => {
    const resultAll = []
    let exitUmhpAge = false

    for (let i = 0; i < ageUmhpArr.length; i++) {
      const resultArr = []
      resultArr[0] = ageUmhpArr[i]

      for (let j = 0; j < genderUmhpArr.length; j++) {
        resultArr[j + 1] = state.umhpInfo
          .filter(it => it.ageGroup === ageUmhpArr[i] && it.gender === genderUmhpArr[j])
          .reduce((streams, it) => streams + it.streams, 0)
        if (resultArr[j + 1] !== 0 && exitUmhpAge === false) {
          exitUmhpAge = true
        }
      }
      resultAll[i] = resultArr
    }
    if (exitUmhpAge === false) {
      return []
    }
    return resultAll
  }
)

// newsletter 年齢
export const getAudienceNewsletterAge = createSelector(
  getAudienceState,
  state => {
    const resultAll = []
    let exitNewsletterAge = false
    for (let i = 0; i < 52; i++) {
      const resultArr = []
      resultArr[0] = (i + 13).toString()

      for (let j = 0; j < genderArr.length; j++) {
        resultArr[j + 1] = state.newsletterInfo
          .filter(it => it.ageGroup === (i + 13).toString() && it.gender === genderArr[j])
          .reduce((streams, it) => streams + it.streams, 0)
        if (resultArr[j + 1] !== 0 && exitNewsletterAge === false) {
          exitNewsletterAge = true
        }
      }
      resultAll[i] = resultArr
    }

    // 65歳以上の場合
    for (let j = 0; j < genderArr.length; j++) {
      const resultArr65 = []
      resultArr65[0] = '65+'
      resultArr65[j + 1] = state.newsletterInfo
        .filter(it => it.ageGroup === '65+' && it.gender === genderArr[j])
        .reduce((streams, it) => streams + it.streams, 0)
      resultAll[52] = resultArr65
      if (resultArr65[j + 1] !== 0 && exitNewsletterAge === false) {
        exitNewsletterAge = true
      }
    }
    if (exitNewsletterAge === false) {
      return []
    }
    return resultAll
  }
)

// 購入者アンケート 年齢
export const getAudienceEnqueteAge = createSelector(
  getAudienceState,
  state => {
    const resultAll = []
    let exitEnqueteAge = false
    for (let i = 0; i < 52; i++) {
      const resultArr = []
      resultArr[0] = (i + 13).toString()

      for (let j = 0; j < genderArr.length; j++) {
        resultArr[j + 1] = state.enqueteInfo
          .filter(it => it.ageGroup === (i + 13).toString() && it.gender === genderArr[j])
          .reduce((streams, it) => streams + it.streams, 0)
        if (resultArr[j + 1] !== 0 && exitEnqueteAge === false) {
          exitEnqueteAge = true
        }
      }
      resultAll[i] = resultArr
    }

    // 65歳以上の場合
    for (let j = 0; j < genderArr.length; j++) {
      const resultArr65 = []
      resultArr65[0] = '65+'
      resultArr65[j + 1] = state.enqueteInfo
        .filter(it => it.ageGroup === '65+' && it.gender === genderArr[j])
        .reduce((streams, it) => streams + it.streams, 0)
      resultAll[52] = resultArr65
      if (resultArr65[j + 1] !== 0 && exitEnqueteAge === false) {
        exitEnqueteAge = true
      }
    }
    if (exitEnqueteAge === false) {
      return []
    }
    return resultAll
  }
)

// Spotify 地域
export const getAudienceSpotifyRegion = createSelector(
  getAudienceState,
  state => {
    return getRegionStreams(state.spotifyRegionInfo)
  }
)

// Line 地域
export const getAudienceLineRegion = createSelector(
  getAudienceState,
  state => {
    return getRegionStreams(state.lineRegionInfo)
  }
)

// UM ホームページ 地域
export const getAudienceUmhpRegion = createSelector(
  getAudienceState,
  state => {
    return getRegionStreams(state.umhpRegionInfo)
  }
)

// newsletter 地域
export const getAudienceNewsletterRegion = createSelector(
  getAudienceState,
  state => {
    return getRegionStreams(state.newsletterInfo)
  }
)

// 購入者アンケート 地域
export const getAudienceEnqueteRegion = createSelector(
  getAudienceState,
  state => {
    return getRegionStreams(state.enqueteInfo)
  }
)

// 地域[都道府県]を取得する
function getRegion(audience: AudienceInfo[]) {
  // 地域
  const regionArr = []
  // 地域を取得(重複な地域を一つ取得)
  for (let i = 0; i < audience.length; i++) {
    if (i === 0) {
      regionArr.push(audience[i].city)
    } else {
      let uniqueRegion = true
      for (const region of regionArr) {
        if (region === audience[i].city) {
          uniqueRegion = false
          break
        }
      }
      if (uniqueRegion) {
        regionArr.push(audience[i].city)
      }
    }
  }
  return regionArr
}

// 地域のAUDIENCE情報を取得
function getRegionStreams(audience: AudienceInfo[]) {
  // 地域 + streams
  const resultAll = []
  // 地域のstreams
  const regionStreamsArr = []
  // 全部地域のstreamsが０かどうかのフラグ
  let blnNoStreams = true
  // 地域
  const regionArr = getRegion(audience)

  // 地域のstreamsを取得
  for (const region of regionArr) {
    regionStreamsArr.push(
      audience.filter(it => it.city === region).reduce((streams, it) => streams + it.streams, 0)
    )

    // 地域に「県、道」を外す（北海道除外）
    let strCity = ''
    if (region !== '北海道' && region !== null) {
      strCity = region.substring(0, region.length - 1)
    } else {
      strCity = region
    }

    // 地域　+ steams
    if (strCity != null) {
      resultAll.push([
        strCity,
        audience.filter(it => it.city === region).reduce((streams, it) => streams + it.streams, 0),
      ])
    }
  }

  // 地域のstreamsが全部0かどうかをチェックして、全部０の場合、空白の配列をreturnして、画面を非表示する
  for (const streams of regionStreamsArr) {
    if (streams !== 0) {
      blnNoStreams = false
      break
    }
  }

  if (blnNoStreams) {
    return []
  }

  // 地域の情報をreturnする
  return resultAll
}

export const getLoading = createSelector(
  getAudienceState,
  state => state.loading
)

export const getLoaded = createSelector(
  getAudienceState,
  state => state.loaded
)

export const getAllEmpty = createSelector(
  getAudienceState,
  state =>
    (state.appleMusicInfo == null || state.appleMusicInfo.length === 0) &&
    (state.spotifyInfo == null || state.spotifyInfo.length === 0) &&
    (state.spotifyRegionInfo == null || state.spotifyRegionInfo.length === 0) &&
    (state.youtubeInfo == null || state.youtubeInfo.length === 0) &&
    (state.umhpInfo == null || state.umhpInfo.length === 0) &&
    (state.umhpRegionInfo == null || state.umhpRegionInfo.length === 0) &&
    (state.newsletterInfo == null || state.newsletterInfo.length === 0) &&
    (state.enqueteInfo == null || state.enqueteInfo.length === 0) &&
    (state.lineInfo == null || state.lineInfo.length === 0) &&
    (state.lineRegionInfo == null || state.lineRegionInfo.length === 0)
)

export const getCsvData = createSelector(
  getAudienceState,
  getCriteriaFrom,
  getCriteriaTo,
  (state, from, to) => {
    const data = []
    data.push([
      '集計期間',
      moment(from).format('YYYY-MM-DD') + ' ~ ' + moment(to).format('YYYY-MM-DD'),
    ])
    data.push([])
    data.push(['デモグラフィック性別', '分類', '集計値', '比率（％）'])

    // Apple Music
    const appleMusicTotal = state.appleMusicInfo.reduce((streams, it) => streams + it.streams, 0)

    if (appleMusicTotal > 0) {
      data.push(
        convert2AudienceGenderArray(
          'Apple Music (性別)',
          '男性',
          'Male',
          appleMusicTotal,
          state.appleMusicInfo
        )
      )
      data.push(
        convert2AudienceGenderArray(
          'Apple Music (性別)',
          '女性',
          'Female',
          appleMusicTotal,
          state.appleMusicInfo
        )
      )
      data.push(
        convert2AudienceGenderArray(
          'Apple Music (性別)',
          '不明',
          'Data Unknown',
          appleMusicTotal,
          state.appleMusicInfo
        )
      )
    }

    // Spotify
    const spotifyTotal = state.spotifyInfo.reduce((streams, it) => streams + it.streams, 0)

    if (spotifyTotal > 0) {
      data.push(
        convert2AudienceGenderArray(
          'Spotify (性別)',
          '男性',
          'male',
          spotifyTotal,
          state.spotifyInfo
        )
      )
      data.push(
        convert2AudienceGenderArray(
          'Spotify (性別)',
          '女性',
          'female',
          spotifyTotal,
          state.spotifyInfo
        )
      )
      data.push(
        convert2AudienceGenderArray('Spotify (性別)', '不明', '', spotifyTotal, state.spotifyInfo)
      )
    }

    // LINE MUSIC
    const lineTotal = state.lineInfo.reduce((streams, it) => streams + it.streams, 0)

    if (lineTotal > 0) {
      data.push(
        convert2AudienceGenderArray('LINE MUSIC (性別)', '男性', 'm', lineTotal, state.lineInfo)
      )
      data.push(
        convert2AudienceGenderArray('LINE MUSIC (性別)', '女性', 'f', lineTotal, state.lineInfo)
      )
      data.push(
        convert2AudienceGenderArray(
          'LINE MUSIC (性別)',
          '不明',
          'unknown',
          lineTotal,
          state.lineInfo
        )
      )
    }

    // YouTube
    const youtubeTotal = state.youtubeInfo.reduce((streams, it) => streams + it.streams, 0)

    if (youtubeTotal > 0) {
      data.push(
        convert2AudienceGenderArray(
          'YouTube (性別)',
          '男性',
          'MALE',
          youtubeTotal,
          state.youtubeInfo
        )
      )
      data.push(
        convert2AudienceGenderArray(
          'YouTube (性別)',
          '女性',
          'FEMALE',
          youtubeTotal,
          state.youtubeInfo
        )
      )
      data.push(
        convert2AudienceGenderArray(
          'YouTube (性別)',
          '不明',
          'GENDER_OTHER',
          youtubeTotal,
          state.youtubeInfo
        )
      )
    }

    // UMHP
    const umhpTotal = state.umhpInfo.reduce((streams, it) => streams + it.streams, 0)

    if (umhpTotal > 0) {
      data.push(
        convert2AudienceGenderArray(
          'UM ホームページ (性別)',
          '男性',
          'male',
          umhpTotal,
          state.umhpInfo
        )
      )
      data.push(
        convert2AudienceGenderArray(
          'UM ホームページ (性別)',
          '女性',
          'female',
          umhpTotal,
          state.umhpInfo
        )
      )
    }

    // Newsletter
    const newsletterTotal = state.newsletterInfo.reduce((streams, it) => streams + it.streams, 0)

    if (newsletterTotal > 0) {
      data.push(
        convert2AudienceGenderArray(
          'ニュースレター (性別)',
          '男性',
          '男性',
          newsletterTotal,
          state.newsletterInfo
        )
      )
      data.push(
        convert2AudienceGenderArray(
          'ニュースレター (性別)',
          '女性',
          '女性',
          newsletterTotal,
          state.newsletterInfo
        )
      )
      data.push(
        convert2AudienceGenderArray(
          'ニュースレター (性別)',
          '不明',
          '不明',
          newsletterTotal,
          state.newsletterInfo
        )
      )
    }
    // Enquete
    const enqueteTotal = state.enqueteInfo.reduce((streams, it) => streams + it.streams, 0)

    if (enqueteTotal > 0) {
      data.push(
        convert2AudienceGenderArray(
          '購入者アンケート (性別)',
          '男性',
          '男性',
          enqueteTotal,
          state.enqueteInfo
        )
      )
      data.push(
        convert2AudienceGenderArray(
          '購入者アンケート (性別)',
          '女性',
          '女性',
          enqueteTotal,
          state.enqueteInfo
        )
      )
      data.push(
        convert2AudienceGenderArray(
          '購入者アンケート (性別)',
          '不明',
          '不明',
          enqueteTotal,
          state.enqueteInfo
        )
      )
    }

    data.push([])
    data.push(['デモグラフィック年齢', '分類1', '分類２', '集計値', '比率（％）'])

    // apple music 年齢
    if (appleMusicTotal > 0) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < ageAppMusicArr.length; i++) {
        for (let j = 0; j < genderArr.length; j++) {
          if (ageAppMusicArr[i] === 'Data Unknown') {
            data.push(
              convert2AudienceAgeArray(
                'Apple Music (年齢)',
                ageAppMusicArr[i],
                '不明',
                genderArr[j],
                genderAppMusicArr[j],
                state.appleMusicInfo
              )
            )
          } else {
            data.push(
              convert2AudienceAgeArray(
                'Apple Music (年齢)',
                ageAppMusicArr[i],
                ageAppMusicArr[i],
                genderArr[j],
                genderAppMusicArr[j],
                state.appleMusicInfo
              )
            )
          }
        }
      }
    }

    // spotify 年齢
    if (spotifyTotal > 0) {
      const ageSpotifyArr = getAgeGroup(state.spotifyInfo)
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < ageSpotifyArr.length; i++) {
        for (let j = 0; j < genderArr.length; j++) {
          if (ageSpotifyArr[i] === 'Unknown') {
            data.push(
              convert2AudienceAgeArray(
                'Spotify (年齢)',
                ageSpotifyArr[i],
                '不明',
                genderArr[j],
                genderSpotifyArr[j],
                state.spotifyInfo
              )
            )
          } else {
            data.push(
              convert2AudienceAgeArray(
                'Spotify (年齢)',
                ageSpotifyArr[i],
                ageSpotifyArr[i],
                genderArr[j],
                genderSpotifyArr[j],
                state.spotifyInfo
              )
            )
          }
        }
      }
    }

    // LINE MUSIC 年齢
    if (lineTotal > 0) {
      const ageLineArr = getAgeGroup(state.lineInfo)
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < ageLineArr.length; i++) {
        for (let j = 0; j < genderArr.length; j++) {
          if (ageLineArr[i] === 'unknown' || ageLineArr[i] === '*') {
            data.push(
              convert2AudienceAgeArray(
                'LINE MUSIC (年齢)',
                ageLineArr[i],
                '不明',
                genderArr[j],
                genderLineArr[j],
                state.lineInfo
              )
            )
          } else {
            data.push(
              convert2AudienceAgeArray(
                'LINE MUSIC (年齢)',
                ageLineArr[i],
                ageLineArr[i],
                genderArr[j],
                genderLineArr[j],
                state.lineInfo
              )
            )
          }
        }
      }
    }

    // Youtube 年齢
    if (youtubeTotal > 0) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < keyAgeYoutubeArr.length; i++) {
        for (let j = 0; j < genderArr.length; j++) {
          data.push(
            convert2AudienceAgeArray(
              'YouTube (年齢)',
              keyAgeYoutubeArr[i],
              ageYoutubeArr[i],
              genderArr[j],
              genderYoutubeArr[j],
              state.youtubeInfo
            )
          )
        }
      }
    }

    // UM ホームページ 年齢
    if (umhpTotal > 0) {
      for (let i = 0; i < ageUmhpArr.length; i++) {
        for (let j = 0; j < genderUmhpViewArr.length; j++) {
          data.push(
            convert2AudienceAgeArray(
              'UM ホームページ (年齢)',
              ageUmhpArr[i],
              ageUmhpArr[i],
              genderUmhpViewArr[j],
              genderUmhpArr[j],
              state.umhpInfo
            )
          )
        }
      }
    }

    // Newsletter 年齢
    if (newsletterTotal > 0) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < 52; i++) {
        // tslint:disable-next-line: prefer-for-of
        for (let j = 0; j < genderArr.length; j++) {
          data.push(
            convert2AudienceAgeArray(
              'ニュースレター (年齢)',
              (i + 13).toString(),
              (i + 13).toString(),
              genderArr[j],
              genderArr[j],
              state.newsletterInfo
            )
          )
        }
      }
      // 65歳以上の場合
      // tslint:disable-next-line: prefer-for-of
      for (let j = 0; j < genderArr.length; j++) {
        data.push(
          convert2AudienceAgeArray(
            'ニュースレター (年齢)',
            '65+',
            '65+',
            genderArr[j],
            genderArr[j],
            state.newsletterInfo
          )
        )
      }
    }

    // 購入者アンケート 年齢
    if (enqueteTotal > 0) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < 52; i++) {
        // tslint:disable-next-line: prefer-for-of
        for (let j = 0; j < genderArr.length; j++) {
          data.push(
            convert2AudienceAgeArray(
              '購入者アンケート (年齢)',
              (i + 13).toString(),
              (i + 13).toString(),
              genderArr[j],
              genderArr[j],
              state.enqueteInfo
            )
          )
        }
      }
      // 65歳以上の場合
      // tslint:disable-next-line: prefer-for-of
      for (let j = 0; j < genderArr.length; j++) {
        data.push(
          convert2AudienceAgeArray(
            '購入者アンケート (年齢)',
            '65+',
            '65+',
            genderArr[j],
            genderArr[j],
            state.enqueteInfo
          )
        )
      }
    }

    data.push([])
    data.push(['デモグラフィック地域', '分類', '集計値', '比率（％）'])

    // Spotify地域
    const spotifyRegionTotal = state.spotifyRegionInfo.reduce(
      (streams, it) => streams + it.streams,
      0
    )

    if (spotifyRegionTotal > 0) {
      const cityArry = getRegion(state.spotifyRegionInfo)
      for (const city of cityArry) {
        data.push(
          convert2AudiencerRegionArray(
            'Spotify (地域)',
            city,
            spotifyRegionTotal,
            state.spotifyRegionInfo
          )
        )
      }
    }

    // LINE MUSIC地域
    const lineRegionTotal = state.lineRegionInfo.reduce((streams, it) => streams + it.streams, 0)

    if (lineRegionTotal > 0) {
      const cityArry = getRegion(state.lineRegionInfo)
      for (const city of cityArry) {
        data.push(
          convert2AudiencerRegionArray(
            'LINE MUSIC (地域)',
            city,
            lineRegionTotal,
            state.lineRegionInfo
          )
        )
      }
    }

    // UM ホームページ地域
    const umhpRegionTotal = state.umhpRegionInfo.reduce((streams, it) => streams + it.streams, 0)

    if (umhpRegionTotal > 0) {
      const cityArry = getRegion(state.umhpRegionInfo)
      for (const city of cityArry) {
        data.push(
          convert2AudiencerRegionArray(
            'UM ホームページ (地域)',
            city,
            umhpRegionTotal,
            state.umhpRegionInfo
          )
        )
      }
    }

    // Newsletter地域
    const newsletterRegionTotal = state.newsletterInfo.reduce(
      (streams, it) => streams + it.streams,
      0
    )

    if (newsletterRegionTotal > 0) {
      const cityArry = getRegion(state.newsletterInfo)
      for (const city of cityArry) {
        data.push(
          convert2AudiencerRegionArray(
            'ニュースレター (地域)',
            city,
            newsletterRegionTotal,
            state.newsletterInfo
          )
        )
      }
    }

    // 購入者アンケート地域
    const enqueteRegionTotal = state.enqueteInfo.reduce((streams, it) => streams + it.streams, 0)

    if (enqueteRegionTotal > 0) {
      const cityArry = getRegion(state.enqueteInfo)
      for (const city of cityArry) {
        data.push(
          convert2AudiencerRegionArray(
            '購入者アンケート (地域)',
            city,
            enqueteRegionTotal,
            state.enqueteInfo
          )
        )
      }
    }

    return data
  }
)

function convert2AudiencerRegionArray(
  type: string,
  city: string,
  total: number,
  audience: AudienceInfo[]
): any[] {
  const regionTotal = audience
    .filter(it => it.city === city)
    .reduce((streams, it) => streams + it.streams, 0)
  return [type, city, regionTotal, ((regionTotal / total) * 100).toFixed(1)]
}

function convert2AudienceGenderArray(
  type: string,
  gender: string,
  key: string,
  total: number,
  audience: AudienceInfo[]
): any[] {
  const genderTotal = audience
    .filter(it => it.gender === key)
    .reduce((streams, it) => streams + it.streams, 0)
  return [type, gender, genderTotal, ((genderTotal / total) * 100).toFixed(1)]
}

function convert2AudienceAgeArray(
  type: string,
  keyAgeGroup: string,
  ageGroup: string,
  gender: string,
  keyGender: string,
  audience: AudienceInfo[]
): any[] {
  const ageGenderTotal = audience
    .filter(it => it.ageGroup === keyAgeGroup && it.gender === keyGender)
    .reduce((streams, it) => streams + it.streams, 0)
  const ageTotal = audience
    .filter(it => it.ageGroup === keyAgeGroup)
    .reduce((streams, it) => streams + it.streams, 0)
  let agePercent = '0.0'
  if (ageTotal !== 0) {
    agePercent = ((ageGenderTotal / ageTotal) * 100).toFixed(1)
  }
  return [type, ageGroup, gender, ageGenderTotal, agePercent]
}
