import * as fromYoutubeGraph from '../reducers/youtube-graph.reducer'
import { createFeatureSelector, createSelector } from '@ngrx/store'
import { getCriteriaFrom, getCriteriaTo } from './criteria.selector'
import * as moment from 'moment'

export const getYoutubeGraph = createFeatureSelector<fromYoutubeGraph.State>('youtubeGraph')

export const getYoutube = createSelector(
  getYoutubeGraph,
  state => state.youtube
)

export const getLoading = createSelector(
  getYoutubeGraph,
  state => state.loading
)

export const getLoaded = createSelector(
  getYoutubeGraph,
  state => state.loaded
)

export const getYoutubeCsv = createSelector(
  getYoutubeGraph,
  getCriteriaFrom,
  getCriteriaTo,
  (state, from, to) => {
    const data = []
    data.push([
      '集計期間',
      moment(from).format('YYYY-MM-DD') + ' ~ ' + moment(to).format('YYYY-MM-DD'),
    ])
    data.push([])
    data.push(['日付'].concat([...new Set(state.youtube.map(item => item.youtubeType))]))
    const dates = [...new Set(state.youtube.map(item => item.streamDate))]
    const column = []
    dates.forEach(d => {
      const c: any[] = [d]
      column.push(c)
      state.youtube.map(youtube => {
        if (d === youtube.streamDate) {
          c.push(youtube.streams)
        }
      })
    })
    column.map(item => data.push(item))
    return data
  }
)
