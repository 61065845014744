import { createAction, props } from '@ngrx/store'
import { TrackSale } from 'src/app/models/track-sale.model'

export const loadTrackSalesSuccess = createAction(
  '[TrackSale] Load TrackSales Success',
  props<{ sales: TrackSale[] }>()
)

export const loadTrackSalesFailure = createAction(
  '[TrackSale] Load TrackSales Failure',
  props<{ error: any }>()
)
