import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-sp-sales',
  templateUrl: './sp-sales.component.html',
  styleUrls: ['./sp-sales.component.scss'],
})
export class SpSalesComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
