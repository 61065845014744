import { Action, createReducer, on } from '@ngrx/store'
import { HomepagesUser } from '../models/homepagesUser.model'
import {
  loadHomepagesUserGraphsSuccess,
  loadHomepagesUserGraphsFailure,
} from '../actions/homepages-user-graph.actions'
import { refreshData } from '../actions/criteria.actions'

export interface State {
  homepagesUser: HomepagesUser[]
  loading: boolean
  loaded: boolean
}

export const initialState: State = { homepagesUser: [], loading: false, loaded: false }

const homepagesUserGraphReducer = createReducer(
  initialState,
  on(refreshData, (state, action) => ({ ...state, loading: true, loaded: false })),
  on(loadHomepagesUserGraphsSuccess, (state, action) => ({
    homepagesUser: action.homepagesUser,
    loading: false,
    loaded: true,
  })),
  on(loadHomepagesUserGraphsFailure, (state, action) => state)
)

export function reducer(state: State | undefined, action: Action) {
  return homepagesUserGraphReducer(state, action)
}
