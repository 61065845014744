import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core'
import { GoogleChartComponent, ChartEvent } from 'angular-google-charts'
import { Observable, Subscription } from 'rxjs'
import { lineChartStyle4Lines } from 'src/app/utils/line-chart-style'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/reducers'
import {
  getLoading,
  getHomepagesUser,
  getHomepagesUserCsv,
} from 'src/app/selectors/homepages-user-graph.selector'
import { map, take } from 'rxjs/operators'
import { isEventDrawerOpened } from 'src/app/selectors/shell.selector'
import { getSelectedEventDate } from 'src/app/selectors/event.selector'
import { range } from 'src/app/utils/range'
import * as moment from 'moment'
import { openEventDrawer } from 'src/app/actions/shell.actions'
import { selectEventDate } from 'src/app/actions/event.actions'
import {
  getDataCollectByHomepagesUserView,
  getDataCollectLoading,
} from 'src/app/selectors/data-collections.selector'
import { HomepagesUser } from 'src/app/models/homepagesUser.model'
import { Artist } from 'src/app/models/artist.model'
import { getSelectedArtist } from 'src/app/selectors/artist.selector'
import { MediaObserver, MediaChange } from '@angular/flex-layout'
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout'
import { of } from 'rxjs'

@Component({
  selector: 'app-homepages-user-graph',
  templateUrl: './homepages-user-graph.component.html',
  styleUrls: ['./homepages-user-graph.component.scss'],
})
export class HomepagesUserGraphComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('chart', { static: false })
  chart: GoogleChartComponent

  @ViewChild('container', { static: false })
  container: ElementRef

  private widthOpened = false
  private widthWhenOpened = 0
  private widthWhenClosed = 0

  siteName$: Observable<string[]>
  units$: Observable<any[]>
  isLoading$: Observable<boolean>

  dataCollections$: Observable<boolean>
  dataCollectLoading$: Observable<boolean>

  unitsOptions = lineChartStyle4Lines

  private subscriptions: Subscription[] = []

  csvId = 'pv'
  csvData$: Observable<any[]>
  csvFileName = 'ホームページユーザー数'
  selectedArtist$: Observable<Artist>

  isHandset$ = this.breakpointObserver.observe(Breakpoints.Handset).pipe(map(it => it.matches))

  checkedSubAccounts: any[]
  checkLabels: { name: string; completed: boolean; disabled: boolean }[] = []
  allSiteName$: Observable<string[]>
  colorsOption: any[]

  constructor(
    private store: Store<AppState>,
    public media: MediaObserver,
    private breakpointObserver: BreakpointObserver
  ) {
    this.subscriptions.push(media.media$.subscribe((_: MediaChange) => {}))
  }

  ngOnInit() {
    this.getIniData()
    this.isLoading$ = this.store.select(getLoading)
    this.subscriptions.push(
      this.isLoading$.subscribe(it => {
        if (it) {
          this.getIniData()
        }
      })
    )

    this.dataCollections$ = this.store.select(getDataCollectByHomepagesUserView)
    this.dataCollectLoading$ = this.store.select(getDataCollectLoading)
    this.csvData$ = this.store.select(getHomepagesUserCsv)
    this.selectedArtist$ = this.store.select(getSelectedArtist)

    this.siteName$ = this.store
      .select(getHomepagesUser)
      .pipe(map(it => ['日付'].concat([...new Set(it.map(item => item.siteName))])))
    this.units$ = this.store.select(getHomepagesUser).pipe(
      map(it => {
        return this.convert2ChartData(it)
      })
    )

    this.subscriptions.push(
      this.store.select(isEventDrawerOpened).subscribe(opened => {
        if (!this.chart) {
          return
        }
        this.widthOpened = opened
        this.chart.width = opened ? this.widthWhenOpened : this.widthWhenClosed
        this.chart.ngOnChanges()
      })
    )

    this.subscriptions.push(
      this.store.select(getSelectedEventDate).subscribe(date => {
        if (!this.chart) {
          return
        }
        if (!date) {
          this.chart.wrapper.getChart().setSelection(null)
        }
        const ymd = moment(date).format('YYYY-MM-DD')
        const columnNum = this.chart.columnNames.length - 1
        this.units$.pipe(take(1)).subscribe(it => {
          const index = it.findIndex(data => data[0] === ymd)
          if (index !== -1) {
            const selections: { row: number; column: number }[] = range(0, columnNum).map(i => ({
              row: index,
              column: i + 1,
            }))
            this.chart.wrapper.getChart().setSelection(selections)
          }
        })
      })
    )

    if (this.media.isActive('lt-md')) {
      this.unitsOptions.legend.position = 'none'
      this.unitsOptions.chartArea.left = 45
    } else {
      this.unitsOptions.legend.position = 'top'
      this.unitsOptions.chartArea.left = 80
    }
  }

  getIniData() {
    this.allSiteName$ = this.store
      .select(getHomepagesUser)
      .pipe(map(it => [...new Set(it.map(item => item.siteName))]))

    this.siteName$ = this.store
      .select(getHomepagesUser)
      .pipe(map(it => ['日付'].concat([...new Set(it.map(item => item.siteName))])))
    this.units$ = this.store.select(getHomepagesUser).pipe(
      map(it => {
        return this.convert2ChartData(it)
      })
    )

    this.allSiteName$.subscribe(it => {
      this.checkLabels = []
      it.forEach(item => {
        this.checkLabels.push({ name: item, completed: true, disabled: false })
      })
    })

    // 取得先と色をマッピングして、グラフのオプションを設定する
    this.siteName$
      .pipe(
        map(it => {
          return this.mappingSourceAndColor(it)
        })
      )
      .subscribe(val => {
        this.colorsOption = []
        val.forEach(d => this.colorsOption.push(d))

        this.unitsOptions = {
          ...lineChartStyle4Lines,
          colors: this.colorsOption,
        }
      })
  }

  // 選択したsubAccountsのstreamsを表示する
  onChange() {
    // 一つのcheckboxだけをチェックしたら、disableする
    this.disableCheckbox()

    // checked subAccountsを取得する
    this.checkedSubAccounts = []
    this.checkLabels.forEach(it => {
      if (it.completed) {
        this.checkedSubAccounts.push(it.name)
      }
    })

    // 選択したsubAccountsを再取得する
    this.siteName$ = of(['日付'].concat(this.checkedSubAccounts))

    // 選択したsubAccountsのstreamsを再取得する
    this.units$ = this.store.select(getHomepagesUser).pipe(
      map(it => {
        return this.convertSelected2ChartData(it)
      })
    )

    // 選択したsubAccountsの色を再設定する
    this.siteName$
      .pipe(
        map(it => {
          // streamsの取得先と色をマッピング
          return this.mappingSourceAndColor(it)
        })
      )
      .subscribe(val => {
        // 色のオプションを取得する
        this.colorsOption = []
        val.forEach(d => this.colorsOption.push(d))

        // グラフのオプションを設定する
        this.unitsOptions = {
          ...lineChartStyle4Lines,
          colors: this.colorsOption,
        }
      })
  }

  private disableCheckbox() {
    var count = 0
    for (var i = 0; i < this.checkLabels.length; i++) {
      if (this.checkLabels[i].completed) {
        count++

        if (this.checkLabels[i].disabled) {
          this.checkLabels[i].disabled = false
        }
      }
    }
    if (count === 1) {
      for (var i = 0; i < this.checkLabels.length; i++) {
        if (this.checkLabels[i].completed) {
          this.checkLabels[i].disabled = true
        }
      }
    }
  }
  ngAfterViewInit() {
    if (!this.container) {
      return
    }

    this.widthWhenClosed = this.container.nativeElement.offsetWidth - 20
    this.widthWhenOpened = this.widthWhenClosed - 300
  }

  ngOnDestroy() {
    this.subscriptions.forEach(it => it.unsubscribe())
  }

  onResize() {
    if (!this.chart) {
      return
    }
    // リサイズ時、出来事の開閉状態によって算出が異なる
    if (this.widthOpened) {
      this.widthWhenOpened = this.container.nativeElement.offsetWidth - 20
      this.widthWhenClosed = this.widthWhenOpened + 300
      this.chart.width = this.widthWhenOpened
    } else {
      this.widthWhenClosed = this.container.nativeElement.offsetWidth - 20
      this.widthWhenOpened = this.widthWhenClosed - 300
      this.chart.width = this.widthWhenClosed
    }
    this.chart.ngOnChanges()
  }

  onSelect(event: ChartEvent[]) {
    if (event.length === 0) {
      return
    }

    const isLegendsSelected = event[0].row === null
    this.units$.pipe(take(1)).subscribe(it => {
      if (isLegendsSelected) {
        return
      }
      this.isHandset$.subscribe(h => {
        if (!h) {
          this.store.dispatch(openEventDrawer())
          this.store.dispatch(selectEventDate({ eventDate: it[event[0].row][0] }))
        }
      })
    })
  }

  private convert2ChartData(pageViews: HomepagesUser[]): any[] {
    const dates = [...new Set(pageViews.map(item => item.transactionDate))]
    const column = []
    dates.forEach(d => {
      const data: any[] = [d]
      column.push(data)
      pageViews.map(units => {
        if (d === units.transactionDate) {
          data.push(units.units)
        }
      })
    })
    return column
  }

  private convertSelected2ChartData(streams: HomepagesUser[]): any[] {
    const dates = [...new Set(streams.map(item => item.transactionDate))]
    const column = []
    dates.forEach(d => {
      const data: any[] = [d]
      column.push(data)
      streams.map(stream => {
        if (d === stream.transactionDate) {
          this.checkedSubAccounts.forEach(item => {
            if (item === stream.siteName) {
              data.push(stream.units)
            }
          })
        }
      })
    })
    return column
  }

  // 取得先と色をマッピング処理
  private mappingSourceAndColor(subAccounts: string[]): string[] {
    const colors = []
    subAccounts.forEach(s => {
      switch (s) {
        case 'UMホームページ': {
          colors.push('#cb5393')
          break
        }
        case 'UM特設ページ': {
          colors.push('#00a5e7')
          break
        }
        case '日付': {
          break
        }
      }
    })
    return colors
  }
}
