import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { AngularFireFunctions } from '@angular/fire/functions'
import { Event } from 'src/app/models/event.model'
import { ArtistId } from 'src/app/models/artist.model'
import * as moment from 'moment'

@Injectable({ providedIn: 'root' })
export class EventService {
  constructor(private functions: AngularFireFunctions) {}

  findEvents(artistId: ArtistId, from: Date, to: Date): Observable<Event[]> {
    return this.functions.httpsCallable<{ artistId: string; from: string; to: string }, Event[]>(
      'findEvents'
    )({
      artistId,
      from: moment(from).format('YYYY-MM-DD'),
      to: moment(to).format('YYYY-MM-DD'),
    })
  }
}
