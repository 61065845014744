import { createAction, props } from '@ngrx/store'
import { Reactions } from '../models/reactions.model'

export const loadReactionsGraphsSuccess = createAction(
  '[ReactionsGraph] Load ReactionsGraphs Success',
  props<{ reactions: Reactions[] }>()
)

export const loadReactionsGraphsFailure = createAction(
  '[ReactionsGraph] Load ReactionsGraphs Failure',
  props<{ error: any }>()
)
