import { Action, createReducer, on } from '@ngrx/store'
import * as ProjectSaleActions from '../actions/project-sale.actions'
import * as CriteriaActions from '../actions/criteria.actions'
import { ProjectSale } from 'src/app/models/project-sale.model'

export interface State {
  sales: ProjectSale[]
  loading: boolean
  loaded: boolean
}

export const initialState: State = {
  sales: [],
  loading: false,
  loaded: false,
}

const projectSaleReducer = createReducer(
  initialState,

  on(CriteriaActions.refreshData, state => ({ ...state, loading: true, loaded: false })),

  on(ProjectSaleActions.loadProjectSalesSuccess, (state, action) => ({
    ...state,
    sales: action.sales,
    loading: false,
    loaded: true,
  })),

  on(ProjectSaleActions.loadProjectSalesFailure, (state, _) => ({
    ...state,
    loading: false,
    loaded: false,
  }))
)

export function reducer(state: State | undefined, action: Action) {
  return projectSaleReducer(state, action)
}
