import { Action, createReducer, on } from '@ngrx/store'
import {
  CountableSummary,
  CountableLinkSummary,
  CountableSourceSummary,
  InstagramHashtag,
} from '../models/latest-aggregate-amounts.model'
import {
  loadLatestAggregateAamountsSuccess,
  loadLatestAggregateAamountsFailure,
} from '../actions/latest-aggregate-amounts.actions'
import { refreshData } from '../actions/criteria.actions'

export interface State {
  // Twitterフォロワー数
  twitterFollowers: CountableSourceSummary[]
  // Facebookフォロワー数
  facebookFollowers: CountableSourceSummary[]
  // Instagramフォロワー数
  instagramFollowers: CountableSourceSummary[]
  // Instagramハッシュタグ数
  instagramHashtag: InstagramHashtag
  // Youtubeチャンネル購読者数
  youtubeChannelSubscribers: CountableLinkSummary
  // ニュースレター購読者数
  newsletterSubscriber: CountableSummary
  // Spotifyフォロワー数
  spotifyFollowers: CountableLinkSummary
  // Lineフォロワー数
  lineFollowers: CountableLinkSummary
  // Tiktokフォロワー数
  tiktokFollowers: CountableLinkSummary

  loading: boolean
  loaded: boolean
}

export const initialState: State = {
  // Twitterフォロワー数
  twitterFollowers: [],
  // Facebookフォロワー数
  facebookFollowers: [],
  // Instagramフォロワー数
  instagramFollowers: [],
  // Instagramハッシュタグ数
  instagramHashtag: { hashtag: null, units: null, rate: null, hasUnits: false },
  // Youtubeチャンネル購読者数
  youtubeChannelSubscribers: { url: null, units: null, rate: null, hasUnits: false },
  // ニュースレター購読者数
  newsletterSubscriber: { units: null, rate: null, hasUnits: false },
  // Spotifyフォロワー数
  spotifyFollowers: { url: null, units: null, rate: null, hasUnits: false },
  // Lineフォロワー数
  lineFollowers: { url: null, units: null, rate: null, hasUnits: false },
  // Tiktokフォロワー数
  tiktokFollowers: { url: null, units: null, rate: null, hasUnits: false },

  loading: false,
  loaded: false,
}

const latestAggregateAmountsReducer = createReducer(
  initialState,
  on(refreshData, (state, action) => ({ ...state, loading: true, loaded: false })),
  on(loadLatestAggregateAamountsSuccess, (state, action) => ({
    // Twitterフォロワー数
    twitterFollowers: action.latestAggregateAmounts.twitterFollowers,
    // Facebookフォロワー数
    facebookFollowers: action.latestAggregateAmounts.facebookFollowers,
    // Instagramフォロワー数
    instagramFollowers: action.latestAggregateAmounts.instagramFollowers,
    // Instagramハッシュタグ数
    instagramHashtag: action.latestAggregateAmounts.instagramHashtag,
    // Youtubeチャンネル購読者数
    youtubeChannelSubscribers:
      action.latestAggregateAmounts.youtubeChannelSubscribers[0] == null
        ? initialState.youtubeChannelSubscribers
        : action.latestAggregateAmounts.youtubeChannelSubscribers[0],
    // ニュースレター購読者数
    newsletterSubscriber: action.latestAggregateAmounts.newsletterSubscriber,
    // Spotifyフォロワー数
    spotifyFollowers:
      action.latestAggregateAmounts.spotifyFollowers[0] == null
        ? initialState.spotifyFollowers
        : action.latestAggregateAmounts.spotifyFollowers[0],
    // Lineフォロワー数
    lineFollowers:
      action.latestAggregateAmounts.lineFollowers[0] == null
        ? initialState.lineFollowers
        : action.latestAggregateAmounts.lineFollowers[0],
    // Tiktokフォロワー数
    tiktokFollowers:
      action.latestAggregateAmounts.tiktokFollowers[0] == null
        ? initialState.tiktokFollowers
        : action.latestAggregateAmounts.tiktokFollowers[0],

    loading: false,
    loaded: true,
  })),
  on(loadLatestAggregateAamountsFailure, (state, action) => state)
)

export function reducer(state: State | undefined, action: Action) {
  return latestAggregateAmountsReducer(state, action)
}
