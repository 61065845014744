import { createSelector, createFeatureSelector } from '@ngrx/store'
import * as DataCollections from '../reducers/data-collections.reducer'

export const getDataCollectionsState = createFeatureSelector<DataCollections.State>(
  'dataCollections'
)

export const getDataCollections = createSelector(
  getDataCollectionsState,
  state => state.dataCollections
)

export const getDataCollectLoading = createSelector(
  getDataCollectionsState,
  state => state.loading
)

export const getDataCollectLoaded = createSelector(
  getDataCollectionsState,
  state => state.loaded
)

export const getDataCollectByTwitterFollower = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.twitterFlollowers
)

export const getDataCollectByFacebookFollower = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.facebookFollowers
)

export const getDataCollectByInstagramFollowers = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.instagramFollowers
)

export const getDataCollectByInstagramHashtag = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.instagramHashtag
)

export const getDataCollectByPhysicalShipments = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.physicalShipments
)

export const getDataCollectByPosPhysicalEstimates = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.posPhysicalEstimates
)

export const getDataCollectByStreams = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.streams
)

export const getDataCollectByDownloads = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.downloads
)

export const getDataCollectByUmphPageviews = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.umphPageviews
)

export const getDataCollectByUmphUserviews = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.umphUserviews
)

export const getDataCollectByGoogleSearch = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.googleSearch
)

export const getDataCollectBySpecialUmhpPageviews = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.specialUmhpPageviews
)

export const getDataCollectBySpecialUmhpUserviews = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.specialUmhpUserviews
)

export const getDataCollectByUdiscoverPageviews = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.udiscoverPageviews
)

export const getDataCollectByWikipediaPageviews = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.wikipediaPageviews
)

export const getDataCollectByTwitterKeywords = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.twitterKeywords
)

export const getDataCollectByUnitsGraph = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.unitsGraph
)

export const getDataCollectByStreamsGraph = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.streamsGraph
)

export const getDataCollectByPhysicalsGraph = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.physicalsGraph
)

export const getDataCollectByDownloadsGraph = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.downloadsGraph
)

export const getDataCollectByChartsOricon = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.chartsOricon
)

export const getDataCollectByChartsSpotify = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.chartsSpotify
)

export const getDataCollectByChartsTiktok = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.chartsTiktok
)

export const getDataCollectByChartsItunes = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.chartsItunes
)

export const getDataCollectByChartsAmazon = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.chartsAmazon
)

export const getDataCollectByChartsLine = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.chartsLine
)

export const getDataCollectByChartsAppleMusic = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.chartsAppleMusic
)

export const getDataCollectByChartsShazam = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.chartsShazam
)

export const getDataCollectByReactionsGraph = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.reactionsGraph
)

export const getDataCollectByPrAnalyzerGraph = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.prAnalyzerGraph
)

export const getDataCollectByHomepagesPageView = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.homepagesPageView
)

export const getDataCollectByHomepagesUserView = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.homepagesUserView
)

export const getDataCollectByNewsletterSubscriber = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.newsletterSubscriber
)

export const getDataCollectByYoutubeSubscriber = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.youtubeSubscriber
)

export const getDataCollectByUmstorePageviews = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.umstorePageviews
)

export const getDataCollectByTwitterTagCloud = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.twitterTagCloud
)

export const getDataCollectByAudienceAppleMusic = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.audienceAppleMusic
)

export const getDataCollectByAudienceSpotify = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.audienceSpotify
)

export const getDataCollectByAudienceLine = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.audienceLine
)

export const getDataCollectByAudienceYoutube = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.audienceYoutube
)

export const getDataCollectByAudienceUmhp = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.audienceUmhp
)

export const getDataCollectByAudienceNewsletter = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.audienceNewsletter
)

export const getDataCollectByAudienceEnquete = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.audienceEnquete
)

export const getDataCollectBySpotifyFollower = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.spotifyFollowers
)

export const getDataCollectByLineFollower = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.lineFollowers
)

export const getDataCollectByTiktokFollower = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.tiktokFollowers
)

export const getDataCollectByTiktokGraph = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.tiktokGraph
)

export const getDataCollectByShazams = createSelector(
  getDataCollectionsState,
  state => state.dataCollections.shazam
)
