import { Injectable } from '@angular/core'
import { AngularFireFunctions } from '@angular/fire/functions'
import { ArtistId } from '../models/artist.model'
import { Observable } from 'rxjs'
import { TiktokGraphPoint } from '../models/tiktok-graph.model'
import * as moment from 'moment'

@Injectable({
  providedIn: 'root',
})
export class TiktokGraphService {
  constructor(private functions: AngularFireFunctions) {}

  findTiktokGraph(artistId: ArtistId, from: Date, to: Date): Observable<TiktokGraphPoint[]> {
    return this.functions.httpsCallable<
      { artistId: string; from: string; to: string },
      TiktokGraphPoint[]
    >('findTiktokGraph')({
      artistId,
      from: moment(from).format('YYYY-MM-DD'),
      to: moment(to).format('YYYY-MM-DD'),
    })
  }
}
