import { createAction, props } from '@ngrx/store'
import { TiktokGraphPoint } from '../models/tiktok-graph.model'

export const loadTiktokGraphsSuccess = createAction(
  '[TiktokGraph] Load TiktokGraphs Success',
  props<{ tiktok: TiktokGraphPoint[] }>()
)
export const loadTiktokGraphsFailure = createAction(
  '[TiktokGraph] Load TiktokGraphs Failure',
  props<{ error: any }>()
)
