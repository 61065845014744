import { createAction, props } from '@ngrx/store'
import { StreamsBySubAccount } from '../models/streams-by-sub-account.model'

export const loadStreamsGraphsSuccess = createAction(
  '[StreamsGraph] Load StreamsGraphs Success',
  props<{ streams: StreamsBySubAccount[] }>()
)
export const loadStreamsGraphsFailure = createAction(
  '[StreamsGraph] Load StreamsGraphs Failure',
  props<{ error: any }>()
)
