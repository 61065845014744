import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as fromReducer from '../reducers/track-sale.reducer'
import * as moment from 'moment'
import { getCriteriaFrom, getCriteriaTo } from './criteria.selector'

export const getTrackSaleState = createFeatureSelector<fromReducer.State>('trackSales')

export const getTrackSales = createSelector(
  getTrackSaleState,
  state => state.sales
)

export const getTrackSaleLoading = createSelector(
  getTrackSaleState,
  state => state.loading
)

export const getTrackSaleLoaded = createSelector(
  getTrackSaleState,
  state => state.loaded
)

export const getTrackTrendsCsv = createSelector(
  getTrackSaleState,
  getCriteriaFrom,
  getCriteriaTo,
  (state, from, to) => {
    const data = []
    data.push([
      '集計期間',
      moment(from).format('YYYY-MM-DD') + ' ~ ' + moment(to).format('YYYY-MM-DD'),
    ])
    data.push([])
    const header = [
      '曲名',
      '売上（NET）',
      '売上（NET）先週比（％）',
      'アルバム換算数',
      'アルバム換算数先週比（％）',
      'トラックダウンロード数',
      'トラックダウンロード数先週比（％）',
      'ストリーム数',
      'ストリーム数先週比（％）',
    ]
    data.push(header)
    state.sales.map(item => {
      if (item.title != null) {
        data.push([
          item.title,
          item.salesCurrencyAmount,
          item.salesCurrencyAmountRate,
          item.trackAdjustedUnits,
          item.trackAdjustedUnitsRate,
          item.trackDownloads,
          item.trackDownloadsRate,
          item.streams,
          item.streamsRate,
        ])
      }
    })

    return data
  }
)
