import { Injectable } from '@angular/core'
import { ArtistId } from 'src/app/models/artist.model'
import { Observable } from 'rxjs'
import { AngularFireFunctions } from '@angular/fire/functions'
import { ProjectSale } from 'src/app/models/project-sale.model'

@Injectable({
  providedIn: 'root',
})
export class ProjectSaleService {
  constructor(private functions: AngularFireFunctions) {}

  findProjectSales(artistId: ArtistId): Observable<ProjectSale[]> {
    return this.functions.httpsCallable<{ artistId: string }, ProjectSale[]>('findProjectSales')({
      artistId,
    })
  }
}
