import { Action, createReducer, on } from '@ngrx/store'
import * as ArtistActions from '../actions/artist.actions'
import { Artist } from 'src/app/models/artist.model'

export interface State {
  artists: Artist[]
  artistsLoading: boolean
  artistsLoaded: boolean
  selectedArtist?: Artist
  loading: boolean
  loaded: boolean
}

export const initialState: State = {
  artists: [],
  artistsLoading: false,
  artistsLoaded: false,
  selectedArtist: null,
  loading: false,
  loaded: false,
}

const artistReducer = createReducer(
  initialState,

  on(ArtistActions.loadArtistsNamed, state => ({
    ...state,
    artistsLoading: true,
    artistsLoaded: false,
  })),

  on(ArtistActions.loadArtistsNamedSuccess, (state, action) => ({
    ...state,
    artistsLoading: false,
    artistsLoaded: true,
    artists: action.artists,
  })),

  on(ArtistActions.loadArtistsNamedFailure, (state, _) => ({
    ...state,
    artistsLoading: false,
    artistsLoaded: false,
  })),

  on(ArtistActions.selectArtist, (state, action) => ({
    ...state,
    selectedArtist: action.artist,
  })),

  on(ArtistActions.loadAndSelectArtist, state => ({ ...state, loading: true, loaded: false })),

  on(ArtistActions.loadAndSelectArtistSuccess, (state, action) => ({
    ...state,
    loading: false,
    loaded: true,
    selectedArtist: action.artist,
  })),

  on(ArtistActions.loadAndSelectArtistFailure, (state, action) => state),
  on(ArtistActions.clearSearchResult, (state, action) => ({ ...state, artists: [] }))
)

export function reducer(state: State | undefined, action: Action) {
  return artistReducer(state, action)
}
