import { Action, createReducer, on } from '@ngrx/store'
import { refreshData } from '../actions/criteria.actions'
import { loadUnitsGraphsSuccess, loadUnitsGraphsFailure } from '../actions/units-graph.actions'
import { Units } from '../models/units.model'

export interface State {
  units: Units[]
  loading: boolean
  loaded: boolean
}

export const initialState: State = { units: [], loading: false, loaded: false }

const unitsGraphReducer = createReducer(
  initialState,
  on(refreshData, (state, action) => ({ ...state, loading: true, loaded: false })),
  on(loadUnitsGraphsSuccess, (state, action) => ({
    units: action.units,
    loading: false,
    loaded: true,
  })),
  on(loadUnitsGraphsFailure, (state, action) => state)
)

export function reducer(state: State | undefined, action: Action) {
  return unitsGraphReducer(state, action)
}
