import { Action, createReducer, on } from '@ngrx/store'
import {
  CountableSummary,
  WikipediaPageview,
  TwitterKeywords,
} from '../models/period-aggregate-amounts.model'
import {
  loadPeriodAggregateAamountsSuccess,
  loadPeriodAggregateAamountsFailure,
} from '../actions/period-aggregate-amounts.actions'
import { refreshData } from '../actions/criteria.actions'

export interface State {
  // フィジカル売り上げ枚数
  physicalShipments: CountableSummary
  // フィジカル消化数
  posPhysicalEstimates: CountableSummary
  // ストリーム数
  streams: CountableSummary
  // ダウンロード数
  downloads: CountableSummary
  // UMホームページビュー
  umhpPageviews: CountableSummary
  // Google検索数
  googleSearch: CountableSummary
  // UM特設ページビュー
  specialUmhpPageviews: CountableSummary
  // UMストアページビュー
  umstorePageviews: CountableSummary
  // uDiscoverページビュー
  udiscoverPageviews: CountableSummary
  // Wikipedia
  wikipediaPageviews: WikipediaPageview[]
  // Twitterキーワード
  twitterKeywords: TwitterKeywords
  // shazams
  shazams: CountableSummary
  // UMホームページユーザービュー
  umhpUserviews: CountableSummary
  // UM特設ページユーザービュー
  specialUmhpUserviews: CountableSummary
  loading: boolean
  loaded: boolean
}

export const initialState: State = {
  // フィジカル売り上げ枚数
  physicalShipments: { units: null, rate: null, hasUnits: false },
  // フィジカル消化数
  posPhysicalEstimates: { units: null, rate: null, hasUnits: false },
  // ストリーム数
  streams: { units: null, rate: null, hasUnits: false },
  // ダウンロード数
  downloads: { units: null, rate: null, hasUnits: false },
  // UMホームページビュー
  umhpPageviews: { units: null, rate: null, hasUnits: false },
  // Google検索数
  googleSearch: { units: null, rate: null, hasUnits: false },
  // UM特設ページビュー
  specialUmhpPageviews: { units: null, rate: null, hasUnits: false },
  // UMストアページビュー
  umstorePageviews: { units: null, rate: null, hasUnits: false },
  // uDiscoverページビュー
  udiscoverPageviews: { units: null, rate: null, hasUnits: false },
  // Wikipedia
  wikipediaPageviews: [],
  // Twitterキーワード
  twitterKeywords: {
    containWords: null,
    excludeWords: null,
    units: null,
    rate: null,
    hasUnits: false,
  },
  // shazams
  shazams: { units: null, rate: null, hasUnits: false },
  // UMホームページユーザービュー
  umhpUserviews: { units: null, rate: null, hasUnits: false },
  // UM特設ページユーザービュー
  specialUmhpUserviews: { units: null, rate: null, hasUnits: false },
  loading: false,
  loaded: false,
}

const periodAggregateAmountsReducer = createReducer(
  initialState,
  on(refreshData, (state, action) => ({ ...state, loading: true, loaded: false })),
  on(loadPeriodAggregateAamountsSuccess, (state, action) => ({
    // フィジカル売り上げ枚数
    physicalShipments: action.periodAggregateAmounts.physicalShipments,
    // フィジカル消化数
    posPhysicalEstimates: action.periodAggregateAmounts.posPhysicalEstimates,
    // ストリーム数
    streams: action.periodAggregateAmounts.streams,
    // ダウンロード数
    downloads: action.periodAggregateAmounts.downloads,
    // UMホームページビュー
    umhpPageviews: action.periodAggregateAmounts.umhpPageviews,
    // Google検索数
    googleSearch: action.periodAggregateAmounts.googleSearch,
    // UM特設ページビュー
    specialUmhpPageviews: action.periodAggregateAmounts.specialUmhpPageviews,
    // UMストアページビュー
    umstorePageviews: action.periodAggregateAmounts.umstorePageviews,
    // uDiscoverページビュー
    udiscoverPageviews: action.periodAggregateAmounts.udiscoverPageviews,
    // Wikipedia
    wikipediaPageviews: action.periodAggregateAmounts.wikipediaPageviews,
    // Twitterキーワード
    twitterKeywords: action.periodAggregateAmounts.twitterKeywords,
    // shazams
    shazams: action.periodAggregateAmounts.shazams,
    // UMホームページユーザービュー
    umhpUserviews: action.periodAggregateAmounts.umhpUserviews,
    // UM特設ページユーザービュー
    specialUmhpUserviews: action.periodAggregateAmounts.specialUmhpUserviews,
    loading: false,
    loaded: true,
  })),
  on(loadPeriodAggregateAamountsFailure, (state, action) => state)
)

export function reducer(state: State | undefined, action: Action) {
  return periodAggregateAmountsReducer(state, action)
}
