import { ActionReducerMap, MetaReducer } from '@ngrx/store'
import { environment } from '../../environments/environment'
import * as fromShell from './shell.reducer'
import * as fromArtist from './artist.reducer'
import * as fromEvent from './event.reducer'
import * as fromCriteria from './criteria.reducer'
import * as fromProjectSale from './project-sale.reducer'
import * as fromTrackSale from './track-sale.reducer'
import * as fromUnitsGraph from './units-graph.reducer'
import * as fromDownloadUnitGraph from './download-unit-graph.reducer'
import * as fromStreamsGraph from './streams-graph.reducer'
import * as fromPhysicalsGraph from './physicals-graph.reducer'
import * as fromFavorite from './favorite.reducer'
import * as fromChart from './chart.reducer'
import * as fromReactionsGraph from './reactions-graph.reducer'
import * as fromHomepagesGraph from './homepages-graph.reducer'
import * as fromHomepagesUserGraph from './homepages-user-graph.reducer'
import * as fromDataCollections from './data-collections.reducer'
import * as fromTwitterTagCloud from './twitter-tag-cloud.reducer'
import * as fromPeriodAggregateAmounts from './period-aggregate-amounts.reducer'
import * as fromLatestAggregateAmounts from './latest-aggregate-amounts.reducer'
import * as fromAudience from './audience.reducer'
import * as fromYoutubeGraph from './youtube-graph.reducer'
import * as fromPrAnalyzerGraph from './pr-analyzer-graph.reducer'
import * as fromTiktokGraph from './tiktok-graph.reducer'
// import * as fromChartRank from './chart-rank.reducer'

export interface AppState {
  shell: fromShell.State
  artists: fromArtist.State
  events: fromEvent.State
  criteria: fromCriteria.State
  projectSales: fromProjectSale.State
  trackSales: fromTrackSale.State
  unitsGraph: fromUnitsGraph.State
  downloadUnitsGraph: fromDownloadUnitGraph.State
  streamsGraph: fromStreamsGraph.State
  physicalsGraph: fromPhysicalsGraph.State
  favorites: fromFavorite.State
  chart: fromChart.State
  reactionsGraph: fromReactionsGraph.State
  homepagesGraph: fromHomepagesGraph.State
  homepagesUserGraph: fromHomepagesUserGraph.State
  dataCollections: fromDataCollections.State
  twitterTagCloud: fromTwitterTagCloud.State
  periodAggregateAmounts: fromPeriodAggregateAmounts.State
  latestAggregateAmounts: fromLatestAggregateAmounts.State
  audience: fromAudience.State
  youtubeGraph: fromYoutubeGraph.State
  prAnalyzerGraph: fromPrAnalyzerGraph.State
  tiktokGraph: fromTiktokGraph.State
  // chartRank: fromChartRank.State
}

export const reducers: ActionReducerMap<AppState> = {
  shell: fromShell.reducer,
  artists: fromArtist.reducer,
  events: fromEvent.reducer,
  criteria: fromCriteria.reducer,
  projectSales: fromProjectSale.reducer,
  trackSales: fromTrackSale.reducer,
  unitsGraph: fromUnitsGraph.reducer,
  downloadUnitsGraph: fromDownloadUnitGraph.reducer,
  streamsGraph: fromStreamsGraph.reducer,
  physicalsGraph: fromPhysicalsGraph.reducer,
  favorites: fromFavorite.reducer,
  chart: fromChart.reducer,
  reactionsGraph: fromReactionsGraph.reducer,
  homepagesGraph: fromHomepagesGraph.reducer,
  homepagesUserGraph: fromHomepagesUserGraph.reducer,
  dataCollections: fromDataCollections.reducer,
  twitterTagCloud: fromTwitterTagCloud.reducer,
  periodAggregateAmounts: fromPeriodAggregateAmounts.reducer,
  latestAggregateAmounts: fromLatestAggregateAmounts.reducer,
  audience: fromAudience.reducer,
  youtubeGraph: fromYoutubeGraph.reducer,
  prAnalyzerGraph: fromPrAnalyzerGraph.reducer,
  tiktokGraph: fromTiktokGraph.reducer,
  // chartRank: fromChartRank.reducer,
}

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : []
