import { Component, OnInit, OnDestroy } from '@angular/core'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/reducers'
import { ChartPart } from 'src/app/models/chart.model'
import {
  getChartSpotifyRank,
  getLoading,
  getLoaded,
  getAllEmpty,
} from 'src/app/selectors/chart.selector'
import { Observable, Subscription } from 'rxjs'
import { map } from 'rxjs/operators'
import * as moment from 'moment'
import { MediaObserver, MediaChange } from '@angular/flex-layout'
import {
  getDataCollectByChartsSpotify,
  getDataCollectLoading,
} from 'src/app/selectors/data-collections.selector'
import { getCriteriaFrom } from 'src/app/selectors/criteria.selector'

@Component({
  selector: 'app-chart-item-spotify',
  templateUrl: './chart-item-spotify.component.html',
  styleUrls: ['./chart-item-spotify.component.scss'],
})
export class ChartItemSpotifyComponent implements OnInit, OnDestroy {
  spotifyInfo$: Observable<ChartPart[]>
  loading$: Observable<boolean>
  loaded$: Observable<boolean>
  allEmpty$: Observable<boolean>
  period$: Observable<any>
  private subscriptions: Subscription[] = []
  dataCollections$: Observable<boolean>
  dataCollectLoading$: Observable<boolean>
  criteriaFrom$: Observable<Date>

  constructor(public media: MediaObserver, private store: Store<AppState>) {
    this.subscriptions.push(media.media$.subscribe((_: MediaChange) => {}))
  }

  helpInfo: string = null

  ngOnInit() {
    this.spotifyInfo$ = this.store.select(getChartSpotifyRank)
    this.loading$ = this.store.select(getLoading)
    this.loaded$ = this.store.select(getLoaded)
    this.allEmpty$ = this.store.select(getAllEmpty)

    this.dataCollections$ = this.store.select(getDataCollectByChartsSpotify)
    this.dataCollectLoading$ = this.store.select(getDataCollectLoading)

    this.criteriaFrom$ = this.store.select(getCriteriaFrom)

    this.period$ = this.criteriaFrom$.pipe(
      map(it => {
        const from = moment(it)
          .subtract(3, 'days')
          .format('YYYY-MM-DD')
        const to = moment(it)
          .add(3, 'days')
          .format('YYYY-MM-DD')
        return { from, to }
      })
    )

    this.period$.subscribe(it => {
      this.helpInfo = `Weeklyは${it.from} ~ ${it.to} 期間の集計データ。`
    })
  }
  ngOnDestroy() {
    this.subscriptions.forEach(it => it.unsubscribe())
  }
}
