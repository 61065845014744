import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { refreshData } from '../actions/criteria.actions'
import { switchMap, catchError, map } from 'rxjs/operators'
import { of } from 'rxjs'
import { TwitterTagCloudService } from '../services/twitter-tag-cloud.service'
import {
  loadTwitterTagCloudSuccess,
  loadTwitterTagCloudFailure,
} from '../actions/twitter-tag-cloud.actions'

@Injectable()
export class TwitterTagCloudEffects {
  constructor(private actions$: Actions, private service: TwitterTagCloudService) {}

  loadTwitterTagCloud$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshData),
      switchMap(action =>
        this.service.findTwitterTagCloud(action.artistId, action.from, action.to).pipe(
          map(tagcloud => loadTwitterTagCloudSuccess({ tagcloud })),
          catchError(error => of(loadTwitterTagCloudFailure({ error })))
        )
      )
    )
  )
}
