import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-copyright',
  template: `
    <div class="copyright" fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="center center">
      <div>Copyright 2019 Universal Music Japan All Rights Reserved.</div>
    </div>
  `,
  styleUrls: ['./copyright.component.scss'],
})
export class CopyrightComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
