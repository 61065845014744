import { Action, createReducer, on } from '@ngrx/store'
import { Homepages } from '../models/homepages.model'
import {
  loadHomepagesGraphsSuccess,
  loadHomepagesGraphsFailure,
} from '../actions/homepages-graph.actions'
import { refreshData } from '../actions/criteria.actions'

export interface State {
  homepages: Homepages[]
  loading: boolean
  loaded: boolean
}

export const initialState: State = { homepages: [], loading: false, loaded: false }

const homepagesGraphReducer = createReducer(
  initialState,
  on(refreshData, (state, action) => ({ ...state, loading: true, loaded: false })),
  on(loadHomepagesGraphsSuccess, (state, action) => ({
    homepages: action.homepages,
    loading: false,
    loaded: true,
  })),
  on(loadHomepagesGraphsFailure, (state, action) => state)
)

export function reducer(state: State | undefined, action: Action) {
  return homepagesGraphReducer(state, action)
}
