import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { ChartService } from '../services/chart.service'
import * as chartActions from '../actions/chart.actions'
import * as CriteriaActions from '../actions/criteria.actions'
import { switchMap, map, catchError } from 'rxjs/operators'
import { of } from 'rxjs'

@Injectable()
export class ChartEffects {
  loadChart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CriteriaActions.refreshData),
      switchMap(action =>
        this.chartService.find(action.artistId, action.from, action.to).pipe(
          map(chart => chartActions.loadChartSuccess({ chart })),
          catchError(error => of(chartActions.loadChartFailure({ error })))
        )
      )
    )
  )

  constructor(private actions$: Actions, private chartService: ChartService) {}
}
