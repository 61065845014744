import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators'
import { of, from } from 'rxjs'

import * as ArtistActions from '../actions/artist.actions'
import * as CriteriaActions from '../actions/criteria.actions'
import { ArtistService } from 'src/app/services/artist.service'
import { getDefaultFromTo } from 'src/app/reducers/criteria.reducer'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/reducers'
import { getSelectedArtist } from 'src/app/selectors/artist.selector'

@Injectable()
export class ArtistEffects {
  private selectedArtist$ = this.store.select(getSelectedArtist)

  loadArtistsNamed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArtistActions.loadArtistsNamed),
      switchMap(action =>
        action.name.length > 0
          ? this.artistService.findArtistsNamed(action.name).pipe(
              map(artists => ArtistActions.loadArtistsNamedSuccess({ artists })),
              catchError(error => of(ArtistActions.loadArtistsNamedFailure({ error })))
            )
          : of(ArtistActions.loadArtistsNamedSuccess({ artists: [] }))
      )
    )
  )

  loadAndSelectArtist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArtistActions.loadAndSelectArtist),
      withLatestFrom(this.selectedArtist$),
      switchMap(([action, selected]) =>
        selected && selected.globalId === action.artistId
          ? from([
              ArtistActions.loadAndSelectArtistSuccess({ artist: selected }),
              CriteriaActions.updateDateRange({ ...getDefaultFromTo() }),
            ])
          : this.artistService.findArtistById(action.artistId).pipe(
              switchMap(artist => [
                ArtistActions.loadAndSelectArtistSuccess({ artist }),
                // アーティストが変更された場合は、
                // FROM TO の検索範囲を引き継がずにデフォルト値に変更します
                CriteriaActions.updateDateRange({ ...getDefaultFromTo() }),
              ])
            )
      )
    )
  )

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private artistService: ArtistService
  ) {}
}
