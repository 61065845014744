import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as fromCriteria from '../reducers/criteria.reducer'
import * as moment from 'moment'

export const getCriteriaState = createFeatureSelector<fromCriteria.State>('criteria')

export const getCriteriaFromTo = createSelector(
  getCriteriaState,
  state => state
)

export const getCriteriaFrom = createSelector(
  getCriteriaState,
  state => state.from
)

export const getCriteriaTo = createSelector(
  getCriteriaState,
  state => state.to
)

export const getCriteriaFromBehindOneDay = createSelector(
  getCriteriaFrom,
  from =>
    moment(from)
      .subtract(1, 'day')
      .toDate()
)

export const getCriteriaDays = createSelector(
  getCriteriaFrom,
  getCriteriaTo,
  (from, to) => moment(to).diff(from, 'days') + 1
)

export const getCriteriaPreviousFrom = createSelector(
  getCriteriaFrom,
  getCriteriaDays,
  (from, days) =>
    moment(from)
      .subtract(days, 'days')
      .toDate()
)

export const getCriteriaPreviousTo = createSelector(
  getCriteriaTo,
  getCriteriaDays,
  (to, days) =>
    moment(to)
      .subtract(days, 'days')
      .toDate()
)
