import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { LatestAggregateAmountsService } from '../services/latest-aggregate-amounts.service'
import * as latestAggregateAmountsActions from '../actions/latest-aggregate-amounts.actions'
import * as CriteriaActions from '../actions/criteria.actions'
import { switchMap, map, catchError } from 'rxjs/operators'
import { of } from 'rxjs'

@Injectable()
export class LatestAggregateAmountsEffects {
  loadLatestAggregateAmounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CriteriaActions.refreshData),
      switchMap(action =>
        this.latestAggregateAmountsService
          .findLatestAggregateAmounts(action.artistId, action.from, action.to)
          .pipe(
            map(latestAggregateAmountsModel =>
              latestAggregateAmountsActions.loadLatestAggregateAamountsSuccess({
                latestAggregateAmounts: latestAggregateAmountsModel,
              })
            ),
            catchError(error =>
              of(latestAggregateAmountsActions.loadLatestAggregateAamountsFailure({ error }))
            )
          )
      )
    )
  )

  constructor(
    private actions$: Actions,
    private latestAggregateAmountsService: LatestAggregateAmountsService
  ) {}
}
