import { Action, createReducer, on } from '@ngrx/store'
import { DownloadUnit } from '../models/download-unit.model'
import * as CriteriaActions from '../actions/criteria.actions'
import * as DownloadUnitActions from '../actions/download-unit-graph.actions'

export interface State {
  downloadUnits: DownloadUnit[]
  loading: boolean
  loaded: boolean
}

export const initialState: State = {
  downloadUnits: [],
  loading: false,
  loaded: false,
}

export const downloadUnitGraphReducer = createReducer(
  initialState,
  on(CriteriaActions.refreshData, (state, action) => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(DownloadUnitActions.loadDownloadUnitsSuccess, (state, action) => ({
    downloadUnits: action.downloadUnits,
    loaded: true,
    loading: false,
  })),
  on(DownloadUnitActions.loadDownloadUnitsFailure, (state, action) => state)
)

export function reducer(state: State | undefined, action: Action): State {
  return downloadUnitGraphReducer(state, action)
}
