import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core'

@Component({
  selector: 'app-sidenav',
  styleUrls: ['./sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="profile">
      <img
        class="avatar"
        src="https://pbs.twimg.com/profile_images/1761680883/daylilyfield_195x195_400x400.png"
      />
    </div>
    <mat-nav-list>
      <mat-list-item routerLink="/home">
        <mat-icon matListIcon>headset_mic</mat-icon>
        アーティスト
      </mat-list-item>
      <mat-list-item>
        <mat-icon matListIcon>library_music</mat-icon>
        作品
      </mat-list-item>
      <mat-list-item>
        <mat-icon matListIcon>settings</mat-icon>
        設定
      </mat-list-item>
      <mat-list-item>
        <mat-icon matListIcon>power_settings_new</mat-icon>
        ログアウト
      </mat-list-item>
    </mat-nav-list>
  `,
})
export class SidenavComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
