import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as fromEvent from '../reducers/event.reducer'
import { Event } from '../models/event.model'
import * as moment from 'moment'
import { getCriteriaFrom, getCriteriaTo } from './criteria.selector'

export const getEventState = createFeatureSelector<fromEvent.State>('events')

export const getEvents = createSelector(
  getEventState,
  getCriteriaTo,
  (state, to) => {
    let data: Event[] = []
    const eventGenre: string[] = [
      'release',
      'ad',
      'event',
      'sns',
      'push',
      'campaign',
      'ownedmedia',
      'media',
      'other',
      'television',
      'web',
    ]
    if (state.events.length > 0) {
      data = convert2EventArray(state.events, eventGenre, to)
    }

    return data.map(item => returnPageEventValue(item))
  }
)

function convert2EventArray(events: Event[], types: string[], to: Date): Event[] {
  const returnEventArr: Event[] = []
  const filterEventArr: Event[] = []

  for (let i = 6; i >= 0; i--) {
    const day = moment(to)
      .subtract(i, 'days')
      .format('YYYY-MM-DD')
    types.forEach(type => {
      if (events.filter(it => it.date === day && it.categoryId === type).length > 0) {
        events
          .filter(it => it.date === day && it.categoryId === type)
          .forEach((ele, index) => {
            if (index < 5) {
              filterEventArr.push(ele)
            }
          })
      }
    })
  }

  for (let i = 6; i >= 0; i--) {
    const day = moment(to)
      .subtract(i, 'days')
      .format('YYYY-MM-DD')
    filterEventArr
      .filter(item => item.date === day)
      .forEach((it, index) => {
        if (index < 5) {
          returnEventArr.push(it)
        }
      })
  }

  return returnEventArr
}

function returnPageEventValue(event: Event): Event {
  let strTitle
  if (event.categoryId === 'web') {
    strTitle = '【web】'.concat(event.title)
  } else if (event.categoryId === 'television') {
    strTitle = '【TV】'.concat(event.title, '「', event.programName, '」')
  } else {
    strTitle = event.title
  }
  return {
    recordId: event.recordId,
    categoryId: event.categoryId,
    date: event.date,
    title: strTitle,
    url: event.url,
    spread: event.spread,
    articleTitle: event.articleTitle,
    priorityMedia: event.priorityMedia,
    programName: event.programName,
    programCategory: event.programCategory,
    facebook: event.facebook,
    publicationAmount:
      event.publicationAmount !== null && event.publicationAmount !== ''
        ? '"' + event.publicationAmount + '"'
        : event.publicationAmount,
    toneAnalysis: event.toneAnalysis,
    quantitativeConversionCost: event.quantitativeConversionCost,
    qualitativeConversionCost: event.qualitativeConversionCost,
    reachCount: event.reachCount,
  }
}

export const getSelectedEventDate = createSelector(
  getEventState,
  state => state.selectedDate
)

export const isEventLoading = createSelector(
  getEventState,
  state => state.loading
)

export const isEventLoaded = createSelector(
  getEventState,
  state => state.loaded
)

export const getCsvData = createSelector(
  getEventState,
  getCriteriaFrom,
  getCriteriaTo,
  (state, from, to) => {
    const data = []
    data.push([
      '集計期間',
      moment(from).format('YYYY-MM-DD') + ' ~ ' + moment(to).format('YYYY-MM-DD'),
    ])
    data.push([])
    data.push([
      '掲載日',
      '媒体区分',
      '重点媒体',
      '媒体名',
      '波及',
      '記事タイトル',
      '番組名',
      '番組区分',
      'URL',
      'Facebook',
      '掲載量',
      '論調分析',
      '量的換算費',
      '質的換算費',
      'リーチ数',
    ])
    state.events.map(item => data.push(returnEventValue(item)))

    return data
  }
)

function returnEventValue(event: Event): any[] {
  let strMediakbn
  if (event.categoryId === 'web') {
    strMediakbn = 'ウェブ'
  } else if (event.categoryId === 'television') {
    strMediakbn = 'テレビ'
  } else {
    strMediakbn = event.categoryId
  }

  return [
    event.date,
    strMediakbn,
    event.priorityMedia,
    event.title,
    event.spread,
    event.articleTitle,
    event.programName,
    event.programCategory,
    event.url,
    event.facebook,
    event.publicationAmount !== null && event.publicationAmount !== ''
      ? '"' + event.publicationAmount + '"'
      : event.publicationAmount,
    event.toneAnalysis,
    event.quantitativeConversionCost,
    event.qualitativeConversionCost,
    event.reachCount,
  ]
}
