import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as fromShell from '../reducers/shell.reducer'

export const getShellState = createFeatureSelector<fromShell.State>('shell')

export const getTitle = createSelector(
  getShellState,
  state => state.title
)

export const getArtistPage = createSelector(
  getShellState,
  state => state.artistPage
)

export const isArtistPage = createSelector(
  getShellState,
  state => state.isArtistPage
)

export const isEventDrawerOpened = createSelector(
  getShellState,
  state => state.eventDrawerOpened
)
