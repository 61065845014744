import { Component, OnInit, ViewChildren, QueryList, AfterViewInit, Renderer2 } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Observable } from 'rxjs'
import { Artist } from '../../../models/artist.model'
import { AppState } from 'src/app/reducers'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import {
  getArtists,
  getArtistsLoading,
  getArtistsLoaded,
  getFavoritesArtistByGenre,
} from 'src/app/selectors/artist.selector'

@Component({
  selector: 'app-sp-top',
  templateUrl: './sp-top.component.html',
  styleUrls: ['./sp-top.component.scss'],
})
export class SpTopComponent implements OnInit, AfterViewInit {
  artists$: Observable<Artist[]>
  isArtistsLoading$: Observable<boolean>
  isArtistsLoaded$: Observable<boolean>
  artistQuery: FormControl

  orderArr: string[]

  @ViewChildren('orderTileDom1')
  orderTilesHogaku: QueryList<any>

  @ViewChildren('orderTileDom2')
  orderTilesYogaku: QueryList<any>

  favorites$: Observable<[string, Artist[]][]>

  artistHistory: Artist[]

  hogakuItem: Artist[]
  yogakuItem: Artist[]

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private renderer: Renderer2
  ) {}

  orderName: string

  ngOnInit() {
    this.artists$ = this.store.select(getArtists)
    this.isArtistsLoading$ = this.store.select(getArtistsLoading)
    this.isArtistsLoaded$ = this.store.select(getArtistsLoaded)
    this.favorites$ = this.store.select(getFavoritesArtistByGenre)

    this.orderArr = ['あ', 'か', 'さ', 'た', 'な', 'は', 'ま', 'や', 'ら', 'わ']
    this.orderName = '[あ-お]'

    this.artistQuery = new FormControl('')
    this.artistHistory = JSON.parse(localStorage.getItem('artistHistory')).reverse()

    // consumption idの対応でスマホン残ってるartist id閲覧履歴をクリアする(一か月、２か月後削除する) start
    for (let i = 0; i < this.artistHistory.length; i++) {
      if (this.artistHistory[i].id !== undefined) {
        this.artistHistory.splice(i, 1)
        i--
      }
    }
    // consumption idの対応でスマホン残ってるartist id閲覧履歴をクリアする(一か月、２か月後削除する) end

    const contains = (s: string) => new RegExp(`^${this.orderName}`, 'gu').test(s)
    this.favorites$.subscribe(element => {
      this.hogakuItem = element[0][1].filter(artist => contains(artist.sortingName))
      this.yogakuItem = element[1][1].filter(artist => contains(artist.sortingName))
    })
  }

  getArtistByalphabet(event: any): void {
    var Element = event ? event.srcElement : event.currentTarget;
    switch (Element.innerHTML) {
      case 'あ': {
        this.orderName = '[あ-お]'
        break
      }
      case 'か': {
        this.orderName = '[か-こが-ご]'
        break
      }
      case 'さ': {
        this.orderName = '[さ-そざ-ぞ]'
        break
      }
      case 'た': {
        this.orderName = '[た-とだ-ど]'
        break
      }
      case 'な': {
        this.orderName = '[な-の]'
        break
      }
      case 'は': {
        this.orderName = '[は-ほば-ぼぱ-ぽ]'
        break
      }
      case 'ま': {
        this.orderName = '[ま-も]'
        break
      }
      case 'や': {
        this.orderName = '[や-よ]'
        break
      }
      case 'ら': {
        this.orderName = '[ら-ろ]'
        break
      }
      case 'わ': {
        this.orderName = '[わ-ん]'
        break
      }
      default: {
        this.orderName = '[あ-お]'
        break
      }
    }

    const contains = (s: string) => new RegExp(`^${this.orderName}`, 'gu').test(s)

    if (Element.className === 'orderItem1') {
      this.orderTilesHogaku.forEach(elementRef =>
        this.renderer.setStyle(elementRef.nativeElement, 'backgroundColor', '')
      )
      this.favorites$.subscribe(element => {
        if (element[0][0] === '邦楽') {
          this.hogakuItem = element[0][1].filter(artist => contains(artist.sortingName))
        }
      })
    } else {
      this.orderTilesYogaku.forEach(elementRef =>
        this.renderer.setStyle(elementRef.nativeElement, 'backgroundColor', '')
      )
      this.favorites$.subscribe(element => {
        if (element[1][0] === '洋楽') {
          this.yogakuItem = element[1][1].filter(artist => contains(artist.sortingName))
        }
      })
    }
    Element.style.backgroundColor = '#ADADAD'
  }

  ngAfterViewInit() {
    this.orderTilesHogaku.forEach((elementRef, index) => {
      if (index === 0) {
        this.renderer.setStyle(elementRef.nativeElement, 'backgroundColor', '#ADADAD')
      }
    })

    this.orderTilesYogaku.forEach((elementRef, index) => {
      if (index === 0) {
        this.renderer.setStyle(elementRef.nativeElement, 'backgroundColor', '#ADADAD')
      }
    })
  }
}
