import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'

import { catchError, switchMap, map } from 'rxjs/operators'
import { of } from 'rxjs'

import * as ProjectSaleActions from '../actions/project-sale.actions'
import { ProjectSaleService } from 'src/app/services/project-sale.service'
import { refreshData } from '../actions/criteria.actions'

@Injectable()
export class ProjectSaleEffects {
  constructor(private actions$: Actions, private projectSaleService: ProjectSaleService) {}

  loadProjectSales$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshData),
      switchMap(action =>
        this.projectSaleService.findProjectSales(action.artistId).pipe(
          map(sales => ProjectSaleActions.loadProjectSalesSuccess({ sales })),
          catchError(error => of(ProjectSaleActions.loadProjectSalesFailure({ error })))
        )
      )
    )
  )
}
