import { Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy } from '@angular/core'
import { MediaObserver, MediaChange } from '@angular/flex-layout'
import { ChartPart } from 'src/app/models/chart.model'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-sp-chart-rank-items',
  template: `
    <mat-card>
      <div id="cd{{ chartName }}" class="chart-card-fold">
        <div fxLayout="row wrap" fxLayoutGap="8px" fxLayoutAlign="start center">
          <input
            type="text"
            id="inp{{ chartName }}"
            class="input-text"
            readonly="true"
            value="{{ chartName }}"
          />
          <div fxFlex="1 1 auto"></div>
          <mat-icon class="help" *ngIf="help" [matTooltip]="help">help_outline</mat-icon>
          <ng-container *ngIf="!dataCollections; then dateNoFix; else dateFix"></ng-container>
        </div>
        <div>
          <mat-list *ngFor="let info of infos">
            <div mat-subheader>{{ info.chartNameJp }}</div>
            <mat-list-item *ngFor="let rankingItem of info.chartItems">
              <mat-icon mat-list-icon>{{ info.icon }}</mat-icon>
              <div mat-list-icon class="ranking">
                <span class="rank-number">{{ rankingItem.currentRank }}</span>
                位
              </div>
              <div class="mat-list-text">
                <h4 class="title" mat-line>
                  {{ rankingItem.title }}
                </h4>
                <p ngClass class="rank" mat-line>
                  <ng-container *ngIf="media.isActive('lt-md')">
                    <span class="sp-prev-number">
                      （ 前週
                      {{ rankingItem.previousRank === null ? '-' : rankingItem.previousRank }}
                      位、最高 {{ rankingItem.rankTop }} 位 ）
                    </span>
                  </ng-container>
                  <ng-container *ngIf="!media.isActive('lt-md')">
                    （ 前週
                    {{ rankingItem.previousRank === null ? '-' : rankingItem.previousRank }}
                    位、最高 {{ rankingItem.rankTop }} 位 ）
                  </ng-container>
                </p>
                <ng-container *ngIf="rankingItem.creations_last_7_days">
                  <h5 class="creations" mat-line>
                    {{ 'Creations ' + (rankingItem.creations_last_7_days | number) }}
                  </h5>
                </ng-container>
              </div>
            </mat-list-item>
          </mat-list>
        </div>
      </div>
      <div fxLayoutAlign="center center">
        <ng-container *ngIf="infos.length > 1 || infos[0].chartItems.length > 1">
          <button
            id="unfold{{ chartName }}"
            mat-icon-button
            class="unfold-button"
            matTooltip="展開"
            (click)="unfold()"
          >
            <mat-icon class="fold-icon">expand_more</mat-icon>
          </button>
        </ng-container>
        <ng-container *ngIf="infos.length > 1 || infos[0].chartItems.length > 1">
          <button
            id="fold{{ chartName }}"
            mat-icon-button
            class="fold-button"
            matTooltip="折畳"
            (click)="fold()"
          >
            <mat-icon class="fold-icon">expand_less</mat-icon>
          </button>
        </ng-container>
      </div>
    </mat-card>

    <ng-template #dateFix>
      <img
        class="datafix_icon"
        matTooltip="各項目において選択した期間のデータが全部揃っている場合に、チェックマークが表示されます。"
        src="./assets/data_collections.png"
      />
    </ng-template>

    <ng-template #dateNoFix></ng-template>
  `,
  styleUrls: ['./sp-chart-rank-items.component.scss'],
})
export class SpChartRankItemsComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = []

  constructor(public media: MediaObserver) {
    this.subscriptions.push(media.media$.subscribe((_: MediaChange) => {}))
  }

  @Input()
  infos: ChartPart[]

  @Input()
  chartName: string

  @Input()
  help: string

  @Input()
  dataCollections: boolean

  ngOnInit() {}

  ngOnDestroy() {
    this.subscriptions.forEach(it => it.unsubscribe())
  }

  unfold(): void {
    document
      .getElementById('cd' + this.chartName)
      .setAttribute('style', 'height: auto;overflow: auto;')
    document.getElementById('unfold' + this.chartName).style.display = 'none'
    document.getElementById('fold' + this.chartName).style.display = 'inline'
    document.getElementById('inp' + this.chartName).focus()
  }

  fold(): void {
    document
      .getElementById('cd' + this.chartName)
      .setAttribute('style', 'height: 180px;overflow: hidden;')
    document.getElementById('unfold' + this.chartName).style.display = 'inline'
    document.getElementById('fold' + this.chartName).style.display = 'none'
  }
}
