import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef } from '@angular/core'
import { GoogleChartComponent, ChartEvent } from 'angular-google-charts'
import { Observable, Subscription } from 'rxjs'
import { lineChartStyle8Lines } from 'src/app/utils/line-chart-style'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/reducers'
import {
  getPhysicals,
  getLoading,
  getPhysicalsCsv,
} from 'src/app/selectors/physicals-graph.selector'
import { map, take } from 'rxjs/operators'
import { isEventDrawerOpened } from 'src/app/selectors/shell.selector'
import { getSelectedEventDate } from 'src/app/selectors/event.selector'
import * as moment from 'moment'
import { openEventDrawer } from 'src/app/actions/shell.actions'
import { selectEventDate } from 'src/app/actions/event.actions'
import { range } from 'src/app/utils/range'
import {
  getDataCollectByPhysicalsGraph,
  getDataCollectLoading,
} from 'src/app/selectors/data-collections.selector'
import { PhysicalsByCustomer } from 'src/app/models/physicals-by-customer.model'
import { Artist } from 'src/app/models/artist.model'
import { getSelectedArtist } from 'src/app/selectors/artist.selector'
import { MediaObserver, MediaChange } from '@angular/flex-layout'
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout'
import { of } from 'rxjs'

@Component({
  selector: 'app-physicals-graph',
  templateUrl: './physicals-graph.component.html',
  styleUrls: ['./physicals-graph.component.scss'],
})
export class PhysicalsGraphComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('chart', { static: false })
  chart: GoogleChartComponent

  @ViewChild('container', { static: false })
  container: ElementRef

  private widthOpened = false
  private widthWhenOpened = 0
  private widthWhenClosed = 0

  customers$: Observable<string[]>
  physicals$: Observable<any[]>
  isLoading$: Observable<boolean>

  dataCollections$: Observable<boolean>
  dataCollectLoading$: Observable<boolean>

  physicalsOptions = lineChartStyle8Lines

  private subscriptions: Subscription[] = []

  csvId = 'physical'
  csvData$: Observable<any[]>
  csvFileName = 'フィジカル消化数（実売値）'
  selectedArtist$: Observable<Artist>

  isHandset$ = this.breakpointObserver.observe(Breakpoints.Handset).pipe(map(it => it.matches))

  checkedSubAccounts: any[]
  checkLabels: { name: string; completed: boolean; disabled: boolean }[] = []
  allCustomers$: Observable<string[]>
  colorsOption: any[]

  constructor(
    private store: Store<AppState>,
    public media: MediaObserver,
    private breakpointObserver: BreakpointObserver
  ) {
    this.subscriptions.push(media.media$.subscribe((_: MediaChange) => {}))
  }

  ngOnInit() {
    this.getIniData()
    this.isLoading$ = this.store.select(getLoading)
    this.subscriptions.push(
      this.isLoading$.subscribe(it => {
        if (it) {
          this.getIniData()
        }
      })
    )

    this.dataCollections$ = this.store.select(getDataCollectByPhysicalsGraph)
    this.dataCollectLoading$ = this.store.select(getDataCollectLoading)
    this.csvData$ = this.store.select(getPhysicalsCsv)
    this.selectedArtist$ = this.store.select(getSelectedArtist)
    this.subscriptions.push(
      this.store.select(isEventDrawerOpened).subscribe(opened => {
        if (!this.chart) {
          return
        }
        this.widthOpened = opened
        this.chart.width = opened ? this.widthWhenOpened : this.widthWhenClosed
        this.chart.ngOnChanges()
      })
    )

    this.subscriptions.push(
      this.store.select(getSelectedEventDate).subscribe(date => {
        if (!this.chart) {
          return
        }
        if (!date) {
          this.chart.wrapper.getChart().setSelection(null)
        }
        const ymd = moment(date).format('YYYY-MM-DD')
        const columnNum = this.chart.columnNames.length - 1
        this.physicals$.pipe(take(1)).subscribe(it => {
          const index = it.findIndex(data => data[0] === ymd)
          if (index !== -1) {
            const selections: { row: number; column: number }[] = range(0, columnNum).map(i => ({
              row: index,
              column: i + 1,
            }))
            this.chart.wrapper.getChart().setSelection(selections)
          }
        })
      })
    )

    if (this.media.isActive('lt-md')) {
      this.physicalsOptions.legend.position = 'none'
      this.physicalsOptions.chartArea.left = 45
    } else {
      this.physicalsOptions.legend.position = 'top'
      this.physicalsOptions.chartArea.left = 80
    }
  }

  getIniData() {
    this.allCustomers$ = this.store
      .select(getPhysicals)
      .pipe(map(it => [...new Set(it.map(item => item.customer))]))

    this.customers$ = this.store
      .select(getPhysicals)
      .pipe(map(it => ['日付'].concat([...new Set(it.map(item => item.customer))])))

    this.physicals$ = this.store.select(getPhysicals).pipe(
      map(it => {
        return this.convert2ChartData(it)
      })
    )

    this.allCustomers$.subscribe(it => {
      this.checkLabels = []
      it.forEach(item => {
        this.checkLabels.push({ name: item, completed: true, disabled: false })
      })
    })

    // 取得先と色をマッピングして、グラフのオプションを設定する
    this.customers$
      .pipe(
        map(it => {
          return this.mappingSourceAndColor(it)
        })
      )
      .subscribe(val => {
        this.colorsOption = []
        val.forEach(d => this.colorsOption.push(d))

        this.physicalsOptions = {
          ...lineChartStyle8Lines,
          colors: this.colorsOption,
        }
      })
  }

  // 選択したsubAccountsのstreamsを表示する
  onChange() {
    // 一つのcheckboxだけをチェックしたら、disableする
    this.disableCheckbox()

    // checked subAccountsを取得する
    this.checkedSubAccounts = []
    this.checkLabels.forEach(it => {
      if (it.completed) {
        this.checkedSubAccounts.push(it.name)
      }
    })

    // 選択したsubAccountsを再取得する
    this.customers$ = of(['日付'].concat(this.checkedSubAccounts))

    // 選択したsubAccountsのstreamsを再取得する
    this.physicals$ = this.store.select(getPhysicals).pipe(
      map(it => {
        return this.convertSelected2ChartData(it)
      })
    )

    // 選択したsubAccountsの色を再設定する
    this.customers$
      .pipe(
        map(it => {
          // streamsの取得先と色をマッピング
          return this.mappingSourceAndColor(it)
        })
      )
      .subscribe(val => {
        // 色のオプションを取得する
        this.colorsOption = []
        val.forEach(d => this.colorsOption.push(d))

        // グラフのオプションを設定する
        this.physicalsOptions = {
          ...lineChartStyle8Lines,
          colors: this.colorsOption,
        }
      })
  }

  private disableCheckbox() {
    var count = 0
    for (var i = 0; i < this.checkLabels.length; i++) {
      if (this.checkLabels[i].completed) {
        count++

        if (this.checkLabels[i].disabled) {
          this.checkLabels[i].disabled = false
        }
      }
    }
    if (count === 1) {
      for (var i = 0; i < this.checkLabels.length; i++) {
        if (this.checkLabels[i].completed) {
          this.checkLabels[i].disabled = true
        }
      }
    }
  }

  ngAfterViewInit() {
    if (!this.container) {
      return
    }
    this.widthWhenClosed = this.container.nativeElement.offsetWidth - 20
    this.widthWhenOpened = this.widthWhenClosed - 300 /* 右ドロワーの幅に合わせて！ */
  }

  ngOnDestroy() {
    this.subscriptions.forEach(it => it.unsubscribe())
  }

  onResize() {
    if (!this.chart) {
      return
    }
    // リサイズ時、出来事の開閉状態によって算出が異なる
    if (this.widthOpened) {
      this.widthWhenOpened = this.container.nativeElement.offsetWidth - 20
      this.widthWhenClosed = this.widthWhenOpened + 300
      this.chart.width = this.widthWhenOpened
    } else {
      this.widthWhenClosed = this.container.nativeElement.offsetWidth - 20
      this.widthWhenOpened = this.widthWhenClosed - 300
      this.chart.width = this.widthWhenClosed
    }
    this.chart.ngOnChanges()
  }

  onSelect(event: ChartEvent[]): void {
    if (event.length === 0) {
      return
    }

    const isLegendsSelected = event[0].row === null
    this.physicals$.pipe(take(1)).subscribe(it => {
      if (isLegendsSelected) {
        return
      }
      this.isHandset$.subscribe(h => {
        if (!h) {
          this.store.dispatch(openEventDrawer())
          this.store.dispatch(selectEventDate({ eventDate: it[event[0].row][0] }))
        }
      })
    })
  }

  private convert2ChartData(physicals: PhysicalsByCustomer[]): any[] {
    const dates = [...new Set(physicals.map(item => item.salesDate))]
    const column = []
    dates.forEach(d => {
      const data: any[] = [d]
      column.push(data)
      physicals.map(physical => {
        if (d === physical.salesDate) {
          data.push(physical.units)
        }
      })
    })
    return column
  }

  private convertSelected2ChartData(streams: PhysicalsByCustomer[]): any[] {
    const dates = [...new Set(streams.map(item => item.salesDate))]
    const column = []
    dates.forEach(d => {
      const data: any[] = [d]
      column.push(data)
      streams.map(stream => {
        if (d === stream.salesDate) {
          this.checkedSubAccounts.forEach(item => {
            if (item === stream.customer) {
              data.push(stream.units)
            }
          })
        }
      })
    })
    return column
  }

  // 取得先と色をマッピング処理
  private mappingSourceAndColor(subAccounts: string[]): string[] {
    const colors = []
    subAccounts.forEach(s => {
      switch (s) {
        case 'アマゾン': {
          colors.push('#cb5393')
          break
        }
        case 'タワーレコード': {
          colors.push('#a0c238')
          break
        }
        case '山野楽器': {
          colors.push('#00a5e7')
          break
        }
        case '新星堂': {
          colors.push('#0168b3')
          break
        }
        case 'Ｃ．Ｃ．Ｃ': {
          colors.push('#d685b0')
          break
        }
        case 'ＨＭＶ': {
          colors.push('#00984b')
          break
        }
        case '日付': {
          break
        }
      }
    })
    return colors
  }
}
