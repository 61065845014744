import * as fromPrAnalyzerGraph from '../reducers/pr-analyzer-graph.reducer'
import { createFeatureSelector, createSelector } from '@ngrx/store'
import { getCriteriaFrom, getCriteriaTo } from './criteria.selector'
import * as moment from 'moment'

export const getPrAnalyzerGraph = createFeatureSelector<fromPrAnalyzerGraph.State>(
  'prAnalyzerGraph'
)

export const getPrAnalyzer = createSelector(
  getPrAnalyzerGraph,
  state => state.prAnalyzer
)

export const getLoading = createSelector(
  getPrAnalyzerGraph,
  state => state.loading
)

export const getLoaded = createSelector(
  getPrAnalyzerGraph,
  state => state.loaded
)

// export const getPrAnalyzerCsv = createSelector(
//   getPrAnalyzerGraph,
//   getCriteriaFrom,
//   getCriteriaTo,
//   (state, from, to) => {
//     const data = []
//     data.push([
//       '集計期間',
//       moment(from).format('YYYY-MM-DD') + ' ~ ' + moment(to).format('YYYY-MM-DD'),
//     ])
//     data.push([])
//     data.push(['日付'].concat([...new Set(state.prAnalyzer.map(item => item.type))]))
//     const dates = [...new Set(state.prAnalyzer.map(item => item.date))]
//     const column = []
//     dates.forEach(d => {
//       const c: any[] = [d]
//       column.push(c)
//       state.prAnalyzer.map(prAnalyzer => {
//         if (d === prAnalyzer.date) {
//           c.push(prAnalyzer.streams)
//         }
//       })
//     })
//     column.map(item => data.push(item))
//     return data
//   }
// )
