import { Action, createReducer, on } from '@ngrx/store'
import * as CriteriaActions from '../actions/criteria.actions'
import * as moment from 'moment'

export const getDefaultFromTo = () => {
  const now = moment()
  const from = moment()
    .subtract(7, 'days')
    .startOf('isoWeek')
  const to = moment(from).add(6, 'days')
  return { from: from.toDate(), to: to.toDate() }
}

export interface State {
  from: Date
  to: Date
}

export const initialState: State = {
  ...getDefaultFromTo(),
}

const criteriaReducer = createReducer(
  initialState,

  on(CriteriaActions.updateDateRange, (state, action) => ({
    ...state,
    from: action.from,
    to: action.to,
  }))
)

export function reducer(state: State | undefined, action: Action) {
  return criteriaReducer(state, action)
}
