import { Pipe, PipeTransform } from '@angular/core'
import { categoryIdToLabel, EventCategoryIds } from 'src/app/models/event.model'

@Pipe({
  name: 'eventCategory',
})
export class EventCategoryPipe implements PipeTransform {
  transform(value: EventCategoryIds, ..._: any[]): any {
    return categoryIdToLabel[value]
  }
}
