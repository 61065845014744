import { Component, OnInit, OnDestroy } from '@angular/core'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/reducers'
import { ChartInfo, ChartPart } from 'src/app/models/chart.model'
import {
  getChartSpotifyRank,
  getChartOriconPhysicalDailySingleInfo,
  getChartOriconPhysicalDailyAlbumInfo,
  getChartOriconPhysicalWeeklySingleInfo,
  getChartOriconPhysicalWeeklyAlbumInfo,
  getChartOriconPhysicalWeeklyDVDInfo,
  getChartOriconStreamingDailyInfo,
  getChartOriconStreamingWeeklyInfo,
  getChartTiktokWeeklyInfo,
  getChartLineMusicRank,
  getChartiTunes,
  getChartAppleMusic,
  getChartAmazon,
  getChartShazamInfo,
  getLoading,
  getAllEmpty,
  getOriconAllEmpty,
  getCSVData,
} from 'src/app/selectors/chart.selector'
import { getCriteriaFrom, getCriteriaTo } from 'src/app/selectors/criteria.selector'
import { Observable, Subscription } from 'rxjs'
// import { getCsvDataLoading, getCharts } from 'src/app/selectors/csv-download.selector'
import { Artist } from 'src/app/models/artist.model'
import { getSelectedArtist } from 'src/app/selectors/artist.selector'
import { MediaObserver, MediaChange } from '@angular/flex-layout'

@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss'],
})
export class ChartsComponent implements OnInit, OnDestroy {
  spotifyInfo$: Observable<ChartPart[]>
  oriconPhysicalDailySingleInfo$: Observable<ChartInfo[]>
  oriconPhysicalDailyAlbumInfo$: Observable<ChartInfo[]>
  oriconPhysicalWeeklySingleInfo$: Observable<ChartInfo[]>
  oriconPhysicalWeeklyAlbumInfo$: Observable<ChartInfo[]>
  oriconPhysicalWeeklyDVDInfo$: Observable<ChartInfo[]>
  oriconStreamingDailyInfo$: Observable<ChartInfo[]>
  oriconStreamingWeeklyInfo$: Observable<ChartInfo[]>
  tiktokWeeklyInfo$: Observable<ChartPart[]>
  lineMusicInfo$: Observable<ChartPart[]>
  iTunesInfo$: Observable<ChartPart[]>
  appleMusicInfo$: Observable<ChartPart[]>
  amazonInfo$: Observable<ChartPart[]>
  shazamInfo$: Observable<ChartPart[]>
  loading$: Observable<boolean>
  chartRankloading$: Observable<boolean>
  allEmpty$: Observable<boolean>
  oriconAllEmpty$: Observable<boolean>
  criteriaFrom$: Observable<Date>
  criteriaTo$: Observable<Date>

  csvId = 'chart'
  csvData$: Observable<any[]>
  csvFileName = 'チャート動向'
  csvLoading$: Observable<boolean>
  selectedArtist$: Observable<Artist>

  private subscriptions: Subscription[] = []

  constructor(public media: MediaObserver, private store: Store<AppState>) {
    this.subscriptions.push(media.media$.subscribe((_: MediaChange) => {}))
  }

  ngOnInit() {
    this.spotifyInfo$ = this.store.select(getChartSpotifyRank)
    this.oriconPhysicalDailySingleInfo$ = this.store.select(getChartOriconPhysicalDailySingleInfo)
    this.oriconPhysicalDailyAlbumInfo$ = this.store.select(getChartOriconPhysicalDailyAlbumInfo)
    this.oriconPhysicalWeeklySingleInfo$ = this.store.select(getChartOriconPhysicalWeeklySingleInfo)
    this.oriconPhysicalWeeklyAlbumInfo$ = this.store.select(getChartOriconPhysicalWeeklyAlbumInfo)
    this.oriconPhysicalWeeklyDVDInfo$ = this.store.select(getChartOriconPhysicalWeeklyDVDInfo)
    this.oriconStreamingDailyInfo$ = this.store.select(getChartOriconStreamingDailyInfo)
    this.oriconStreamingWeeklyInfo$ = this.store.select(getChartOriconStreamingWeeklyInfo)
    this.tiktokWeeklyInfo$ = this.store.select(getChartTiktokWeeklyInfo)
    this.lineMusicInfo$ = this.store.select(getChartLineMusicRank)
    this.iTunesInfo$ = this.store.select(getChartiTunes)
    this.appleMusicInfo$ = this.store.select(getChartAppleMusic)
    this.amazonInfo$ = this.store.select(getChartAmazon)
    this.shazamInfo$ = this.store.select(getChartShazamInfo)

    this.loading$ = this.store.select(getLoading)
    this.allEmpty$ = this.store.select(getAllEmpty)
    this.oriconAllEmpty$ = this.store.select(getOriconAllEmpty)
    this.criteriaFrom$ = this.store.select(getCriteriaFrom)
    this.criteriaTo$ = this.store.select(getCriteriaTo)

    this.csvLoading$ = this.store.select(getLoading)
    this.csvData$ = this.store.select(getCSVData)
    this.selectedArtist$ = this.store.select(getSelectedArtist)
  }

  ngOnDestroy() {
    this.subscriptions.forEach(it => it.unsubscribe())
  }
}
