import { Action, createReducer, on } from '@ngrx/store'
import { PhysicalsByCustomer } from '../models/physicals-by-customer.model'
import { refreshData } from '../actions/criteria.actions'
import {
  loadPhysicalsGraphsSuccess,
  loadPhysicalsGraphsFailure,
} from '../actions/physicals-graph.actions'

export interface State {
  physicals: PhysicalsByCustomer[]
  loading: boolean
  loaded: boolean
}

export const initialState: State = { physicals: [], loading: false, loaded: false }

const physicalsGraphReducer = createReducer(
  initialState,
  on(refreshData, (state, action) => ({ ...state, loading: true, loaded: false })),
  on(loadPhysicalsGraphsSuccess, (state, action) => ({
    physicals: action.physicals,
    loading: false,
    loaded: true,
  })),
  on(loadPhysicalsGraphsFailure, (state, action) => state)
)

export function reducer(state: State | undefined, action: Action) {
  return physicalsGraphReducer(state, action)
}
