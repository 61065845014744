import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core'

@Component({
  selector: 'app-aggregated-amount-item',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <mat-card
      matRipple
      [matRippleDisabled]="!hasLink()"
      (click)="onCardClick()"
      [class.has-link]="hasLink()"
    >
      <div fxLayout="row wrap" fxLayoutGap="8px" fxLayoutAlign="start center">
        <img class="icon" [src]="icon" />
        <div
          fxLayout="row wrap"
          fxLayoutAlign=" center"
          class="name"
          fxFlex="1 1 70%"
          fxFlex.md="60%"
        >
          <div>
            <div>{{ name }}<br />{{ account }}</div>
          </div>
        </div>
        <div fxFlex="1 1 auto"></div>
        <mat-icon class="help" *ngIf="help" [matTooltip]="help">help_outline</mat-icon>
      </div>
      <div class="content">
        <app-data-field legend="{{ unit }}">
          <div fxFlex="1 1 auto">
            <div fxLayout="row wrap" fxLayoutGap="8px" fxLayoutAlign="center baseline">
              <ng-container *ngIf="!loading; else inProgress">
                <ng-container *ngIf="hasAmount; else noAmount">
                  <div class="amount">{{ amount | number }}</div>
                  <div fxLayout fxLayoutAlign="start center">
                    <ng-container *ngIf="rate !== null; else noRate">
                      <ng-container *ngIf="isDecimal != null">
                        <div>{{ rate | number: '1.1-1' }}%</div>
                      </ng-container>
                      <ng-container *ngIf="isDecimal == null">
                        <div>{{ rate | number: '1.0-0' }}%</div>
                      </ng-container>
                      <mat-icon *ngIf="rate > 100">trending_up</mat-icon>
                      <mat-icon *ngIf="rate < 100">trending_down</mat-icon>
                    </ng-container>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
          <div fxLayoutAlign="right end">
            <ng-container *ngIf="!dataCollectLoading; else dateNoFix">
              <ng-container *ngIf="dataCollections; then dateFix; else dateNoFix"></ng-container>
            </ng-container>
          </div>
        </app-data-field>
      </div>
    </mat-card>

    <ng-template #dateFix>
      <img
        class="datafix_icon"
        matTooltip="各項目において選択した期間のデータが全部揃っている場合に、チェックマークが表示されます。"
        src="./assets/data_collections.png"
      />
    </ng-template>

    <ng-template #dateNoFix>
      <img class="datanofix_icon" src="./assets/data_collections.png" />
    </ng-template>

    <ng-template #inProgress>
      <app-music-note></app-music-note>
    </ng-template>

    <ng-template #noAmount>
      <div class="no-data">−</div>
    </ng-template>

    <ng-template #noRate>
      <div>- %</div>
    </ng-template>
  `,
  styleUrls: ['./aggregated-amount-item.component.scss'],
})
export class AggregatedAmountItemComponent implements OnInit {
  @Input()
  name: string

  @Input()
  account: string

  @Input()
  icon: string

  @Input()
  amount: number

  @Input()
  unit: string

  @Input()
  rate: number

  @Input()
  link: string

  @Input()
  help: string

  @Input()
  hasAmount = true

  @Input()
  loading = true

  @Input()
  dataCollections = false

  @Input()
  dataCollectLoading = true

  @Input()
  isDecimal: string

  constructor() {}

  ngOnInit() {}

  hasLink(): boolean {
    return this.link != null && this.link !== ''
  }

  onCardClick(): void {
    if (this.hasLink()) {
      window.open(this.link)
    }
  }
}
