import { Component, OnInit, OnDestroy } from '@angular/core'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/reducers'
import { ChartInfo, Chart } from 'src/app/models/chart.model'
import {
  getChartOriconPhysicalDailyAlbumInfo,
  getChartOriconPhysicalDailySingleInfo,
  getChartOriconPhysicalWeeklyAlbumInfo,
  getChartOriconPhysicalWeeklyDVDInfo,
  getChartOriconPhysicalWeeklySingleInfo,
  getChartOriconStreamingDailyInfo,
  getChartOriconStreamingWeeklyInfo,
  getLoading,
  getLoaded,
  getAllEmpty,
} from 'src/app/selectors/chart.selector'
import { Observable, Subscription } from 'rxjs'
import { MediaObserver, MediaChange } from '@angular/flex-layout'
import {
  getDataCollectByChartsOricon,
  getDataCollectLoading,
} from 'src/app/selectors/data-collections.selector'

@Component({
  selector: 'app-chart-item-oricon',
  templateUrl: './chart-item-oricon.component.html',
  styleUrls: ['./chart-item-oricon.component.scss'],
})
export class ChartItemOriconComponent implements OnInit, OnDestroy {
  physicalDailyAlbumInfo$: Observable<ChartInfo[]>
  physicalDailySingleInfo$: Observable<ChartInfo[]>
  physicalWeeklyAlbumInfo$: Observable<ChartInfo[]>
  physicalWeeklyDVDInfo$: Observable<ChartInfo[]>
  physicalWeeklySingleInfo$: Observable<ChartInfo[]>
  streamingDailyInfo$: Observable<ChartInfo[]>
  streamingWeeklyInfo$: Observable<ChartInfo[]>
  infos: { chart: ChartInfo[]; subName: string; icon: string }[] = []
  info: { chart: ChartInfo[]; subName: string; icon: string }
  loading$: Observable<boolean>
  loaded$: Observable<boolean>
  allEmpty$: Observable<boolean>
  private subscriptions: Subscription[] = []
  dataCollections$: Observable<boolean>
  dataCollectLoading$: Observable<boolean>

  constructor(public media: MediaObserver, private store: Store<AppState>) {
    this.subscriptions.push(media.media$.subscribe((_: MediaChange) => {}))
  }

  ngOnInit() {
    this.physicalDailyAlbumInfo$ = this.store.select(getChartOriconPhysicalDailyAlbumInfo)
    this.physicalDailySingleInfo$ = this.store.select(getChartOriconPhysicalDailySingleInfo)
    this.physicalWeeklyAlbumInfo$ = this.store.select(getChartOriconPhysicalWeeklyAlbumInfo)
    this.physicalWeeklyDVDInfo$ = this.store.select(getChartOriconPhysicalWeeklyDVDInfo)
    this.physicalWeeklySingleInfo$ = this.store.select(getChartOriconPhysicalWeeklySingleInfo)
    this.streamingDailyInfo$ = this.store.select(getChartOriconStreamingDailyInfo)
    this.streamingWeeklyInfo$ = this.store.select(getChartOriconStreamingWeeklyInfo)
    this.loading$ = this.store.select(getLoading)
    this.loaded$ = this.store.select(getLoaded)
    this.allEmpty$ = this.store.select(getAllEmpty)

    this.dataCollections$ = this.store.select(getDataCollectByChartsOricon)
    this.dataCollectLoading$ = this.store.select(getDataCollectLoading)

    this.physicalDailyAlbumInfo$.subscribe(chart => {
      if (chart.length > 0) {
        this.info = { chart, subName: 'CD アルバム（Daily）', icon: 'album' }
        this.infos.push(this.info)
      }
    })
    this.physicalWeeklyAlbumInfo$.subscribe(chart => {
      if (chart.length > 0) {
        this.info = { chart, subName: 'CD アルバム（Weekly）', icon: 'album' }
        this.infos.push(this.info)
      }
    })
    this.physicalDailySingleInfo$.subscribe(chart => {
      if (chart.length > 0) {
        this.info = { chart, subName: 'CD シングル（Daily）', icon: 'audiotrack' }
        this.infos.push(this.info)
      }
    })
    this.physicalWeeklySingleInfo$.subscribe(chart => {
      if (chart.length > 0) {
        this.info = { chart, subName: 'CD シングル（Weekly）', icon: 'audiotrack' }
        this.infos.push(this.info)
      }
    })
    this.physicalWeeklyDVDInfo$.subscribe(chart => {
      if (chart.length > 0) {
        this.info = { chart, subName: 'DVD（Weekly）', icon: 'music_video' }
        this.infos.push(this.info)
      }
    })
    this.streamingDailyInfo$.subscribe(chart => {
      if (chart.length > 0) {
        this.info = { chart, subName: 'Streaming（Daily）', icon: 'audiotrack' }
        this.infos.push(this.info)
      }
    })
    this.streamingWeeklyInfo$.subscribe(chart => {
      if (chart.length > 0) {
        this.info = { chart, subName: 'Streaming（Weekly）', icon: 'audiotrack' }
        this.infos.push(this.info)
      }
    })
  }
  ngOnDestroy() {
    this.subscriptions.forEach(it => it.unsubscribe())
  }
}
