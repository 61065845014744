import { Component, OnInit, Input, ChangeDetectionStrategy, OnChanges } from '@angular/core'
import { ProjectSale } from 'src/app/models/project-sale.model'
import { AngularFireStorage } from '@angular/fire/storage'
import { Observable, from } from 'rxjs'

@Component({
  selector: 'app-project-sale',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <mat-card>
      <div
        *ngIf="!loading && !projectSale.title"
        class="no-product"
        fxLayout
        fxLayoutAlign="center center"
      >
        リリース済みの作品はありません。
      </div>
      <div class="trend" fxLayout *ngIf="loading || projectSale.title">
        <div *ngIf="loading" class="jakesha-loading" fxLayout fxLayoutAlign="center center">
          <app-music-note></app-music-note>
        </div>
        <img *ngIf="!loading" fxFlex="0 0 96px" class="jakesha" [src]="jakeshaUrl$ | async" />
        <div fxFlex="1 1 auto" fxLayout="column" fxLayoutGap="16px">
          <div fxLayout fxLayoutAlign="space-between">
            <div>
              <span *ngIf="!loading">{{ projectSale.title }}</span>
              <span>&nbsp;</span>
            </div>
            <div>
              <span *ngIf="!loading"
                >リリース {{ projectSale.releaseDate | date: 'yyyy-MM-dd' }}</span
              >
            </div>
          </div>
          <div fxLayout fxLayoutAlign="space-evenly end" fxLayoutGap="16px">
            <app-data-field legend="売上（NET）" fxFlex="1 1 12%">
              <div
                class="trend-item"
                fxLayout="row wrap"
                fxLayoutAlign="start baseline"
                fxLayoutGap="8px"
              >
                <div class="trend-item-amount" *ngIf="!loading; else inProgress">
                  {{ projectSale.salesCurrencyAmount | number }}
                </div>
              </div>
            </app-data-field>
            <app-data-field legend="アルバム換算数" fxFlex="1 1 12%">
              <div
                class="trend-item"
                fxLayout="row wrap"
                fxLayoutAlign="start baseline"
                fxLayoutGap="8px"
              >
                <div class="trend-item-amount" *ngIf="!loading; else inProgress">
                  {{ projectSale.albumAdjustedUnits | number }}
                </div>
              </div>
            </app-data-field>
            <div class="physicals" fxFlex="1 1 40%">
              <div class="category" fxFlex="1 1 33%">
                <div class="legend">フィジカル売上枚数（NET）</div>
                <div class="value">
                  <div
                    class="trend-item"
                    fxLayout="row wrap"
                    fxLayoutAlign="start baseline"
                    fxLayoutGap="8px"
                  >
                    <div class="trend-item-amount" *ngIf="!loading; else inProgress">
                      <!--                      {{ projectSale.physicals | number }}-->

                      <div
                        fxLayout
                        fxLayoutAlign="start center"
                        matRipple
                        [matMenuTriggerFor]="searchMenu"
                      >
                        <span class="toolbar-button-label">{{
                          projectSale.physicals | number
                        }}</span>
                        <mat-icon class="product-code-icon">format_list_bulleted</mat-icon>
                      </div>

                      <mat-menu #searchMenu="matMenu" class="physical-details">
                        <div
                          class="product-code-details"
                          mat-menu-item
                          [disableRipple]="true"
                          [disabled]="true"
                        >
                          <div class="physicals-detail-title">フィジカル売上枚数品番別内訳</div>
                          <div
                            class="physicals-title-label"
                            fxLayout="row"
                            fxLayoutAlign="space-evenly end"
                            fxLayoutGap="5px"
                          >
                            <div class="item product-title-label">タイトル</div>
                            <div class="item detail-title-label">NET</div>
                            <div class="item detail-title-label">消化率</div>
                            <div class="item detail-title-label">発売前</div>
                            <div class="item detail-title-label">D2C</div>
                          </div>
                          <div *ngFor="let item of projectSale.physicalsDetail">
                            <mat-divider></mat-divider>
                            <div
                              fxLayout="row"
                              fxLayoutAlign="start center"
                              fxLayoutGap="5px"
                              class="product-code-details-item"
                            >
                              <div class="item" fxLayout="column">
                                <div class="product-code-label">
                                  {{ item.productCode || '不明な品番' }}
                                </div>
                                <div class="product-name-label">
                                  {{ item.productName || '不明な商品名' }}
                                </div>
                              </div>
                              <div class="item physicals-value-label">
                                {{ item.physicalsProd | number }}
                              </div>
                              <div
                                class="item physicals-value-label"
                                *ngIf="item.digestibility != null && item.digestibility != 0.0"
                              >
                                {{ item.digestibility | number }}%
                              </div>
                              <div
                                class="item physicals-value-label"
                                *ngIf="item.digestibility != null && item.digestibility == 0.0"
                              >
                                0%
                              </div>
                              <div
                                class="item physicals-value-label"
                                *ngIf="item.digestibility == null"
                              >
                                ‐
                              </div>
                              <div class="item physicals-value-label">
                                {{ item.preOrderPhysicalsProd | number }}
                              </div>
                              <div class="item physicals-value-label">
                                {{ item.d2cPhysicalsProd | number }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </mat-menu>
                    </div>
                  </div>
                </div>
              </div>
              <div class="category" fxFlex="1 1 33%">
                <div class="legend">発売前出荷数</div>
                <div class="value">
                  <div
                    class="trend-item"
                    fxLayout="row wrap"
                    fxLayoutAlign="start baseline"
                    fxLayoutGap="8px"
                  >
                    <div class="trend-item-amount" *ngIf="!loading; else inProgress">
                      {{ projectSale.preOrderPhysicals | number }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="category" fxFlex="1 1 33%">
                <div class="legend">D2C出荷枚数（NET）</div>
                <div class="value">
                  <div
                    class="trend-item"
                    fxLayout="row wrap"
                    fxLayoutAlign="start baseline"
                    fxLayoutGap="8px"
                  >
                    <div class="trend-item-amount" *ngIf="!loading; else inProgress">
                      {{ projectSale.d2cPhysicals | number }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <app-data-field legend="アルバムダウンロード数" fxFlex="1 1 12%">
              <div
                class="trend-item"
                fxLayout="row wrap"
                fxLayoutAlign="start baseline"
                fxLayoutGap="8px"
              >
                <div class="trend-item-amount" *ngIf="!loading; else inProgress">
                  {{ projectSale.albumDownloads | number }}
                </div>
              </div>
            </app-data-field>
            <app-data-field legend="トラックダウンロード数" fxFlex="1 1 12%">
              <div
                class="trend-item"
                fxLayout="row wrap"
                fxLayoutAlign="start baseline"
                fxLayoutGap="8px"
              >
                <div class="trend-item-amount" *ngIf="!loading; else inProgress">
                  {{ projectSale.trackDownloads | number }}
                </div>
              </div>
            </app-data-field>
            <app-data-field legend="ストリーム数" fxFlex="1 1 12%">
              <div
                class="trend-item"
                fxLayout="row wrap"
                fxLayoutAlign="start baseline"
                fxLayoutGap="8px"
              >
                <div class="trend-item-amount" *ngIf="!loading; else inProgress">
                  {{ projectSale.streams | number }}
                </div>
              </div>
            </app-data-field>
          </div>
        </div>
      </div>
    </mat-card>

    <ng-template #inProgress>
      <app-music-note></app-music-note>
    </ng-template>
  `,
  styleUrls: ['./project-sale.component.scss'],
})
export class ProjectSaleComponent implements OnInit, OnChanges {
  @Input()
  projectSale: ProjectSale

  @Input()
  loading = true

  jakeshaUrl$: Observable<any>
  constructor(private fireStorage: AngularFireStorage) {}

  ngOnInit() {}

  ngOnChanges() {
    if (!this.loading) {
      this.jakeshaUrl$ = from(this.getJackeshaUrl(this.projectSale))
    }
  }

  async getJackeshaUrl(sale: ProjectSale): Promise<any> {
    const cloudStorage = this.fireStorage.storage.app.storage()
    const storageRef = cloudStorage.ref()
    let starsRef = storageRef.child('/jacket_pictures/no_printing.png')

    if (sale && sale.upc) {
      starsRef = storageRef.child(`/jacket_pictures/${sale.projectId}.JPEG`)
    }

    return await starsRef
      .getDownloadURL()
      .then(url => {
        return url
      })
      .catch(error => {
        return './assets/no_printing.png'
      })
  }
}
