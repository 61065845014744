import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { PhysicalsGraphService } from '../services/physicals-graph.service'
import { refreshData } from '../actions/criteria.actions'
import { switchMap, catchError, map } from 'rxjs/operators'
import {
  loadPhysicalsGraphsSuccess,
  loadPhysicalsGraphsFailure,
} from '../actions/physicals-graph.actions'
import { of } from 'rxjs'

@Injectable()
export class PhysicalsGraphEffects {
  constructor(private actions$: Actions, private service: PhysicalsGraphService) {}

  loadPhysicalsGraph$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshData),
      switchMap(action =>
        this.service.findPhysicalsGraph(action.artistId, action.from, action.to).pipe(
          map(physicals => loadPhysicalsGraphsSuccess({ physicals })),
          catchError(error => of(loadPhysicalsGraphsFailure({ error })))
        )
      )
    )
  )
}
