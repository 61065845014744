import { Component, OnInit, OnDestroy, Host } from '@angular/core'
import { trigger, transition, query, animate, style } from '@angular/animations'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/reducers'
import { getArtistPage } from 'src/app/selectors/shell.selector'
import { Observable, Subscription } from 'rxjs'
import { ArtistPageTypes } from 'src/app/reducers/shell.reducer'
import { ActivatedRoute } from '@angular/router'
import { loadAndSelectArtist } from 'src/app/actions/artist.actions'
import { Artist } from 'src/app/models/artist.model'
import { getSelectedArtist, getLoaded } from 'src/app/selectors/artist.selector'
import { MediaObserver, MediaChange } from '@angular/flex-layout'
import { ShellComponent } from '../shell/shell.component'

@Component({
  selector: 'app-artist',
  templateUrl: './artist.component.html',
  styleUrls: ['./artist.component.scss'],
  animations: [
    trigger('artistLoaded', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(32px)' }),
        animate('500ms ease-out', style({ opacity: 1, transform: 'none' })),
      ]),
    ]),
  ],
})
export class ArtistComponent implements OnInit, OnDestroy {
  artistPage$: Observable<ArtistPageTypes>
  selectedArtist$: Observable<Artist>
  loaded$: Observable<boolean>

  private subscriptions: Subscription[] = []

  constructor(
    public media: MediaObserver,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    @Host() private shell: ShellComponent
  ) {
    this.subscriptions.push(
      // media.isActive を使うためには、
      // MediaObserver であらかじめ監視をしておく必要があります。
      media.media$.subscribe((_: MediaChange) => {})
    )
  }

  ngOnInit() {
    this.selectedArtist$ = this.store.select(getSelectedArtist)
    this.loaded$ = this.store.select(getLoaded)
    this.artistPage$ = this.store.select(getArtistPage)

    this.subscriptions.push(
      this.route.paramMap.subscribe(paramMap => {
        const artistId = paramMap.get('artistId')
        this.store.dispatch(loadAndSelectArtist({ artistId }))
      })
    )
  }

  ngOnDestroy() {
    this.subscriptions.forEach(it => it.unsubscribe())
  }

  onRouterActivate() {
    this.shell.container.scrollable.scrollTo({ top: 0 })
  }
}
