import { Injectable } from '@angular/core'

import { ArtistId } from 'src/app/models/artist.model'
import { AngularFireFunctions } from '@angular/fire/functions'
import { DataCollections } from 'src/app/models/data-collections.model'
import { Observable } from 'rxjs'
import * as moment from 'moment'

@Injectable({ providedIn: 'root' })
export class DataCollectionsService {
  constructor(private functions: AngularFireFunctions) {}

  find(artistId: ArtistId, from: Date, to: Date): Observable<DataCollections> {
    return this.functions.httpsCallable<
      { artistId: ArtistId; from: string; to: string },
      DataCollections
    >('findDataCollections')({
      artistId,
      from: moment(from).format('YYYY-MM-DD'),
      to: moment(to).format('YYYY-MM-DD'),
    })
  }
}
