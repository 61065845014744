import { Injectable } from '@angular/core'
import { ArtistId } from 'src/app/models/artist.model'
import { Observable } from 'rxjs'
import { AngularFireFunctions } from '@angular/fire/functions'
import { TrackSale } from 'src/app/models/track-sale.model'
import * as moment from 'moment'

@Injectable({
  providedIn: 'root',
})
export class TrackSaleService {
  constructor(private functions: AngularFireFunctions) {}

  findTrackSales(artistId: ArtistId, from: Date, to: Date): Observable<TrackSale[]> {
    return this.functions.httpsCallable<
      { artistId: string; from: string; to: string },
      TrackSale[]
    >('findTrackSales')({
      artistId,
      from: moment(from).format('YYYY-MM-DD'),
      to: moment(to).format('YYYY-MM-DD'),
    })
  }
}
