import { Component, OnInit, OnDestroy } from '@angular/core'
import { Observable, Subscription } from 'rxjs'
import { ChartPart } from 'src/app/models/chart.model'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/reducers'
import { getChartShazamInfo, getAllEmpty, getLoading } from 'src/app/selectors/chart.selector'
import { MediaObserver, MediaChange } from '@angular/flex-layout'
import {
  getDataCollectByChartsShazam,
  getDataCollectLoading,
} from 'src/app/selectors/data-collections.selector'

@Component({
  selector: 'app-chart-item-shazam',
  templateUrl: './chart-item-shazam.component.html',
  styleUrls: ['./chart-item-shazam.component.scss'],
})
export class ChartItemShazamComponent implements OnInit, OnDestroy {
  shazamInfo$: Observable<ChartPart[]>
  loading$: Observable<boolean>
  allEmpty$: Observable<boolean>
  private subscriptions: Subscription[] = []
  dataCollections$: Observable<boolean>
  dataCollectLoading$: Observable<boolean>

  constructor(public media: MediaObserver, private store: Store<AppState>) {
    this.subscriptions.push(media.media$.subscribe((_: MediaChange) => {}))
  }

  ngOnInit() {
    this.shazamInfo$ = this.store.select(getChartShazamInfo)
    this.loading$ = this.store.select(getLoading)
    this.allEmpty$ = this.store.select(getAllEmpty)

    this.dataCollections$ = this.store.select(getDataCollectByChartsShazam)
    this.dataCollectLoading$ = this.store.select(getDataCollectLoading)
  }
  ngOnDestroy() {
    this.subscriptions.forEach(it => it.unsubscribe())
  }
}
