// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  title: '[DEV] アーティスト ダッシュボード | UNIVERSAL MUSIC JAPAN',
  storage: {
    web: {
      root: 'https://storage.googleapis.com/musiquery-public',
    },
  },

  firebase: {
    apiKey: 'AIzaSyCX6k2C3jx9UReA4vvSSx9azI2U6R0IeHg',
    authDomain: 'umg-jp-dev.firebaseapp.com',
    databaseURL: 'https://umg-jp-dev.firebaseio.com',
    projectId: 'umg-jp-dev',
    storageBucket: 'umg-jp-dev.appspot.com',
    messagingSenderId: '556725661151',
    appId: '1:556725661151:web:33caae516191607234686a',
  },

  functions: {
    root: 'https://umg-jp-dev.web.app',
    // root: 'http://localhost:5000',
  },

  ga: {
    id: '', // プロダクション環境でのみ有効なのでからっぽです
  },

  // google map api key
  googleApiKey: 'AIzaSyCcJgnDinPItjtcUtcPReq1VoDxJahybhI',
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
