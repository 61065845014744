import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef } from '@angular/core'
import { GoogleChartComponent, ChartEvent } from 'angular-google-charts'
import { Observable, Subscription } from 'rxjs'
import { lineChartStyleDefault } from 'src/app/utils/line-chart-style'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/reducers'
import { getStreams, getLoading, getStreamsCsv } from 'src/app/selectors/streams-graph.selector'
import { map, take } from 'rxjs/operators'
import { isEventDrawerOpened } from 'src/app/selectors/shell.selector'
import { getSelectedEventDate } from 'src/app/selectors/event.selector'
import * as moment from 'moment'
import { openEventDrawer } from 'src/app/actions/shell.actions'
import { selectEventDate } from 'src/app/actions/event.actions'
import { range } from 'src/app/utils/range'
import {
  getDataCollectByStreamsGraph,
  getDataCollectLoading,
} from 'src/app/selectors/data-collections.selector'
import { StreamsBySubAccount } from 'src/app/models/streams-by-sub-account.model'
import { Artist } from 'src/app/models/artist.model'
import { getSelectedArtist } from 'src/app/selectors/artist.selector'
import { MediaObserver, MediaChange } from '@angular/flex-layout'
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout'
import { of } from 'rxjs'

@Component({
  selector: 'app-streams-graph',
  templateUrl: './streams-graph.component.html',
  styleUrls: ['./streams-graph.component.scss'],
})
export class StreamsGraphComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('chart', { static: false })
  chart: GoogleChartComponent

  @ViewChild('container', { static: false })
  container: ElementRef

  private widthOpened = false
  private widthWhenOpened = 0
  private widthWhenClosed = 0

  subAccounts$: Observable<string[]>
  streams$: Observable<StreamsBySubAccount[]>
  isLoading$: Observable<boolean>

  dataCollections$: Observable<boolean>
  dataCollectLoading$: Observable<boolean>

  colorsOption: any[]
  streamsOptions: any

  private subscriptions: Subscription[] = []

  csvId = 'stream'
  csvData$: Observable<any[]>
  csvFileName = 'ストリーム数'
  selectedArtist$: Observable<Artist>

  isHandset$ = this.breakpointObserver.observe(Breakpoints.Handset).pipe(map(it => it.matches))
  checkedSubAccounts: any[] = []
  allSubAccountsStreams$: Observable<string[]>
  checkLabels: { name: string; completed: boolean; disabled: boolean }[] = []

  constructor(
    private store: Store<AppState>,
    public media: MediaObserver,
    private breakpointObserver: BreakpointObserver
  ) {
    this.subscriptions.push(media.media$.subscribe((_: MediaChange) => {}))
  }

  ngOnInit() {
    this.getIniData()
    this.isLoading$ = this.store.select(getLoading)

    this.subscriptions.push(
      this.isLoading$.subscribe(it => {
        if (it) {
          this.getIniData()
        }
      })
    )

    this.dataCollections$ = this.store.select(getDataCollectByStreamsGraph)
    this.dataCollectLoading$ = this.store.select(getDataCollectLoading)
    this.csvData$ = this.store.select(getStreamsCsv)
    this.selectedArtist$ = this.store.select(getSelectedArtist)

    this.subscriptions.push(
      this.store.select(isEventDrawerOpened).subscribe(opened => {
        if (!this.chart) {
          return
        }
        this.widthOpened = opened
        this.chart.width = opened ? this.widthWhenOpened : this.widthWhenClosed
        this.chart.ngOnChanges()
      })
    )

    this.subscriptions.push(
      this.store.select(getSelectedEventDate).subscribe(date => {
        if (!this.chart) {
          return
        }
        if (!date) {
          this.chart.wrapper.getChart().setSelection(null)
        }
        const ymd = moment(date).format('YYYY-MM-DD')
        const columnNum = this.chart.columnNames.length - 1
        this.streams$.pipe(take(1)).subscribe(it => {
          const index = it.findIndex(data => data[0] === ymd)
          if (index !== -1) {
            const selections: { row: number; column: number }[] = range(0, columnNum).map(i => ({
              row: index,
              column: i + 1,
            }))
            this.chart.wrapper.getChart().setSelection(selections)
          }
        })
      })
    )

    if (this.media.isActive('lt-md')) {
      this.streamsOptions.legend.position = 'none'
      this.streamsOptions.chartArea.left = 45
    } else {
      this.streamsOptions.legend.position = 'top'
      this.streamsOptions.chartArea.left = 80
    }
  }

  getIniData() {
    this.allSubAccountsStreams$ = this.store
      .select(getStreams)
      .pipe(map(it => [...new Set(it.map(item => item.subAccountName))]))
    this.subAccounts$ = this.store
      .select(getStreams)
      .pipe(map(it => ['日付'].concat([...new Set(it.map(item => item.subAccountName))])))
    this.streams$ = this.store.select(getStreams).pipe(
      map(it => {
        return this.convert2ChartData(it)
      })
    )
    this.allSubAccountsStreams$.subscribe(it => {
      this.checkLabels = []
      it.forEach(item => {
        this.checkLabels.push({ name: item, completed: true, disabled: false })
      })
    })

    // streamsの取得先と色をマッピングして、グラフのオプションを設定する
    this.subAccounts$
      .pipe(
        map(it => {
          // streamsの取得先と色をマッピング
          return this.mappingSourceAndColor(it)
        })
      )
      .subscribe(val => {
        // 色のオプションを取得する
        this.colorsOption = []
        val.forEach(d => this.colorsOption.push(d))

        // グラフのオプションを設定する
        this.streamsOptions = {
          ...lineChartStyleDefault,
          colors: this.colorsOption,
        }
      })
  }

  ngAfterViewInit() {
    if (!this.container) {
      return
    }
    this.widthWhenClosed = this.container.nativeElement.offsetWidth - 20
    this.widthWhenOpened = this.widthWhenClosed - 300 /* 右ドロワーの幅に合わせて！ */
  }

  ngOnDestroy() {
    this.subscriptions.forEach(it => it.unsubscribe())
  }

  onResize() {
    if (!this.chart) {
      return
    }
    // リサイズ時、出来事の開閉状態によって算出が異なる
    if (this.widthOpened) {
      this.widthWhenOpened = this.container.nativeElement.offsetWidth - 20
      this.widthWhenClosed = this.widthWhenOpened + 300
      this.chart.width = this.widthWhenOpened
    } else {
      this.widthWhenClosed = this.container.nativeElement.offsetWidth - 20
      this.widthWhenOpened = this.widthWhenClosed - 300
      this.chart.width = this.widthWhenClosed
    }
    this.chart.ngOnChanges()
  }

  onSelect(event: ChartEvent[]): void {
    if (event.length === 0) {
      return
    }

    const isLegendsSelected = event[0].row === null
    this.streams$.pipe(take(1)).subscribe(it => {
      if (isLegendsSelected) {
        return
      }
      this.isHandset$.subscribe(h => {
        if (!h) {
          this.store.dispatch(openEventDrawer())
          this.store.dispatch(selectEventDate({ eventDate: it[event[0].row][0] }))
        }
      })
    })
  }

  // 選択したsubAccountsのstreamsを表示する
  onChange() {
    // 一つのcheckboxだけをチェックしたら、disableする
    this.disableCheckbox()

    // checked subAccountsを取得する
    this.checkedSubAccounts = []
    this.checkLabels.forEach(it => {
      if (it.completed) {
        this.checkedSubAccounts.push(it.name)
      }
    })

    // 選択したsubAccountsを再取得する
    this.subAccounts$ = of(['日付'].concat(this.checkedSubAccounts))

    // 選択したsubAccountsのstreamsを再取得する
    this.streams$ = this.store.select(getStreams).pipe(
      map(it => {
        return this.convertSelected2ChartData(it)
      })
    )

    // 選択したsubAccountsの色を再設定する
    this.subAccounts$
      .pipe(
        map(it => {
          // streamsの取得先と色をマッピング
          return this.mappingSourceAndColor(it)
        })
      )
      .subscribe(val => {
        // 色のオプションを取得する
        this.colorsOption = []
        val.forEach(d => this.colorsOption.push(d))

        // グラフのオプションを設定する
        this.streamsOptions = {
          ...lineChartStyleDefault,
          colors: this.colorsOption,
        }
      })
  }

  private disableCheckbox() {
    var count = 0
    for (var i = 0; i < this.checkLabels.length; i++) {
      if (this.checkLabels[i].completed) {
        count++

        if (this.checkLabels[i].disabled) {
          this.checkLabels[i].disabled = false
        }
      }
    }
    if (count === 1) {
      for (var i = 0; i < this.checkLabels.length; i++) {
        if (this.checkLabels[i].completed) {
          this.checkLabels[i].disabled = true
        }
      }
    }
  }

  private convert2ChartData(streams: StreamsBySubAccount[]): any[] {
    const dates = [...new Set(streams.map(item => item.transactionDate))]
    const column = []
    dates.forEach(d => {
      const data: any[] = [d]
      column.push(data)
      streams.map(stream => {
        if (d === stream.transactionDate) {
          data.push(stream.streams)
        }
      })
    })
    return column
  }

  private convertSelected2ChartData(streams: StreamsBySubAccount[]): any[] {
    const dates = [...new Set(streams.map(item => item.transactionDate))]
    const column = []
    dates.forEach(d => {
      const data: any[] = [d]
      column.push(data)
      streams.map(stream => {
        if (d === stream.transactionDate) {
          this.checkedSubAccounts.forEach(item => {
            if (item === stream.subAccountName) {
              data.push(stream.streams)
            }
          })
        }
      })
    })
    return column
  }

  /* streamsの取得先と色をマッピング処理
     取得先がAmazon, Amazon - Unlimited, Amazon - Prime, Spotify, Apple, Line Music, Vevoの場合、
     グラフ線の色を固定します。上記以外の6件の取得先の色はランダムします。それ以外の場合は同じ色にします。
  */
  private mappingSourceAndColor(subAccounts: string[]): string[] {
    const colors = []
    const colorsRandom = ['#2196f3', '#ffc107', '#03a9f4', '#009688', '#3f51b5', '#ffeb3b']
    const colors14Above = '#ff22ca'
    subAccounts.forEach(s => {
      switch (s) {
        case 'Amazon': {
          colors.push('#ff5722')
          break
        }
        case 'Amazon - Unlimited': {
          colors.push('#ff9800')
          break
        }
        case 'Amazon - Prime': {
          colors.push('#00bcd4')
          break
        }
        case 'Spotify': {
          colors.push('#673ab7')
          break
        }
        case 'Apple': {
          colors.push('#cb5393')
          break
        }
        case 'Line Music': {
          colors.push('#8bc34a')
          break
        }
        case 'Vevo': {
          colors.push('#e60000')
          break
        }
        case '日付': {
          break
        }
        default: {
          if (colorsRandom.length > 0) {
            // 上記以外の6件の取得先が色がランダムします。
            colors.push(colorsRandom.shift())
          } else {
            // 上記以外取得先の色が同じ色にします。
            colors.push(colors14Above)
          }
          break
        }
      }
    })
    return colors
  }
}
