import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { refreshData } from '../actions/criteria.actions'
import { switchMap, catchError, map } from 'rxjs/operators'
import {
  loadReactionsGraphsSuccess,
  loadReactionsGraphsFailure,
} from '../actions/reactions-graph.actions'
import { of } from 'rxjs'
import { ReactionsGraphService } from '../services/reactions-graph.service'

@Injectable()
export class ReactionsGraphEffects {
  constructor(private actions$: Actions, private service: ReactionsGraphService) {}

  loadStreamsGraph$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshData),
      switchMap(action =>
        this.service.findReactionsGraph(action.artistId, action.from, action.to).pipe(
          map(reactions => loadReactionsGraphsSuccess({ reactions })),
          catchError(error => of(loadReactionsGraphsFailure({ error })))
        )
      )
    )
  )
}
