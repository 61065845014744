import { Action, createReducer, on } from '@ngrx/store'
import { DataCollections } from '../models/data-collections.model'
import * as DataCollectionsActions from '../actions/data-collections.actions'
import * as CriteriaActions from '../actions/criteria.actions'

export interface State {
  dataCollections: DataCollections
  loading: boolean
  loaded: boolean
}

export const initialState: State = {
  dataCollections: {
    twitterFlollowers: false,
    facebookFollowers: false,
    instagramFollowers: false,
    instagramHashtag: false,
    physicalShipments: false,
    posPhysicalEstimates: false,
    streams: false,
    downloads: false,
    umphPageviews: false,
    googleSearch: false,
    specialUmhpPageviews: false,
    udiscoverPageviews: false,
    wikipediaPageviews: false,
    twitterKeywords: false,
    unitsGraph: false,
    streamsGraph: false,
    physicalsGraph: false,
    downloadsGraph: false,
    chartsOricon: false,
    chartsSpotify: false,
    chartsTiktok: false,
    chartsItunes: false,
    chartsAmazon: false,
    chartsLine: false,
    chartsAppleMusic: false,
    chartsShazam: false,
    reactionsGraph: false,
    prAnalyzerGraph: false,
    homepagesPageView: false,
    homepagesUserView: false,
    newsletterSubscriber: false,
    youtubeSubscriber: false,
    umstorePageviews: false,
    twitterTagCloud: false,
    audienceAppleMusic: false,
    audienceSpotify: false,
    audienceYoutube: false,
    audienceUmhp: false,
    audienceNewsletter: false,
    audienceEnquete: false,
    audienceLine: false,
    spotifyFollowers: false,
    lineFollowers: false,
    tiktokFollowers: false,
    tiktokGraph: false,
    shazam: false,
    specialUmhpUserviews: false,
    umphUserviews: false,
  },
  loading: false,
  loaded: false,
}

const dataCollectionsReducer = createReducer(
  initialState,

  on(CriteriaActions.refreshData, (state, action) => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(DataCollectionsActions.loadDataCollectionsSuccess, (state, action) => ({
    dataCollections: action.dataCollections,
    loaded: true,
    loading: false,
  })),
  on(DataCollectionsActions.loadDataCollectionsFailure, (state, action) => state)
)

export function reducer(state: State | undefined, action: Action) {
  return dataCollectionsReducer(state, action)
}
