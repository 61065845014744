import { Component, OnInit, OnDestroy } from '@angular/core'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/reducers'
import { ChartPart } from 'src/app/models/chart.model'
import { getChartiTunes, getLoading, getLoaded } from 'src/app/selectors/chart.selector'
import { Observable, Subscription } from 'rxjs'
import { MediaObserver, MediaChange } from '@angular/flex-layout'
import {
  getDataCollectByChartsItunes,
  getDataCollectLoading,
} from 'src/app/selectors/data-collections.selector'

@Component({
  selector: 'app-chart-item-itunes',
  templateUrl: './chart-item-itunes.component.html',
  styleUrls: ['./chart-item-itunes.component.scss'],
})
export class ChartItemITunesComponent implements OnInit, OnDestroy {
  iTunesInfo$: Observable<ChartPart[]>
  loading$: Observable<boolean>
  loaded$: Observable<boolean>
  allEmpty$: Observable<boolean>
  period$: Observable<any>
  private subscriptions: Subscription[] = []
  dataCollections$: Observable<boolean>
  dataCollectLoading$: Observable<boolean>

  constructor(public media: MediaObserver, private store: Store<AppState>) {
    this.subscriptions.push(media.media$.subscribe((_: MediaChange) => {}))
  }

  ngOnInit() {
    this.iTunesInfo$ = this.store.select(getChartiTunes)
    this.loading$ = this.store.select(getLoading)
    this.loaded$ = this.store.select(getLoaded)

    this.dataCollections$ = this.store.select(getDataCollectByChartsItunes)
    this.dataCollectLoading$ = this.store.select(getDataCollectLoading)
  }

  ngOnDestroy() {
    this.subscriptions.forEach(it => it.unsubscribe())
  }
}
