import { createAction, props } from '@ngrx/store'
import { PrAnalyzerGraph } from '../models/pr-analyzer-graph.model'

export const loadPrAnalyzerGraphsSuccess = createAction(
  '[PrAnalyzerGraph] Load PrAnalyzerGraphs Success',
  props<{ prAnalyzer: PrAnalyzerGraph[] }>()
)
export const loadPrAnalyzerGraphsFailure = createAction(
  '[PrAnalyzerGraph] Load PrAnalyzerGraphs Failure',
  props<{ error: any }>()
)
