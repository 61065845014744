import { createAction, props } from '@ngrx/store'
import { TwitterTagCloud } from '../models/twitter-tag-cloud.model'

export const loadTwitterTagCloudSuccess = createAction(
  '[TwitterTagCloud Service] load Twitter Tag Cloud Success',
  props<{ tagcloud: TwitterTagCloud[] }>()
)

export const loadTwitterTagCloudFailure = createAction(
  '[TwitterTagCloud Service] load Twitter Tag Cloud Failure',
  props<{ error: any }>()
)
