import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'
import { Observable, EMPTY } from 'rxjs'

declare let gtag: (type: string, id: string, option: any) => void

@Injectable({ providedIn: 'root' })
export class GoogleAnalyticsService {
  constructor() {}

  onPageVisit(url: string): Observable<never> {
    if (environment.production && !!gtag && !!environment.ga.id) {
      gtag('config', environment.ga.id, { page_path: url })
    }
    return EMPTY
  }
}
