import { Component, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/reducers'
import { Observable, Subscription } from 'rxjs'
import { Artist } from 'src/app/models/artist.model'
import { getSelectedArtist } from 'src/app/selectors/artist.selector'
import {
  getAudienceAppleMusic,
  getAudienceAppleMusicAge,
  getAudienceYoutube,
  getAudienceYoutubeAge,
  getAudienceSpotify,
  getAudienceSpotifyAge,
  getAudienceSpotifyRegion,
  getAudienceUmhp,
  getAudienceUmhpAge,
  getAudienceUmhpRegion,
  getAudienceEnquete,
  getAudienceEnqueteAge,
  getAudienceEnqueteRegion,
  getAudienceNewsletter,
  getAudienceNewsletterAge,
  getAudienceNewsletterRegion,
  getAudienceLine,
  getAudienceLineAge,
  getAudienceLineRegion,
  getLoading,
  getLoaded,
  getCsvData,
  getAllEmpty,
} from '../../../selectors/audience.selector'
import { MediaObserver, MediaChange } from '@angular/flex-layout'
import { getCriteriaFrom, getCriteriaTo } from 'src/app/selectors/criteria.selector'
import {
  getDataCollectByAudienceAppleMusic,
  getDataCollectByAudienceSpotify,
  getDataCollectByAudienceYoutube,
  getDataCollectByAudienceUmhp,
  getDataCollectByAudienceNewsletter,
  getDataCollectByAudienceEnquete,
  getDataCollectByAudienceLine,
  getDataCollectLoading,
} from 'src/app/selectors/data-collections.selector'

@Component({
  selector: 'app-audience',
  templateUrl: './audience.component.html',
  styleUrls: ['./audience.component.scss'],
})
export class AudienceComponent implements OnInit {
  appleMusicInfo$: Observable<any[]>
  appleMusicAgeInfo$: Observable<any[]>
  youtubeInfo$: Observable<any[]>
  youtubeAgeInfo$: Observable<any[]>
  spotifyInfo$: Observable<any[]>
  spotifyAgeInfo$: Observable<any[]>
  spotifyRegionInfo$: Observable<any[]>
  umhpInfo$: Observable<any[]>
  umhpAgeInfo$: Observable<any[]>
  umhpRegionInfo$: Observable<any[]>
  enqueteInfo$: Observable<any[]>
  enqueteAgeInfo$: Observable<any[]>
  enqueteRegionInfo$: Observable<any[]>
  newsletterInfo$: Observable<any[]>
  newsletterAgeInfo$: Observable<any[]>
  newsletterRegionInfo$: Observable<any[]>
  lineInfo$: Observable<any[]>
  lineAgeInfo$: Observable<any[]>
  lineRegionInfo$: Observable<any[]>
  allEmpty$: Observable<boolean>
  loading$: Observable<boolean>
  loaded$: Observable<boolean>
  today = new Date()
  yesterday = new Date()
  csvId = 'audience'
  csvData$: Observable<any[]>
  csvFileName = 'デモグラフィック'
  csvLoading$: Observable<boolean>
  selectedArtist$: Observable<Artist>
  criteriaFrom$: Observable<Date>
  criteriaTo$: Observable<Date>
  dataCollectAppMusic$: Observable<boolean>
  dataCollectSpotify$: Observable<boolean>
  dataCollectLine$: Observable<boolean>
  dataCollectYoutube$: Observable<boolean>
  dataCollectUmhp$: Observable<boolean>
  dataCollectNewsletter$: Observable<boolean>
  dataCollectEnquete$: Observable<boolean>
  dataCollectLoading$: Observable<boolean>
  ageLabels: string[]
  umhpAgeLabels: string[]

  options = {
    backgroundColor: 'transparent',
    slices: {
      0: { color: '#42A5F5' },
      1: { color: '#EC407A' },
      2: { color: '#9E9E9E' },
    },
    sliceVisibilityThreshold: 0.001,
  }

  optionsAge = {
    backgroundColor: 'transparent',
    legend: { position: 'top' },
    isStacked: true,
    colors: ['#42A5F5', '#EC407A', '#9E9E9E'],
    width: 600,
  }

  optionsRegion = {
    region: 'JP',
    resolution: 'provinces',
    colorAxis: { colors: ['#E3F2FD', '#0D47A1'] },
  }

  private subscriptions: Subscription[] = []

  constructor(public media: MediaObserver, private store: Store<AppState>) {
    this.subscriptions.push(media.media$.subscribe((_: MediaChange) => {}))
  }

  ngOnInit() {
    this.appleMusicInfo$ = this.store.select(getAudienceAppleMusic)
    this.appleMusicAgeInfo$ = this.store.select(getAudienceAppleMusicAge)
    this.youtubeInfo$ = this.store.select(getAudienceYoutube)
    this.youtubeAgeInfo$ = this.store.select(getAudienceYoutubeAge)
    this.spotifyInfo$ = this.store.select(getAudienceSpotify)
    this.spotifyAgeInfo$ = this.store.select(getAudienceSpotifyAge)
    this.spotifyRegionInfo$ = this.store.select(getAudienceSpotifyRegion)
    this.umhpInfo$ = this.store.select(getAudienceUmhp)
    this.umhpAgeInfo$ = this.store.select(getAudienceUmhpAge)
    this.umhpRegionInfo$ = this.store.select(getAudienceUmhpRegion)
    this.enqueteInfo$ = this.store.select(getAudienceEnquete)
    this.enqueteAgeInfo$ = this.store.select(getAudienceEnqueteAge)
    this.enqueteRegionInfo$ = this.store.select(getAudienceEnqueteRegion)
    this.newsletterInfo$ = this.store.select(getAudienceNewsletter)
    this.newsletterAgeInfo$ = this.store.select(getAudienceNewsletterAge)
    this.newsletterRegionInfo$ = this.store.select(getAudienceNewsletterRegion)
    this.lineInfo$ = this.store.select(getAudienceLine)
    this.lineAgeInfo$ = this.store.select(getAudienceLineAge)
    this.lineRegionInfo$ = this.store.select(getAudienceLineRegion)
    this.allEmpty$ = this.store.select(getAllEmpty)
    this.loading$ = this.store.select(getLoading)
    this.loaded$ = this.store.select(getLoaded)
    this.csvData$ = this.store.select(getCsvData)
    this.selectedArtist$ = this.store.select(getSelectedArtist)
    this.yesterday.setDate(this.yesterday.getDate() - 1)
    this.criteriaFrom$ = this.store.select(getCriteriaFrom)
    this.criteriaTo$ = this.store.select(getCriteriaTo)
    this.dataCollectAppMusic$ = this.store.select(getDataCollectByAudienceAppleMusic)
    this.dataCollectSpotify$ = this.store.select(getDataCollectByAudienceSpotify)
    this.dataCollectYoutube$ = this.store.select(getDataCollectByAudienceYoutube)
    this.dataCollectUmhp$ = this.store.select(getDataCollectByAudienceUmhp)
    this.dataCollectNewsletter$ = this.store.select(getDataCollectByAudienceNewsletter)
    this.dataCollectEnquete$ = this.store.select(getDataCollectByAudienceEnquete)
    this.dataCollectLine$ = this.store.select(getDataCollectByAudienceLine)
    this.dataCollectLoading$ = this.store.select(getDataCollectLoading)

    this.ageLabels = ['ageGroup', '男性', '女性', '不明']
    this.umhpAgeLabels = ['ageGroup', '男性', '女性']
  }
}
