import { BrowserModule, Title } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { AngularFireModule } from '@angular/fire'
import { AngularFireFunctionsModule, FUNCTIONS_ORIGIN } from '@angular/fire/functions'
import { AngularFireStorageModule } from '@angular/fire/storage'
import {
  MatSidenavModule,
  MatToolbarModule,
  MatButtonModule,
  MatFormFieldModule,
  MatIconModule,
  MatListModule,
  MatInputModule,
  MatMenuModule,
  MatSlideToggleModule,
  MatCheckboxModule,
  MatCardModule,
  MatTooltipModule,
  MatRippleModule,
  MatDatepickerModule,
  MAT_DATE_FORMATS,
  MatAutocompleteModule,
  MatDividerModule,
  MatTableModule,
  MatTabsModule,
  MatGridListModule,
} from '@angular/material'
import { MatMomentDateModule } from '@angular/material-moment-adapter'
import { GoogleChartsModule } from 'angular-google-charts'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { StoreModule } from '@ngrx/store'
import { reducers, metaReducers } from './reducers'
import { EffectsModule } from '@ngrx/effects'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { environment } from '../environments/environment'
import { ShellComponent } from './views/containers/shell/shell.component'
import { HomeComponent } from './views/containers/home/home.component'
import { ArtistComponent } from './views/containers/artist/artist.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SidenavComponent } from './views/presentations/sidenav/sidenav.component'
import { ArtistFooterComponent } from './views/presentations/artist-footer/artist-footer.component'
import { StoreRouterConnectingModule, RouterState } from '@ngrx/router-store'
import { DataFieldComponent } from './views/presentations/data-field/data-field.component'
import { ProgressIndicatorComponent } from './views/presentations/progress-indicator/progress-indicator.component'
import { effects } from 'src/app/effects'
import { ArtistCombinedComponent } from './views/containers/artist-combined/artist-combined.component'
import { RecentProjectTrendsComponent } from './views/containers/recent-project-trends/recent-project-trends.component'
import { AggregatedAmountsComponent } from './views/containers/aggregated-amounts/aggregated-amounts.component'
import { AggregatedAmountItemComponent } from './views/presentations/aggregated-amount-item/aggregated-amount-item.component'
import { UnitsGraphComponent } from './views/containers/units-graph/units-graph.component'
import { EventsComponent } from './views/containers/events/events.component'
// tslint:disable-next-line:max-line-length
import { AggregatedAmountsInstagramHashtagCountComponent } from './views/containers/aggregated-amounts-instagram-hashtag-count/aggregated-amounts-instagram-hashtag-count.component'
import { MusicNoteComponent } from './views/presentations/music-note/music-note.component'
import { SearchArtistResultItemComponent } from './views/presentations/search-artist-result-item/search-artist-result-item.component'
import { AggregatedAmountsTwitterFollowerComponent } from './views/containers/aggregated-amounts-twitter-follower/aggregated-amounts-twitter-follower.component'
import { AggregatedAmountsFacebookFollowerComponent } from './views/containers/aggregated-amounts-facebook-follower/aggregated-amounts-facebook-follower.component'

// tslint:disable-next-line:max-line-length
import { AggregatedAmountsInstagramFollowersCountComponent } from './views/containers/aggregated-amounts-instagram-followers-count/aggregated-amounts-instagram-followers-count.component'
import { ProjectSaleComponent } from './views/presentations/project-sale/project-sale.component'
import { RecentProjectTrendComponent } from './views/containers/recent-project-trend/recent-project-trend.component'
import { AggregatedAmountsStreamsComponent } from './views/containers/aggregated-amounts-streams/aggregated-amounts-streams.component'
import { AggregatedAmountsUdiscoverPageviewComponent } from './views/containers/aggregated-amounts-udiscover-pageview/aggregated-amounts-udiscover-pageview.component'
import { DownloadUnitsGraphComponent } from './views/containers/download-units-graph/download-units-graph.component'
import { AggregatedAmountsDownloadUnitComponent } from './views/containers/aggregated-amounts-download-unit/aggregated-amounts-download-unit.component'
import { AggregatedAmountsUmhpPageviewComponent } from './views/containers/aggregated-amounts-umhp-pageview/aggregated-amounts-umhp-pageview/aggregated-amounts-umhp-pageview.component'
import { StreamsGraphComponent } from './views/containers/streams-graph/streams-graph.component'
import { PhysicalsGraphComponent } from './views/containers/physicals-graph/physicals-graph.component'
import { AggregatedAmountsTwitterKeywordsCountComponent } from './views/containers/aggregated-amounts-twitter-keywords-count/aggregated-amounts-twitter-keywords-count.component'
import { AggregatedAmountsPhysicalShipmentCountComponent } from './views/containers/aggregated-amounts-physical-shipment-count/aggregated-amounts-physical-shipment-count.component'
import { AggregatedAmountsSpecialUmhpPageviewComponent } from './views/containers/aggregated-amounts-special-umhp-pageview/aggregated-amounts-special-umhp-pageview.component'

import { AggregatedAmountsPosPhysicalEstimateComponent } from './views/containers/aggregated-amounts-pos-physical-estimate/aggregated-amounts-pos-physical-estimate.component'
import { AggregatedAmountsGoogleSearchCountComponent } from './views/containers/aggregated-amounts-google-search-count/aggregated-amounts-google-search-count.component'
import { EventCategoryPipe } from './pipes/event-category.pipe'

import { AggregatedAmountsWikipediaPageviewComponent } from './views/containers/aggregated-amounts-wikipedia-pageview/aggregated-amounts-wikipedia-pageview.component'
import { CopyrightComponent } from 'src/app/views/presentations/copyright/copyright.component'
import { ChartsComponent } from './views/containers/charts/charts.component'

import { ReactionsGraphComponent } from './views/containers/reactions-graph/reactions-graph.component'
import { HomepagesGraphComponent } from './views/containers/homepages-graph/homepages-graph.component'
import { AggregatedAmountsYoutubeChannelSubscribersCountComponent } from './views/containers/aggregated-amounts-youtube-channel-subscribers-count/aggregated-amounts-youtube-channel-subscribers-count.component'
import { AggregatedAmountsNewsletterSubscriberCountComponent } from './views/containers/aggregated-amounts-newsletter-subscriber-count/aggregated-amounts-newsletter-subscriber-count.component'
import { CsvDownloadComponent } from './views/presentations/csv-download/csv-download.component'
import { AggregatedAmountsUmstorePageviewComponent } from './views/containers/aggregated-amounts-umstore-pageview/aggregated-amounts-umstore-pageview.component'

import { TagCloudModule } from 'angular-tag-cloud-module'
import { TwitterTagCloudComponent } from './views/containers/twitter-tag-cloud/twitter-tag-cloud.component'
import { MatBottomSheetModule } from '@angular/material/bottom-sheet'
import { AudienceComponent } from './views/containers/audience/audience.component'
import { AudiencePieChartComponent } from './views/presentations/audience-pie-chart/audience-pie-chart.component'
import { SpTopComponent } from './views/containers/sp-top/sp-top.component'
import { SpSalesComponent } from './views/containers/sp-sales/sp-sales.component'
import { SpProjectSaleComponent } from './views/presentations/sp-project-sale/sp-project-sale.component'
import { SpDataFieldComponent } from './views/presentations/sp-data-field/sp-data-field.component'
import { SpAggregateAmountsComponent } from './views/containers/sp-aggregate-amounts/sp-aggregate-amounts.component'
import { SpChartsComponent } from './views/containers/sp-charts/sp-charts.component'
import { SpGraphComponent } from './views/containers/sp-graph/sp-graph.component'
import { SpDemographicsComponent } from './views/containers/sp-demographics/sp-demographics.component'
import { SpProjectSaleDetailComponent } from './views/presentations/sp-project-sale-detail/sp-project-sale-detail.component'
import { AudienceColumnChartComponent } from './views/presentations/audience-column-chart/audience-column-chart.component'
import { AudienceGeoChartComponent } from './views/presentations/audience-geo-chart/audience-geo-chart.component'
import { RecentTrackTrendsComponent } from './views/containers/recent-track-trends/recent-track-trends.component'
import { TrackSaleComponent } from './views/presentations/track-sale/track-sale.component'
import { SpTrackSaleComponent } from './views/presentations/sp-track-sale/sp-track-sale.component'
import { SpTrackSalesComponent } from './views/containers/sp-track-sales/sp-track-sales.component'
import { SpProjectSalesComponent } from './views/containers/sp-project-sales/sp-project-sales.component'
import { YoutubeGraphComponent } from './views/containers/youtube-graph/youtube-graph.component'
import { AggregatedAmountsSpotifyFollowerComponent } from './views/containers/aggregated-amounts-spotify-follower/aggregated-amounts-spotify-follower.component'
import { ChartItemOriconComponent } from './views/containers/chart-item-oricon/chart-item-oricon.component'
import { ChartItemsComponent } from './views/presentations/chart-items/chart-items.component'
import { ChartItemSpotifyComponent } from './views/containers/chart-item-spotify/chart-item-spotify.component'
import { ChartItemTiktokComponent } from './views/containers/chart-item-tiktok/chart-item-tiktok.component'
import { ChartItemLineComponent } from './views/containers/chart-item-line/chart-item-line.component'
import { ChartRankItemsComponent } from './views/presentations/chart-rank-items/chart-rank-items.component'
import { ChartItemITunesComponent } from './views/containers/chart-item-itunes/chart-item-itunes.component'
import { ChartItemAppleMusicComponent } from './views/containers/chart-item-apple-music/chart-item-apple-music.component'
import { ChartItemAmazonComponent } from './views/containers/chart-item-amazon/chart-item-amazon.component'
import { SpChartItemsComponent } from './views/presentations/sp-chart-items/sp-chart-items.component'
import { SpChartRankItemsComponent } from './views/presentations/sp-chart-rank-items/sp-chart-rank-items.component'
import { ChartItemShazamComponent } from './views/containers/chart-item-shazam/chart-item-shazam.component'
import { PrAnalyzerGraphComponent } from './views/containers/pr-analyzer-graph/pr-analyzer-graph.component'
import { AggregatedAmountsLineFollowerComponent } from './views/containers/aggregated-amounts-line-follower/aggregated-amounts-line-follower.component'
import { AggregatedAmountsTiktokFollowerComponent } from './views/containers/aggregated-amounts-tiktok-follower/aggregated-amounts-tiktok-follower.component'
import { TiktokGraphComponent } from './views/containers/tiktok-graph/tiktok-graph.component'
import { AggregatedAmountsSpecialUmhpUserviewComponent } from './views/containers/aggregated-amounts-special-umhp-userview/aggregated-amounts-special-umhp-userview.component'
import { AggregatedAmountsUmhpUserviewComponent } from './views/containers/aggregated-amounts-umhp-userview/aggregated-amounts-umhp-userview.component'
import { HomepagesUserGraphComponent } from './views/containers/homepages-user-graph/homepages-user-graph.component'
import { AggregatedAmountsShazamsComponent } from './views/containers/aggregated-amounts-shazams/aggregated-amounts-shazams.component'

@NgModule({
  declarations: [
    AppComponent,
    ShellComponent,
    HomeComponent,
    ArtistComponent,
    SidenavComponent,
    ArtistFooterComponent,
    DataFieldComponent,
    ProgressIndicatorComponent,
    ArtistCombinedComponent,
    RecentProjectTrendsComponent,
    AggregatedAmountsComponent,
    AggregatedAmountItemComponent,
    UnitsGraphComponent,
    EventsComponent,
    AggregatedAmountsTwitterFollowerComponent,
    AggregatedAmountsInstagramHashtagCountComponent,
    AggregatedAmountsFacebookFollowerComponent,
    MusicNoteComponent,
    SearchArtistResultItemComponent,
    AggregatedAmountsInstagramFollowersCountComponent,
    ProjectSaleComponent,
    RecentProjectTrendComponent,
    AggregatedAmountsStreamsComponent,
    AggregatedAmountsUdiscoverPageviewComponent,
    DownloadUnitsGraphComponent,
    AggregatedAmountsDownloadUnitComponent,
    AggregatedAmountsPosPhysicalEstimateComponent,
    AggregatedAmountsUmhpPageviewComponent,
    StreamsGraphComponent,
    PhysicalsGraphComponent,
    AggregatedAmountsTwitterKeywordsCountComponent,
    EventCategoryPipe,
    AggregatedAmountsPhysicalShipmentCountComponent,
    AggregatedAmountsSpecialUmhpPageviewComponent,
    AggregatedAmountsGoogleSearchCountComponent,
    AggregatedAmountsWikipediaPageviewComponent,
    CopyrightComponent,
    ChartsComponent,
    ReactionsGraphComponent,
    HomepagesGraphComponent,
    AggregatedAmountsYoutubeChannelSubscribersCountComponent,
    AggregatedAmountsNewsletterSubscriberCountComponent,
    CsvDownloadComponent,
    AggregatedAmountsUmstorePageviewComponent,
    TwitterTagCloudComponent,
    AudienceComponent,
    AudiencePieChartComponent,
    SpSalesComponent,
    SpTopComponent,
    SpAggregateAmountsComponent,
    SpChartsComponent,
    SpGraphComponent,
    AudienceComponent,
    AudiencePieChartComponent,
    SpDemographicsComponent,
    SpProjectSaleComponent,
    SpProjectSaleDetailComponent,
    SpDataFieldComponent,
    AudienceColumnChartComponent,
    AudienceGeoChartComponent,
    RecentTrackTrendsComponent,
    TrackSaleComponent,
    SpTrackSaleComponent,
    SpTrackSalesComponent,
    SpProjectSalesComponent,
    YoutubeGraphComponent,
    AggregatedAmountsSpotifyFollowerComponent,
    ChartItemOriconComponent,
    ChartItemsComponent,
    ChartItemSpotifyComponent,
    ChartItemTiktokComponent,
    ChartItemLineComponent,
    ChartRankItemsComponent,
    ChartItemITunesComponent,
    ChartItemAppleMusicComponent,
    ChartItemAmazonComponent,
    SpChartItemsComponent,
    SpChartRankItemsComponent,
    ChartItemShazamComponent,
    AggregatedAmountsLineFollowerComponent,
    PrAnalyzerGraphComponent,
    AggregatedAmountsTiktokFollowerComponent,
    TiktokGraphComponent,
    AggregatedAmountsSpecialUmhpUserviewComponent,
    AggregatedAmountsUmhpUserviewComponent,
    HomepagesUserGraphComponent,
    AggregatedAmountsShazamsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatCardModule,
    MatTooltipModule,
    MatRippleModule,
    MatTableModule,
    MatTabsModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatAutocompleteModule,
    MatDividerModule,
    MatBottomSheetModule,
    MatGridListModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot(effects),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    StoreRouterConnectingModule.forRoot({
      routerState: RouterState.Minimal,
    }),
    GoogleChartsModule.forRoot(environment.googleApiKey),
    TagCloudModule,
  ],
  providers: [
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'YYYY-MM-DD',
        },
        display: {
          dateInput: 'YYYY-MM-DD',
          monthYearLabel: 'YYYY-MM',
          dateA11yLabel: 'YYYY-MM-DD',
          monthYearA11yLabel: 'YYYY-MM',
        },
      },
    },
    Title,
    // 全てのリクエストは Imperva を経由する必要がありますが、
    // その場合は Functions のエンドポイントをカスタムドメインにする必要があります。
    // Functions の HttpCallble のエンドポイントをカスタマイズするには、
    // Function エミュレーターであると詐称するしか方法がないため、
    // ここではエミュレーターとしてエンドポイントを指定しています。
    { provide: FUNCTIONS_ORIGIN, useValue: environment.functions.root },
  ],
  bootstrap: [AppComponent],
  entryComponents: [EventsComponent, SpProjectSaleDetailComponent],
})
export class AppModule {}
