import { Injectable } from '@angular/core'
import { AngularFireFunctions } from '@angular/fire/functions'
import { ArtistId } from '../models/artist.model'
import { Observable } from 'rxjs'
import { Units } from '../models/units.model'
import * as moment from 'moment'

@Injectable({
  providedIn: 'root',
})
export class UnitsGraphService {
  constructor(private functions: AngularFireFunctions) {}

  findUnitsGraph(artistId: ArtistId, from: Date, to: Date): Observable<Units[]> {
    return this.functions.httpsCallable<{ artistId: string; from: string; to: string }, Units[]>(
      'findUnitsGraph'
    )({
      artistId,
      from: moment(from).format('YYYY-MM-DD'),
      to: moment(to).format('YYYY-MM-DD'),
    })
  }
}
