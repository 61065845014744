import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as fromReducer from '../reducers/project-sale.reducer'
import * as moment from 'moment'

export const getProjectSaleState = createFeatureSelector<fromReducer.State>('projectSales')

export const getProjectSales = createSelector(
  getProjectSaleState,
  state => state.sales
)

export const getProjectSaleLoading = createSelector(
  getProjectSaleState,
  state => state.loading
)

export const getProjectSaleLoaded = createSelector(
  getProjectSaleState,
  state => state.loaded
)

export const getProjectTrendsCsv = createSelector(
  getProjectSaleState,
  state => {
    const data = []
    data.push(['集計期間', 'リリースから ' + moment().format('YYYY-MM-DD') + ' まで'])
    data.push([])
    const header = [
      'タイトル',
      '発売日',
      '売上（NET）',
      'アルバム換算数',
      'フィジカル売上枚数（NET）',
      '発売前出荷数',
      'D2C出荷枚数（NET）',
      'アルバムダウンロード数',
      'トラックダウンロード数',
      'ストリーム数',
    ]
    data.push(header)
    state.sales.map(item => {
      if (item.title != null) {
        data.push([
          item.title,
          moment(item.releaseDate).format('YYYY-MM-DD'),
          item.salesCurrencyAmount,
          item.albumAdjustedUnits,
          item.physicals,
          item.preOrderPhysicals,
          item.d2cPhysicals,
          item.albumDownloads,
          item.trackDownloads,
          item.streams,
        ])
      }
    })
    data.push([])
    data.push(['フィジカル売上枚数品番別内訳'])
    const detailHeader = [
      '作品タイトル',
      '品番別タイトル',
      '品番',
      'フィジカル売上枚数（NET）',
      '消化率',
      '発売前出荷数',
      'D2C出荷枚数（NET）',
    ]
    data.push(detailHeader)
    state.sales.map(item => {
      if (item.title != null) {
        item.physicalsDetail.map(detail => {
          if (detail.digestibility == null) {
            data.push([
              item.title,
              detail.productName,
              detail.productCode,
              detail.physicalsProd,
              '-',
              detail.preOrderPhysicalsProd,
              detail.d2cPhysicalsProd,
            ])
          } else {
            data.push([
              item.title,
              detail.productName,
              detail.productCode,
              detail.physicalsProd,
              detail.digestibility + '%',
              detail.preOrderPhysicalsProd,
              detail.d2cPhysicalsProd,
            ])
          }
        })
      }
    })
    return data
  }
)
