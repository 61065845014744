import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { HomeComponent } from 'src/app/views/containers/home/home.component'
import { ArtistComponent } from 'src/app/views/containers/artist/artist.component'
import { SpSalesComponent } from './views/containers/sp-sales/sp-sales.component'
import { SpAggregateAmountsComponent } from './views/containers/sp-aggregate-amounts/sp-aggregate-amounts.component'
import { SpChartsComponent } from './views/containers/sp-charts/sp-charts.component'
import { SpGraphComponent } from './views/containers/sp-graph/sp-graph.component'
import { SpDemographicsComponent } from './views/containers/sp-demographics/sp-demographics.component'

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  {
    path: 'artists/:artistId',
    component: ArtistComponent,
    children: [
      { path: 'sales', component: SpSalesComponent },
      { path: 'data', component: SpAggregateAmountsComponent },
      { path: 'charts', component: SpChartsComponent },
      { path: 'graph', component: SpGraphComponent },
      { path: 'demographics', component: SpDemographicsComponent },
    ],
  },
  { path: '**', redirectTo: 'home' },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
