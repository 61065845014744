import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { StreamsGraphService } from '../services/streams-graph.service'
import { refreshData } from '../actions/criteria.actions'
import { switchMap, catchError, map } from 'rxjs/operators'
import {
  loadStreamsGraphsSuccess,
  loadStreamsGraphsFailure,
} from '../actions/streams-graph.actions'
import { of } from 'rxjs'

@Injectable()
export class StreamsGraphEffects {
  constructor(private actions$: Actions, private service: StreamsGraphService) {}

  loadStreamsGraph$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshData),
      switchMap(action =>
        this.service.findStreamsGraph(action.artistId, action.from, action.to).pipe(
          map(streams => loadStreamsGraphsSuccess({ streams })),
          catchError(error => of(loadStreamsGraphsFailure({ error })))
        )
      )
    )
  )
}
