import { createAction, props } from '@ngrx/store'
import { Audience } from '../models/audience.model'

export const loadAudienceSuccess = createAction(
  '[Audience] Load Audience success',
  props<{ audience: Audience }>()
)
export const loadAudienceFailure = createAction(
  '[Audience] Load Audience failure',
  props<{ error: any }>()
)
