import { Component, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/reducers'
import { Observable } from 'rxjs'
import { CountableSummary } from 'src/app/models/latest-aggregate-amounts.model'
import {
  getNewsletterSubscriber,
  getLoading,
} from 'src/app/selectors/latest-aggregate-amounts.selector'
import {
  getDataCollectByNewsletterSubscriber,
  getDataCollectLoading,
} from 'src/app/selectors/data-collections.selector'

@Component({
  selector: 'app-aggregated-amounts-newsletter-subscriber-count',
  templateUrl: './aggregated-amounts-newsletter-subscriber-count.component.html',
  styleUrls: ['./aggregated-amounts-newsletter-subscriber-count.component.scss'],
})
export class AggregatedAmountsNewsletterSubscriberCountComponent implements OnInit {
  newsletterSubscriberCountModel$: Observable<CountableSummary>
  loading$: Observable<boolean>

  dataCollections$: Observable<boolean>
  dataCollectLoading$: Observable<boolean>

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.newsletterSubscriberCountModel$ = this.store.select(getNewsletterSubscriber)
    this.loading$ = this.store.select(getLoading)

    this.dataCollections$ = this.store.select(getDataCollectByNewsletterSubscriber)
    this.dataCollectLoading$ = this.store.select(getDataCollectLoading)
  }
}
