import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core'
import { ProjectSale } from 'src/app/models/project-sale.model'
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material'
@Component({
  selector: 'app-sp-project-sale-detail',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './sp-project-sale-detail.component.html',
  styleUrls: ['./sp-project-sale-detail.component.scss'],
})
export class SpProjectSaleDetailComponent implements OnInit {
  projectSales: ProjectSale[]
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public projectSale: ProjectSale) {
    this.projectSales = Object.keys(projectSale).map(key => projectSale[key])
  }

  ngOnInit() {}
}
