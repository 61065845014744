import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'

import { switchMap, map, catchError } from 'rxjs/operators'
import { of } from 'rxjs'

import * as FavoriteActions from '../actions/favorite.actions'
import { ArtistService } from 'src/app/services/artist.service'

@Injectable()
export class FavoriteEffects {
  loadFavorites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoriteActions.loadFavorites),
      switchMap(_ =>
        this.artistService.findFavoriteArtists().pipe(
          map(artists => FavoriteActions.loadFavoritesSuccess({ favorites: artists })),
          catchError(error => of(FavoriteActions.loadFavoritesFailure({ error })))
        )
      )
    )
  )

  constructor(private actions$: Actions, private artistService: ArtistService) {}
}
