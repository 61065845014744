import { createAction, props } from '@ngrx/store'
import { PeriodAggregateAmounts } from '../models/period-aggregate-amounts.model'

export const loadPeriodAggregateAamountsSuccess = createAction(
  '[PeriodAggregateAamounts] Find PeriodAggregateAamounts Success',
  props<{ periodAggregateAmounts: PeriodAggregateAmounts }>()
)

export const loadPeriodAggregateAamountsFailure = createAction(
  '[PeriodAggregateAamounts] Find PeriodAggregateAamounts Failure',
  props<{ error: any }>()
)
