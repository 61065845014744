import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { DownloadUnitGraphService } from '../services/download-unit-graph.service'
import * as downloadUnitGraphActions from '../actions/download-unit-graph.actions'
import * as CriteriaActions from '../actions/criteria.actions'
import { switchMap, map, catchError } from 'rxjs/operators'
import { of } from 'rxjs'

@Injectable()
export class DownloadUnitGraphEffects {
  loadDownloadUnitsGraph$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CriteriaActions.refreshData),
      switchMap(action =>
        this.downloadUnitGraphService.find(action.artistId, action.from, action.to).pipe(
          map(downloadUnits =>
            downloadUnitGraphActions.loadDownloadUnitsSuccess({ downloadUnits })
          ),
          catchError(error => of(downloadUnitGraphActions.loadDownloadUnitsFailure({ error })))
        )
      )
    )
  )

  constructor(
    private actions$: Actions,
    private downloadUnitGraphService: DownloadUnitGraphService
  ) {}
}
