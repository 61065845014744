import { createAction, props } from '@ngrx/store'
import { PhysicalsByCustomer } from '../models/physicals-by-customer.model'

export const loadPhysicalsGraphsSuccess = createAction(
  '[PhysicalsGraph] Load PhysicalsGraphs Success',
  props<{ physicals: PhysicalsByCustomer[] }>()
)

export const loadPhysicalsGraphsFailure = createAction(
  '[PhysicalsGraph] Load PhysicalsGraphs Failure',
  props<{ error: any }>()
)
