import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as fromFavorite from '../reducers/favorite.reducer'
import { Artist } from 'src/app/models/artist.model'

export const getFavoritesState = createFeatureSelector<fromFavorite.State>('favorites')

export const getFavoritesArtistByGenre = createSelector(
  getFavoritesState,
  (state): [string, Artist[]][] => {
    const favoritesByGenre = state.favorites.reduce((acc, artist) => {
      const genre = artist.genre
      acc.set(genre, acc.has(genre) ? [...acc.get(genre), artist] : [artist])
      return acc
    }, new Map<string, Artist[]>())

    const reserved = ['邦楽', '洋楽', 'クラシック', 'ジャズ', 'ディズニー']

    const reservedFavorites: [string, Artist[]][] = reserved.map(key => [
      key,
      favoritesByGenre.get(key) || [],
    ])

    reserved.forEach(key => favoritesByGenre.delete(key))

    return reservedFavorites
      .concat(Array.from(favoritesByGenre.entries()))
      .map(([key, values]) => [
        key,
        values.sort((a, b) => (a.sortingName > b.sortingName ? 1 : -1)),
      ])
  }
)

export const getFavoriteArtistsLoading = createSelector(
  getFavoritesState,
  state => state.loading
)

export const getFavoriteArtistsLoaded = createSelector(
  getFavoritesState,
  state => state.loaded
)

export const getFavoriteArtists = createSelector(
  getFavoritesState,
  state => state.favorites
)
