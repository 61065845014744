import { ArtistEffects } from 'src/app/effects/artist.effects'
import { CriteriaEffects } from 'src/app/effects/criteria.effects'
import { EventEffects } from 'src/app/effects/event.effects'
import { FavoriteEffects } from 'src/app/effects/favorite.effects'
import { ShellEffects } from 'src/app/effects/shell.effects'
import { HomepagesGraphEffects } from 'src/app/effects/homepages-graph.effects'
import { HomepagesUserGraphEffects } from 'src/app/effects/homepages-user-graph.effects'
import { UnitsGraphEffects } from './units-graph.effects'
import { ProjectSaleEffects } from 'src/app/effects/project-sale.effects'
import { TrackSaleEffects } from 'src/app/effects/track-sale.effects'
import { StreamsGraphEffects } from './streams-graph.effects'
import { PhysicalsGraphEffects } from './physicals-graph.effects'
import { DownloadUnitGraphEffects } from './download-unit-graph.effects'
import { ReactionsGraphEffects } from './reactions-graph.effects'
import { TwitterTagCloudEffects } from './twitter-tag-cloud.effects'
import { PeriodAggregateAmountsEffects } from './period-aggregate-amounts.effects'
import { LatestAggregateAmountsEffects } from './latest-aggregate-amounts.effects'
import { AudienceEffects } from './audience.effects'
import { ChartEffects } from './chart.effects'
import { DataCollectionInfoEffects } from './data-collections.effects'
import { YoutubeGraphEffects } from './youtube-graph.effects'
import { PrAnalyzerGraphEffects } from './pr-analyzer-graph.effects'
import { TiktokGraphEffects } from './tiktok-graph.effects'
// import { ChartRankEffects } from './chart-rank.effects'

export const effects = [
  ArtistEffects,
  CriteriaEffects,
  EventEffects,
  FavoriteEffects,
  ShellEffects,
  HomepagesGraphEffects,
  HomepagesUserGraphEffects,
  UnitsGraphEffects,
  ProjectSaleEffects,
  TrackSaleEffects,
  StreamsGraphEffects,
  PhysicalsGraphEffects,
  DownloadUnitGraphEffects,
  ReactionsGraphEffects,
  TwitterTagCloudEffects,
  PeriodAggregateAmountsEffects,
  LatestAggregateAmountsEffects,
  AudienceEffects,
  ChartEffects,
  DataCollectionInfoEffects,
  YoutubeGraphEffects,
  PrAnalyzerGraphEffects,
  TiktokGraphEffects,
  // ChartRankEffects,
]
