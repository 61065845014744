import { createAction, props } from '@ngrx/store'
import { YoutubeGraphPoint } from '../models/youtube-graph.model'

export const loadYoutubeGraphsSuccess = createAction(
  '[YoutubeGraph] Load YoutubeGraphs Success',
  props<{ youtube: YoutubeGraphPoint[] }>()
)
export const loadYoutubeGraphsFailure = createAction(
  '[YoutubeGraph] Load YoutubeGraphs Failure',
  props<{ error: any }>()
)
