import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core'
import { ArtistPageTypes } from 'src/app/reducers/shell.reducer'

@Component({
  selector: 'app-artist-footer',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./artist-footer.component.scss'],
  template: `
    <div class="bottom-toolbar mat-elevation-z4" *ngIf="page">
      <div class="bottom-toolbar-item" [class.selected]="page === 'sales'">
        <button mat-button routerLink="sales">
          <mat-icon>queue_music</mat-icon>
          <div class="caption">作品動向</div>
        </button>
      </div>
      <div class="bottom-toolbar-item" [class.selected]="page === 'data'">
        <button mat-button routerLink="data">
          <mat-icon>trending_up</mat-icon>
          <div class="caption">データ</div>
        </button>
      </div>
      <div class="bottom-toolbar-item" [class.selected]="page === 'graph'">
        <button mat-button routerLink="graph">
          <mat-icon>insert_chart_outlined</mat-icon>
          <div class="caption">グラフ</div>
        </button>
      </div>
      <div class="bottom-toolbar-item" [class.selected]="page === 'charts'">
        <button mat-button routerLink="charts">
          <mat-icon>star_border</mat-icon>
          <div class="caption">チャート</div>
        </button>
      </div>
      <div class="bottom-toolbar-item" [class.selected]="page === 'demographics'">
        <button mat-button routerLink="demographics">
          <mat-icon>face</mat-icon>
          <div class="caption">デモグラ</div>
        </button>
      </div>
    </div>
  `,
})
export class ArtistFooterComponent implements OnInit {
  @Input()
  page: ArtistPageTypes

  constructor() {}

  ngOnInit() {}
}
