import { Component, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/reducers'
import { Observable } from 'rxjs'
import { Title } from '@angular/platform-browser'
import { environment } from 'src/environments/environment'
import { Artist } from 'src/app/models/artist.model'
import { getSelectedArtist } from 'src/app/selectors/artist.selector'
import { CountableLinkSummary } from 'src/app/models/latest-aggregate-amounts.model'
import {
  getCriteriaFrom,
  getCriteriaTo,
  getCriteriaPreviousFrom,
  getCriteriaPreviousTo,
  getCriteriaFromBehindOneDay,
} from 'src/app/selectors/criteria.selector'
import {
  getTwitterFollowers,
  getFacebookFollowers,
  getInstagramFollowers,
  getLatestAggregatedAmounts,
  getLoading as getCsvLoadingLatest,
} from 'src/app/selectors/latest-aggregate-amounts.selector'
import {
  getPeriodAggregatedAmounts,
  getLoading as getCsvLoadingPeriod,
} from 'src/app/selectors/period-aggregate-amounts.selector'
import {
  getDataCollectByTwitterFollower,
  getDataCollectLoading,
} from 'src/app/selectors/data-collections.selector'

@Component({
  selector: 'app-sp-aggregate-amounts',
  templateUrl: './sp-aggregate-amounts.component.html',
  styleUrls: ['./sp-aggregate-amounts.component.scss'],
})
export class SpAggregateAmountsComponent implements OnInit {
  criteriaFrom$: Observable<Date>
  criteriaTo$: Observable<Date>
  criteriaPreviousFrom$: Observable<Date>
  criteriaPreviousTo$: Observable<Date>
  criteriaFromBehindOneDay$: Observable<Date>

  selectedArtist$: Observable<Artist>

  twitterFollowers$: Observable<CountableLinkSummary[]>
  facebookFollowers$: Observable<CountableLinkSummary[]>
  instagramFollowers$: Observable<CountableLinkSummary[]>
  loading$: Observable<boolean>
  dataCollections$: Observable<boolean>
  dataCollectLoading$: Observable<boolean>

  constructor(private store: Store<AppState>, private titleService: Title) {}

  csvLatestIdRecent = 'recent'
  csvLatestFileNameRecent = '最新値'
  csvLatestDataRecent$: Observable<any[]>
  csvLatestLoadingRecent$: Observable<boolean>

  csvPeriodIdTotal = 'total'
  csvPeriodFileNameTotal = '期間集計値'
  csvPeriodDataTotal$: Observable<any[]>
  csvPeriodLoadingTotal$: Observable<boolean>

  ngOnInit() {
    this.criteriaFrom$ = this.store.select(getCriteriaFrom)
    this.criteriaTo$ = this.store.select(getCriteriaTo)
    this.criteriaPreviousFrom$ = this.store.select(getCriteriaPreviousFrom)
    this.criteriaPreviousTo$ = this.store.select(getCriteriaPreviousTo)
    this.criteriaFromBehindOneDay$ = this.store.select(getCriteriaFromBehindOneDay)

    this.selectedArtist$ = this.store.select(getSelectedArtist)
    this.selectedArtist$.subscribe(artist =>
      this.titleService.setTitle(artist.nameMain + ' | ' + environment.title)
    )
    this.twitterFollowers$ = this.store.select(getTwitterFollowers)
    this.facebookFollowers$ = this.store.select(getFacebookFollowers)
    this.instagramFollowers$ = this.store.select(getInstagramFollowers)

    this.csvLatestDataRecent$ = this.store.select(getLatestAggregatedAmounts)
    this.csvLatestLoadingRecent$ = this.store.select(getCsvLoadingLatest)

    this.csvPeriodDataTotal$ = this.store.select(getPeriodAggregatedAmounts)
    this.csvPeriodLoadingTotal$ = this.store.select(getCsvLoadingPeriod)

    this.loading$ = this.store.select(getCsvLoadingLatest)
    this.dataCollections$ = this.store.select(getDataCollectByTwitterFollower)
    this.dataCollectLoading$ = this.store.select(getDataCollectLoading)
  }

  isZero(i: number): boolean {
    return i === 0
  }
}
