import { createAction, props } from '@ngrx/store'
import { DataCollections } from '../models/data-collections.model'

export const loadDataCollectionsSuccess = createAction(
  '[DataCollections] Load DataCollections success',
  props<{ dataCollections: DataCollections }>()
)
export const loadDataCollectionsFailure = createAction(
  '[DataCollections] Load DataCollections failure',
  props<{ error: any }>()
)
