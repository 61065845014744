import { createAction, props } from '@ngrx/store'
import { Artist } from 'src/app/models/artist.model'

export const loadFavorites = createAction('[Favorite] Load Favorites')

export const loadFavoritesSuccess = createAction(
  '[Favorite] Load Favorites Success',
  props<{ favorites: Artist[] }>()
)

export const loadFavoritesFailure = createAction(
  '[Favorite] Load Favorites Failure',
  props<{ error: any }>()
)
