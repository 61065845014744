import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { refreshData } from '../actions/criteria.actions'
import { switchMap, catchError, map } from 'rxjs/operators'
import { UnitsGraphService } from '../services/units-graph.service'
import * as UnitsGraphActions from '../actions/units-graph.actions'
import { of } from 'rxjs'

@Injectable()
export class UnitsGraphEffects {
  constructor(private actions$: Actions, private service: UnitsGraphService) {}

  loadUnitsGraph$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshData),
      switchMap(action =>
        this.service.findUnitsGraph(action.artistId, action.from, action.to).pipe(
          map(units => UnitsGraphActions.loadUnitsGraphsSuccess({ units })),
          catchError(error => of(UnitsGraphActions.loadUnitsGraphsFailure({ error })))
        )
      )
    )
  )
}
