import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core'

@Component({
  selector: 'app-data-field',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./data-field.component.scss'],
  template: `
    <div class="field">
      <div class="legend">{{ legend }}</div>
      <div class="value"><ng-content></ng-content></div>
    </div>
  `,
})
export class DataFieldComponent implements OnInit {
  @Input()
  legend: string

  constructor() {}

  ngOnInit() {}
}
