import * as fromHomepagesUserGraph from '../reducers/homepages-user-graph.reducer'
import { createFeatureSelector, createSelector } from '@ngrx/store'
import { getCriteriaFrom, getCriteriaTo } from './criteria.selector'
import * as moment from 'moment'

export const getHomepagesUserGraph = createFeatureSelector<fromHomepagesUserGraph.State>(
  'homepagesUserGraph'
)

export const getHomepagesUser = createSelector(
  getHomepagesUserGraph,
  state => state.homepagesUser
)

export const getLoading = createSelector(
  getHomepagesUserGraph,
  state => state.loading
)

export const getLoaded = createSelector(
  getHomepagesUserGraph,
  state => state.loaded
)

export const getHomepagesUserCsv = createSelector(
  getHomepagesUserGraph,
  getCriteriaFrom,
  getCriteriaTo,
  (state, from, to) => {
    const data = []
    data.push([
      '集計期間',
      moment(from).format('YYYY-MM-DD') + ' ~ ' + moment(to).format('YYYY-MM-DD'),
    ])
    data.push([])
    data.push(['日付'].concat([...new Set(state.homepagesUser.map(item => item.siteName))]))
    const charts = convert2ChartData(state.homepagesUser)
    charts.map(item => data.push(item))
    return data
  }
)

function convert2ChartData(obj: any[]): any[] {
  const dates = [...new Set(obj.map(item => item.transactionDate))]
  const column = []
  dates.forEach(d => {
    const data: any[] = [d]
    column.push(data)
    obj.map(units => {
      if (d === units.transactionDate) {
        data.push(units.units)
      }
    })
  })
  return column
}
