import { createAction, props } from '@ngrx/store'
import { Event } from 'src/app/models/event.model'

export const loadEventsSuccess = createAction(
  '[Event] Load Events Success',
  props<{ events: Event[] }>()
)

export const loadEventsFailure = createAction(
  '[Event] Load Events Failure',
  props<{ error: any }>()
)

export const selectEventDate = createAction(
  '[Event] Select Event Date',
  props<{ eventDate: string }>()
)

export const unselectEventDate = createAction('[Event] Unselect Event Date')
