import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { TiktokGraphService } from '../services/tiktok-graph.service'
import { refreshData } from '../actions/criteria.actions'
import { switchMap, catchError, map } from 'rxjs/operators'
import { loadTiktokGraphsSuccess, loadTiktokGraphsFailure } from '../actions/tiktok-graph.actions'
import { of } from 'rxjs'

@Injectable()
export class TiktokGraphEffects {
  constructor(private actions$: Actions, private service: TiktokGraphService) {}

  loadYoutubeGraph$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshData),
      switchMap(action =>
        this.service.findTiktokGraph(action.artistId, action.from, action.to).pipe(
          map(tiktok => loadTiktokGraphsSuccess({ tiktok })),
          catchError(error => of(loadTiktokGraphsFailure({ error })))
        )
      )
    )
  )
}
