import { Component, OnInit, ElementRef, Input, ChangeDetectionStrategy } from '@angular/core'
import * as moment from 'moment'
import { Artist } from 'src/app/models/artist.model'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/reducers'
import * as encoding from 'encoding'

@Component({
  selector: 'app-csv-download',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div *ngIf="!loading; else inProgress">
      <button
        mat-icon-button
        class="download-button"
        matTooltip="CSV ファイルをダウンロード"
        (click)="download($event)"
      >
        <mat-icon class="download-icon">get_app</mat-icon>
      </button>
      <a id="{{ id }}-csv"></a>
    </div>
    <ng-template #inProgress><div></div></ng-template>
  `,
  styleUrls: ['./csv-download.component.scss'],
})
export class CsvDownloadComponent implements OnInit {
  @Input()
  id: string
  @Input()
  csv: any[]
  @Input()
  fileName: string
  @Input()
  loading: boolean
  @Input()
  artist: Artist

  element: HTMLElement
  constructor(private store: Store<AppState>, private elementRef: ElementRef) {
    this.element = this.elementRef.nativeElement
  }

  ngOnInit() {}

  download = (event: any) => {
    const data = [
      ['ダウンロード日', moment().format('YYYY-MM-DD')],
      ['項目', this.fileName],
      ['アーティスト', this.artist.nameMain],
    ]
    this.csv.map(c => {
      data.push(
        c.map(obj => {
          if (obj != null) {
            return obj.toString()
          }
        })
      )
    })
    const blob = new Blob([this.array2csv(data)], {
      type: 'text/csv',
    })
    const url = window.URL.createObjectURL(blob)
    const link: HTMLAnchorElement = this.element.querySelector(
      '#' + this.id + '-csv'
    ) as HTMLAnchorElement
    link.href = url
    link.download =
      this.fileName +
      '-' +
      this.artist.nameMain.replace(' ', '_') +
      '-' +
      moment().format('YYYYMMDDHHmmss') +
      '.csv'
    link.target = '_blank'
    link.click()
    setTimeout(() => {
      window.URL.revokeObjectURL(url)
    }, 1000)
  }

  array2csv = (arr: any[]) => {
    const s = arr
      .map(row => row.map(str => '"' + (str ? str.replace(/"/g, '""') : '') + '"'))
      .map(row => row.join(','))
      .join('\r\n')
    return encoding.convert(s, 'windows-31j', 'utf8')
  }
}
