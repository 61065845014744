import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  ViewChild,
  OnChanges,
} from '@angular/core'
import { ProjectSale } from 'src/app/models/project-sale.model'
import { MatBottomSheet } from '@angular/material'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/reducers'
import { SpProjectSaleDetailComponent } from '../sp-project-sale-detail/sp-project-sale-detail.component'
import { AngularFireStorage } from '@angular/fire/storage'
import { Observable, from } from 'rxjs'

@Component({
  selector: 'app-sp-project-sale',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <mat-card>
      <div
        *ngIf="!loading && !projectSale.title"
        class="no-product"
        fxLayout
        fxLayoutAlign="center center"
      >
        リリース済みの作品はありません。
      </div>

      <div
        *ngIf="loading || projectSale.title"
        fxLayout="column"
        fxFlex="100%"
        fxLayoutAlign="space-evenly start"
        fxLayoutGap="16px"
      >
        <div class="trend" fxLayout="row">
          <div *ngIf="loading" class="jakesha-loading" fxLayout fxLayoutAlign="center center">
            <app-music-note></app-music-note>
          </div>
          <img *ngIf="!loading" fxFlex="0 0 96px" class="jakesha" [src]="jakeshaUrl$ | async" />
          <div fxFlex="1 1 auto" fxLayout="column" fxLayoutGap="16px">
            <div>
              <span class="title" *ngIf="!loading">{{ projectSale.title }}</span>
            </div>
            <div>
              <span *ngIf="!loading"
                >リリース {{ projectSale.releaseDate | date: 'yyyy-MM-dd' }}</span
              >
            </div>
          </div>
        </div>
        <div class="data-field">
          <app-sp-data-field legend="売上（NET）" fxFlex="1 1 100%">
            <div class="trend-item">
              <div class="trend-item-amount" *ngIf="!loading; else inProgress">
                {{ projectSale.salesCurrencyAmount | number }}
              </div>
            </div>
          </app-sp-data-field>
        </div>
        <div class="data-field">
          <app-sp-data-field legend="アルバム換算数" fxFlex="1 1 100%">
            <div
              class="trend-item"
              fxLayout="row wrap"
              fxLayoutAlign="end baseline"
              fxLayoutGap="8px"
            >
              <div class="trend-item-amount" *ngIf="!loading; else inProgress">
                {{ projectSale.albumAdjustedUnits | number }}
              </div>
            </div>
          </app-sp-data-field>
        </div>
        <div
          class="physicals"
          fxLayout="column"
          fxLayoutAlign="space-between start"
          fxFlex.sm="1 1 auto"
        >
          <div class="category" fxLayout="row wrap">
            <div class="legend">フィジカル売上枚数（NET）</div>
            <div fxFlex="1 1 auto"></div>
            <div class="value">
              <div
                class="trend-item"
                fxLayout="row wrap"
                fxLayoutAlign="end baseline"
                fxLayoutGap="8px"
              >
                <div class="trend-item-amount" *ngIf="!loading; else inProgress">
                  <div fxLayout fxLayoutAlign="end center" matRipple>
                    <div
                      fxLayout
                      fxLayoutAlign="end center"
                      matRipple
                      (click)="toggleDetailDrawer(projectSale)"
                    >
                      <mat-icon class="product-code-icon">format_list_bulleted</mat-icon>
                      <span class="toolbar-button-label">{{ projectSale.physicals | number }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="category" fxLayout="row wrap">
            <div class="legend">発売前出荷数</div>
            <div fxFlex="1 1 auto"></div>
            <div class="value">
              <div class="trend-item">
                <div class="trend-item-amount" *ngIf="!loading; else inProgress">
                  {{ projectSale.preOrderPhysicals | number }}
                </div>
              </div>
            </div>
          </div>
          <div class="category" fxLayout="row wrap">
            <div class="legend">D2C出荷枚数（NET）</div>
            <div fxFlex="1 1 auto"></div>
            <div class="value">
              <div
                class="trend-item"
                fxLayout="row wrap"
                fxLayoutAlign="end baseline"
                fxLayoutGap="8px"
              >
                <div class="trend-item-amount" *ngIf="!loading; else inProgress">
                  {{ projectSale.d2cPhysicals | number }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="data-field">
          <app-sp-data-field legend="アルバムダウンロード数" fxFlex="1 1 100%">
            <div
              class="trend-item"
              fxLayout="row wrap"
              fxLayoutAlign="end baseline"
              fxLayoutGap="8px"
            >
              <div class="trend-item-amount" *ngIf="!loading; else inProgress">
                {{ projectSale.albumDownloads | number }}
              </div>
            </div>
          </app-sp-data-field>
        </div>
        <div class="data-field">
          <app-sp-data-field legend="トラックダウンロード数" fxFlex="1 1 100%">
            <div
              class="trend-item"
              fxLayout="row wrap"
              fxLayoutAlign="end baseline"
              fxLayoutGap="8px"
            >
              <div class="trend-item-amount" *ngIf="!loading; else inProgress">
                {{ projectSale.trackDownloads | number }}
              </div>
            </div>
          </app-sp-data-field>
        </div>
        <div class="data-field">
          <app-sp-data-field legend="ストリーム数" fxFlex="1 1 100%">
            <div
              class="trend-item"
              fxLayout="row wrap"
              fxLayoutAlign="end baseline"
              fxLayoutGap="8px"
            >
              <div class="trend-item-amount" *ngIf="!loading; else inProgress">
                {{ projectSale.streams | number }}
              </div>
            </div>
          </app-sp-data-field>
        </div>
      </div>
    </mat-card>

    <ng-template #inProgress>
      <app-music-note></app-music-note>
    </ng-template>
  `,
  styleUrls: ['./sp-project-sale.component.scss'],
})
export class SpProjectSaleComponent implements OnInit, OnChanges {
  @Input()
  projectSale: ProjectSale

  @Input()
  loading = true

  jakeshaUrl$: Observable<any>
  constructor(
    private store: Store<AppState>,
    private bottomSheet: MatBottomSheet,
    private fireStorage: AngularFireStorage
  ) {}

  ngOnInit() {}

  ngOnChanges() {
    if (!this.loading) {
      this.jakeshaUrl$ = from(this.getJackeshaUrl(this.projectSale))
    }
  }

  async getJackeshaUrl(sale: ProjectSale): Promise<any> {
    const cloudStorage = this.fireStorage.storage.app.storage()
    const storageRef = cloudStorage.ref()
    let starsRef = storageRef.child('/jacket_pictures/no_printing.png')

    if (sale && sale.upc) {
      starsRef = storageRef.child(`/jacket_pictures/${sale.projectId}.JPEG`)
    }

    return await starsRef
      .getDownloadURL()
      .then(url => {
        return url
      })
      .catch(error => {
        return './assets/no_printing.png'
      })
  }

  toggleDetailDrawer(projectSale) {
    this.bottomSheet.open(SpProjectSaleDetailComponent, { data: { projectSale } })
  }
}
