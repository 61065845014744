import { Component, OnInit, Optional } from '@angular/core'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/reducers'
import { selectEventDate } from 'src/app/actions/event.actions'
import { Observable, Subscription } from 'rxjs'
import { Event } from 'src/app/models/event.model'
import { Artist } from 'src/app/models/artist.model'
import { getSelectedArtist } from 'src/app/selectors/artist.selector'
import {
  getEvents,
  isEventLoaded,
  getSelectedEventDate,
  isEventLoading,
  getCsvData,
} from 'src/app/selectors/event.selector'
import { MediaObserver, MediaChange } from '@angular/flex-layout'
import * as moment from 'moment'
import { MatBottomSheetRef } from '@angular/material/bottom-sheet'

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
})
export class EventsComponent implements OnInit {
  events$: Observable<Event[]>
  selectedEventDate$: Observable<string>
  isLoaded$: Observable<boolean>
  isLoading$: Observable<boolean>
  today = new Date()
  yesterday = new Date()
  csvId = 'event'
  csvData$: Observable<any[]>
  csvFileName = '出来事'
  csvLoading$: Observable<boolean>
  selectedArtist$: Observable<Artist>

  private subscriptions: Subscription[] = []

  constructor(
    public media: MediaObserver,
    private store: Store<AppState>,
    @Optional() private bottomSheetRef: MatBottomSheetRef<EventsComponent>
  ) {
    this.subscriptions.push(media.media$.subscribe((_: MediaChange) => {}))
  }

  openLink(event: MouseEvent): void {
    this.bottomSheetRef.dismiss()
    event.preventDefault()
  }

  ngOnInit() {
    this.events$ = this.store.select(getEvents)
    this.isLoaded$ = this.store.select(isEventLoaded)
    this.isLoading$ = this.store.select(isEventLoading)
    this.selectedEventDate$ = this.store.select(getSelectedEventDate)
    this.csvData$ = this.store.select(getCsvData)
    this.selectedArtist$ = this.store.select(getSelectedArtist)
  }

  selectEvent(event: Event): void {
    this.store.dispatch(selectEventDate({ eventDate: event.date }))
  }

  isSelected(event: Event, date?: string): boolean {
    if (!date) {
      return false
    }
    return moment(event.date).format('YYYYMMDD') === moment(date).format('YYYYMMDD')
  }

  onUrlClick(event: MouseEvent, url: string): void {
    event.stopPropagation()
    window.open(url)
  }

  onAddEventClick(): void {
    const url = 'https://umusic.cybozu.com/k/450/'
    window.open(url)
  }
}
