import { Component, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/reducers'
import { Observable } from 'rxjs'
import { CountableLinkSummary } from 'src/app/models/latest-aggregate-amounts.model'
import {
  getSpotifyFollowers,
  getLoading,
} from 'src/app/selectors/latest-aggregate-amounts.selector'
import { Artist } from 'src/app/models/artist.model'
import { getSelectedArtist } from 'src/app/selectors/artist.selector'
import {
  getDataCollectBySpotifyFollower,
  getDataCollectLoading,
} from 'src/app/selectors/data-collections.selector'

@Component({
  selector: 'app-aggregated-amounts-spotify-follower',
  templateUrl: './aggregated-amounts-spotify-follower.component.html',
  styleUrls: ['./aggregated-amounts-spotify-follower.component.scss'],
})
export class AggregatedAmountsSpotifyFollowerComponent implements OnInit {
  spotifyFollower$: Observable<CountableLinkSummary>
  selectedArtist$: Observable<Artist>
  loading$: Observable<boolean>

  dataCollections$: Observable<boolean>
  dataCollectLoading$: Observable<boolean>

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.spotifyFollower$ = this.store.select(getSpotifyFollowers)
    this.selectedArtist$ = this.store.select(getSelectedArtist)
    this.loading$ = this.store.select(getLoading)

    this.dataCollections$ = this.store.select(getDataCollectBySpotifyFollower)
    this.dataCollectLoading$ = this.store.select(getDataCollectLoading)
  }
}
