import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { MediaObserver, MediaChange } from '@angular/flex-layout'
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout'
import { AppState } from 'src/app/reducers'
import { getCriteriaFrom, getCriteriaTo } from 'src/app/selectors/criteria.selector'
import { Observable, Subscription } from 'rxjs'
import { map } from 'rxjs/operators'
import { Artist } from 'src/app/models/artist.model'
import { getSelectedArtist } from 'src/app/selectors/artist.selector'
import {
  getDataCollectByTwitterTagCloud,
  getDataCollectLoading,
} from 'src/app/selectors/data-collections.selector'
import {
  getCloudData,
  getLoading,
  getTwitterTagCloudCsv,
} from 'src/app/selectors/twitter-tag-cloud.selector'
import { TwitterTagCloud } from 'src/app/models/twitter-tag-cloud.model'
import { TagCloudComponent, CloudData, CloudOptions } from 'angular-tag-cloud-module'

@Component({
  selector: 'app-twitter-tag-cloud',
  templateUrl: './twitter-tag-cloud.component.html',
  styleUrls: ['./twitter-tag-cloud.component.scss'],
})
export class TwitterTagCloudComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('tagcloudcomp', { static: false })
  tagcloudcomp: TagCloudComponent

  @ViewChild('container', { static: false })
  container: ElementRef

  twitterTagCloud$: Observable<TwitterTagCloud[]>
  cloudData$: Observable<CloudData[]>
  loading$: Observable<boolean>
  criteriaFrom$: Observable<Date>
  criteriaTo$: Observable<Date>

  // 【各種オプション】
  // 0.0～1.0は 枠に対する割合(0%～100%)で取り扱われる。
  // 1より大きい値はピクセル幅として取り扱われる。

  pcOptions: CloudOptions = {
    width: 0.8,
    height: 300,
    overflow: false,
    realignOnResize: false,
    randomizeAngle: false,
    font: '',
  }

  spOptions: CloudOptions = {
    width: 1.0,
    height: 400,
    overflow: false,
    realignOnResize: false,
    randomizeAngle: false,
    font: '',
  }

  dataCollections$: Observable<boolean>
  dataCollectLoading$: Observable<boolean>

  csvId = 'twittertagcound'
  csvData$: Observable<any[]>
  csvFileName = 'Twitter キーワード投稿内容'
  selectedArtist$: Observable<Artist>

  isHandset$ = this.breakpointObserver.observe(Breakpoints.Handset).pipe(map(it => it.matches))

  private subscriptions: Subscription[] = []

  constructor(
    private store: Store<AppState>,
    public media: MediaObserver,
    private breakpointObserver: BreakpointObserver
  ) {
    this.subscriptions.push(media.media$.subscribe((_: MediaChange) => {}))
  }

  ngAfterViewInit() {
    // タグクラウドのreDraw()を行うと文字位置が変わる為、出来事の開閉では再描画しない
  }

  ngOnInit() {
    this.loading$ = this.store.select(getLoading)

    this.dataCollections$ = this.store.select(getDataCollectByTwitterTagCloud)
    this.dataCollectLoading$ = this.store.select(getDataCollectLoading)

    this.csvData$ = this.store.select(getTwitterTagCloudCsv)
    this.selectedArtist$ = this.store.select(getSelectedArtist)

    this.cloudData$ = this.store.select(getCloudData)

    this.criteriaFrom$ = this.store.select(getCriteriaFrom)
    this.criteriaTo$ = this.store.select(getCriteriaTo)
  }

  ngOnDestroy() {
    this.subscriptions.forEach(it => it.unsubscribe())
  }

  onResize(mode: string) {
    if (!this.tagcloudcomp) {
      return
    }
    const offsetWidth = this.container.nativeElement.offsetWidth
    if (mode === 'sp') {
      this.tagcloudcomp.config.width = offsetWidth
    } else {
      this.tagcloudcomp.config.width = offsetWidth - 320
    }
    this.tagcloudcomp.reDraw()
  }
}
