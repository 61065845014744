import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { map, switchMap, catchError } from 'rxjs/operators'
import {
  loadHomepagesGraphsSuccess,
  loadHomepagesGraphsFailure,
} from '../actions/homepages-graph.actions'
import { HomepagesGraphService } from '../services/homepages-graph.service'
import * as CriteriaActions from '../actions/criteria.actions'
import { of } from 'rxjs'

@Injectable()
export class HomepagesGraphEffects {
  constructor(private actions$: Actions, private service: HomepagesGraphService) {}

  loadHomepagesGraph = createEffect(() =>
    this.actions$.pipe(
      ofType(CriteriaActions.refreshData),
      switchMap(action =>
        this.service.findHomepagesGraph(action.artistId, action.from, action.to).pipe(
          map(d => loadHomepagesGraphsSuccess({ homepages: d })),
          catchError(error => of(loadHomepagesGraphsFailure({ error })))
        )
      )
    )
  )
}
