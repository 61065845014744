import { Component, OnInit, OnDestroy } from '@angular/core'
import { trigger, transition, query, animate, style, stagger } from '@angular/animations'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/reducers'
import { Observable, Subscription } from 'rxjs'
import { Artist } from 'src/app/models/artist.model'
import {
  getFavoritesArtistByGenre,
  getFavoriteArtistsLoading,
  getFavoriteArtistsLoaded,
} from 'src/app/selectors/favorite.selector'
import { loadFavorites } from 'src/app/actions/favorite.actions'
import { Title } from '@angular/platform-browser'
import { environment } from 'src/environments/environment'
import { MediaObserver, MediaChange } from '@angular/flex-layout'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('favoritesLoaded', [
      transition(':enter', [
        query(
          '.fav',
          [
            style({ opacity: 0, transform: 'translateY(-100px)' }),
            stagger(30, [
              animate(
                '200ms cubic-bezier(0.35, 0, 0.25, 1)',
                style({ opacity: 1, transform: 'none' })
              ),
            ]),
          ],
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class HomeComponent implements OnInit, OnDestroy {
  favorites$: Observable<[string, Artist[]][]>
  isLoading$: Observable<boolean>
  isLoaded$: Observable<boolean>
  private subscriptions: Subscription[] = []

  constructor(
    private store: Store<AppState>,
    private titleService: Title,
    public media: MediaObserver
  ) {
    this.subscriptions.push(media.media$.subscribe((_: MediaChange) => {}))
  }

  ngOnInit() {
    this.favorites$ = this.store.select(getFavoritesArtistByGenre)
    this.isLoading$ = this.store.select(getFavoriteArtistsLoading)
    this.isLoaded$ = this.store.select(getFavoriteArtistsLoaded)

    this.store.dispatch(loadFavorites())
    this.titleService.setTitle(environment.title)
  }

  ngOnDestroy() {
    this.subscriptions.forEach(it => it.unsubscribe())
  }
}
