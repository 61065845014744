import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { switchMap, map, catchError } from 'rxjs/operators'
import { of } from 'rxjs'
import { AudienceService } from '../services/audience.service'
import * as CriteriaActions from '../actions/criteria.actions'
import * as AudienceActions from '../actions/audience.actions'

@Injectable()
export class AudienceEffects {
  constructor(private actions$: Actions, private service: AudienceService) {}

  loadAudience$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CriteriaActions.refreshData),
      switchMap(action =>
        this.service.find(action.artistId, action.from, action.to).pipe(
          map(audience => AudienceActions.loadAudienceSuccess({ audience })),
          catchError(error => of(AudienceActions.loadAudienceFailure({ error })))
        )
      )
    )
  )
}
