import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core'
import { AppState } from 'src/app/reducers'
import { Store } from '@ngrx/store'
import {
  getDownloadUnitsGraph,
  getLoading,
  getDounloadsCsv,
} from 'src/app/selectors/download-unit-graph.selector'
import { GoogleChartComponent, ChartEvent } from 'angular-google-charts'
import { Observable, Subscription } from 'rxjs'
import { lineChartStyle13Lines } from 'src/app/utils/line-chart-style'
import { DownloadUnit } from 'src/app/models/download-unit.model'
import { map, take } from 'rxjs/operators'
import { isEventDrawerOpened } from 'src/app/selectors/shell.selector'
import { getSelectedEventDate } from 'src/app/selectors/event.selector'
import { openEventDrawer } from 'src/app/actions/shell.actions'
import { selectEventDate } from 'src/app/actions/event.actions'
import { range } from 'src/app/utils/range'
import * as moment from 'moment'
import {
  getDataCollectByDownloadsGraph,
  getDataCollectLoading,
} from 'src/app/selectors/data-collections.selector'
import { Artist } from 'src/app/models/artist.model'
import { getSelectedArtist } from 'src/app/selectors/artist.selector'
import { MediaObserver, MediaChange } from '@angular/flex-layout'
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout'
import { of } from 'rxjs'

@Component({
  selector: 'app-download-units-graph',
  templateUrl: './download-units-graph.component.html',
  styleUrls: ['./download-units-graph.component.scss'],
})
export class DownloadUnitsGraphComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('chart', { static: false })
  chart: GoogleChartComponent

  @ViewChild('container', { static: false })
  container: ElementRef

  private widthOpened = false
  private widthWhenOpened = 0
  private widthWhenClosed = 0

  subAccounts$: Observable<string[]>
  units$: Observable<any[]>
  isLoading$: Observable<boolean>

  dataCollections$: Observable<boolean>
  dataCollectLoading$: Observable<boolean>

  unitsOptions = lineChartStyle13Lines

  private subscriptions: Subscription[] = []

  csvId = 'download'
  csvData$: Observable<any[]>
  csvFileName = 'ダウンロード数'
  selectedArtist$: Observable<Artist>

  isHandset$ = this.breakpointObserver.observe(Breakpoints.Handset).pipe(map(it => it.matches))
  allSubAccountsStreams$: Observable<string[]>
  checkedSubAccounts: any[]
  unDispLabels: any[]

  checkLabels: any[]
  colorsOption: any[]
  downloadOptions: any

  constructor(
    private store: Store<AppState>,
    public media: MediaObserver,
    private breakpointObserver: BreakpointObserver
  ) {
    this.subscriptions.push(media.media$.subscribe((_: MediaChange) => { }))
  }

  ngOnInit() {
    this.isLoading$ = this.store.select(getLoading)
    this.getIniData()

    this.dataCollections$ = this.store.select(getDataCollectByDownloadsGraph)
    this.dataCollectLoading$ = this.store.select(getDataCollectLoading)
    this.csvData$ = this.store.select(getDounloadsCsv)
    this.selectedArtist$ = this.store.select(getSelectedArtist)

    this.subscriptions.push(
      this.isLoading$.subscribe(it => {
        if (it) {
          this.getIniData()
        }
      })
    )

    this.subscriptions.push(
      this.store.select(isEventDrawerOpened).subscribe(opened => {
        if (!this.chart) {
          return
        }
        this.widthOpened = opened
        this.chart.width = opened ? this.widthWhenOpened : this.widthWhenClosed
        this.chart.ngOnChanges()
      })
    )

    this.subscriptions.push(
      this.store.select(getSelectedEventDate).subscribe(date => {
        if (!this.chart) {
          return
        }
        if (!date) {
          this.chart.wrapper.getChart().setSelection(null)
        }
        const ymd = moment(date).format('YYYY-MM-DD')
        const columnNum = this.chart.columnNames.length - 1
        this.units$.pipe(take(1)).subscribe(it => {
          const index = it.findIndex(data => data[0] === ymd)
          if (index !== -1) {
            const selections: { row: number; column: number }[] = range(0, columnNum).map(i => ({
              row: index,
              column: i + 1,
            }))
            this.chart.wrapper.getChart().setSelection(selections)
          }
        })
      })
    )

    if (this.media.isActive('lt-md')) {
      this.unitsOptions.chartArea.left = 45
    } else {
      this.unitsOptions.chartArea.left = 80
    }
  }

  private getIniData() {
    this.allSubAccountsStreams$ = this.store
      .select(getDownloadUnitsGraph)
      .pipe(map(it => [...new Set(it.map(item => item.subAccountName))]))
    this.subAccounts$ = this.store
      .select(getDownloadUnitsGraph)
      .pipe(map(it => ['日付'].concat([...new Set(it.map(item => item.subAccountName))])))
    this.units$ = this.store.select(getDownloadUnitsGraph).pipe(
      map(it => {
        return this.convert2ChartData(it)
      })
    )
    this.allSubAccountsStreams$.subscribe(it => {
      this.checkLabels = []
      it.forEach(item => {
        this.checkLabels.push({ name: item, completed: true, disabled: false })
      })
    })
    // this.checkLabels = [
    //   { name: 'Amazon', completed: true },
    //   { name: 'Apple', completed: true },
    //   { name: 'RecoChoku', completed: true },
    // ]
    // downloadsの取得先と色をマッピングして、グラフのオプションを設定する
    this.subAccounts$
      .pipe(
        map(it => {
          // streamsの取得先と色をマッピング
          return this.mappingSourceAndColor(it)
        })
      )
      .subscribe(val => {
        // 色のオプションを取得する
        this.colorsOption = []
        val.forEach(d => this.colorsOption.push(d))

        // グラフのオプションを設定する
        this.unitsOptions = {
          ...lineChartStyle13Lines,
          colors: this.colorsOption,
        }
      })
  }

  // 選択したsubAccountsのunitsを表示する
  onChange() {
    // 一つのcheckboxだけをチェックしたら、disableする
    this.disableCheckbox()
    this.checkedSubAccounts = []
    // this.unDispLabels = [
    //   'Avex',
    //   'Digimerce',
    //   'Dwango',
    //   'Google',
    //   'Label Gate',
    //   'Line Music',
    //   'MTI',
    //   'OTOTOY',
    //   'Oricon',
    // ]

    /* 
    checkedSubAccountsをunitsの順で取得する
    checkboxがチェックしたら、該当labelを表示する
    */
    // this.checkLabels.forEach(it => {
    //   if (it.completed) {
    //     switch (it.name) {
    //       case 'Amazon': {
    //         document.getElementById('lblAmazon').style.display = 'flex'
    //         this.checkedSubAccounts.push(it.name)
    //         break
    //       }
    //       case 'Apple': {
    //         document.getElementById('lblApple').style.display = 'flex'
    //         this.checkedSubAccounts.push(it.name)
    //         break
    //       }
    //       case 'RecoChoku': {
    //         document.getElementById('lblReco').style.display = 'flex'
    //         this.unDispLabels.push(it.name)
    //         break
    //       }
    //     }
    //   } else {
    //     // checkboxが外す時、該当labelを非表示する
    //     switch (it.name) {
    //       case 'Amazon': {
    //         document.getElementById('lblAmazon').style.display = 'none'
    //         break
    //       }
    //       case 'Apple': {
    //         document.getElementById('lblApple').style.display = 'none'
    //         break
    //       }
    //       case 'RecoChoku': {
    //         document.getElementById('lblReco').style.display = 'none'
    //         break
    //       }
    //     }
    //   }
    // })
    // this.checkedSubAccounts = this.checkedSubAccounts.concat(this.unDispLabels)
    // this.checkedSubAccounts.push('YMM')

    this.checkLabels.forEach(it => {
      if (it.completed) {
        this.checkedSubAccounts.push(it.name)
      }
    })

    // 選択したsubAccountsを再取得する
    this.subAccounts$ = of(['日付'].concat(this.checkedSubAccounts))

    // 選択したsubAccountsのstreamsを再取得する
    this.units$ = this.store.select(getDownloadUnitsGraph).pipe(
      map(it => {
        return this.convertSelected2ChartData(it)
      })
    )

    // downloadsの取得先と色をマッピングして、グラフのオプションを設定する
    this.subAccounts$
      .pipe(
        map(it => {
          // streamsの取得先と色をマッピング
          return this.mappingSourceAndColor(it)
        })
      )
      .subscribe(val => {
        // 色のオプションを取得する
        this.colorsOption = []
        val.forEach(d => this.colorsOption.push(d))

        // グラフのオプションを設定する
        this.unitsOptions = {
          ...lineChartStyle13Lines,
          colors: this.colorsOption,
        }
      })
  }

  ngAfterViewInit() {
    if (!this.container) {
      return
    }

    this.widthWhenClosed = this.container.nativeElement.offsetWidth - 20
    this.widthWhenOpened = this.widthWhenClosed - 300
  }

  ngOnDestroy() {
    this.subscriptions.forEach(it => it.unsubscribe())
  }

  onResize() {
    if (!this.chart) {
      return
    }
    // リサイズ時、出来事の開閉状態によって算出が異なる
    if (this.widthOpened) {
      this.widthWhenOpened = this.container.nativeElement.offsetWidth - 20
      this.widthWhenClosed = this.widthWhenOpened + 300
      this.chart.width = this.widthWhenOpened
    } else {
      this.widthWhenClosed = this.container.nativeElement.offsetWidth - 20
      this.widthWhenOpened = this.widthWhenClosed - 300
      this.chart.width = this.widthWhenClosed
    }
    this.chart.ngOnChanges()
  }

  onSelect(event: ChartEvent[]) {
    if (event.length === 0) {
      return
    }

    const isLegendsSelected = event[0].row === null

    this.units$.pipe(take(1)).subscribe(it => {
      if (isLegendsSelected) {
        return
      }
      this.isHandset$.subscribe(h => {
        if (!h) {
          this.store.dispatch(openEventDrawer())
          this.store.dispatch(selectEventDate({ eventDate: it[event[0].row][0] }))
        }
      })
    })
  }

  private disableCheckbox() {
    var count = 0
    for (var i = 0; i < this.checkLabels.length; i++) {
      if (this.checkLabels[i].completed) {
        count++

        if (this.checkLabels[i].disabled) {
          this.checkLabels[i].disabled = false
        }
      }
    }
    if (count === 1) {
      for (var i = 0; i < this.checkLabels.length; i++) {
        if (this.checkLabels[i].completed) {
          this.checkLabels[i].disabled = true
        }
      }
    }
  }

  private convert2ChartData(downloadUnits: DownloadUnit[]): any[] {
    const dates = [...new Set(downloadUnits.map(item => item.transactionDate))]
    const column = []
    dates.forEach(d => {
      const data: any[] = [d]
      column.push(data)
      downloadUnits.map(units => {
        if (d === units.transactionDate) {
          data.push(units.units)
        }
      })
    })
    return column
  }

  private convertSelected2ChartData(downloadUnits: DownloadUnit[]): any[] {
    const dates = [...new Set(downloadUnits.map(item => item.transactionDate))]
    const column = []
    dates.forEach(d => {
      const data: any[] = [d]
      column.push(data)
      downloadUnits.map(units => {
        if (d === units.transactionDate) {
          this.checkedSubAccounts.forEach(item => {
            if (item === units.subAccountName) {
              data.push(units.units)
            }
          })
        }
      })
    })
    return column
  }

  /* downloadの取得先と色をマッピング処理
     取得先がAmazon, Apple, RecoChokuの場合、
     グラフ線の色を固定します。上記以外の10件の取得先の色はランダムします。それ以外の場合は同じ色にします。
  */
  private mappingSourceAndColor(subAccounts: string[]): string[] {
    const colors = []
    const colorsRandom = [
      // '#673ab7',
      // '#cb5393',
      '#e60000',
      '#8bc34a',
      '#2196f3',
      '#ffc107',
      '#03a9f4',
      '#009688',
      '#00bcd4',
      '#ffeb3b',
    ]
    const colors14Above = '#ff22ca'
    subAccounts.forEach(s => {
      switch (s) {
        case 'Amazon': {
          colors.push('#ff5722')
          break
        }
        case 'Apple': {
          colors.push('#ff9800')
          break
        }
        case 'RecoChoku': {
          colors.push('#3f51b5')
          break
        }
        case 'Label Gate': {
          colors.push('#673ab7')
          break
        }
        case 'Dwango': {
          colors.push('#cb5393')
          break
        }
        case '日付': {
          break
        }
        default: {
          if (colorsRandom.length > 0) {
            // 上記以外の10件の取得先が色がランダムします。
            colors.push(colorsRandom.shift())
          } else {
            // 上記以外取得先の色が同じ色にします。
            colors.push(colors14Above)
          }
          break
        }
      }
    })
    return colors
  }
}
