import * as fromStreamsGraph from '../reducers/streams-graph.reducer'
import { createFeatureSelector, createSelector } from '@ngrx/store'
import { getCriteriaFrom, getCriteriaTo } from './criteria.selector'
import * as moment from 'moment'

export const getStreamsGraph = createFeatureSelector<fromStreamsGraph.State>('streamsGraph')

export const getStreams = createSelector(
  getStreamsGraph,
  state => state.streams
)

export const getLoading = createSelector(
  getStreamsGraph,
  state => state.loading
)

export const getLoaded = createSelector(
  getStreamsGraph,
  state => state.loaded
)

export const getStreamsCsv = createSelector(
  getStreamsGraph,
  getCriteriaFrom,
  getCriteriaTo,
  (state, from, to) => {
    const data = []
    data.push([
      '集計期間',
      moment(from).format('YYYY-MM-DD') + ' ~ ' + moment(to).format('YYYY-MM-DD'),
    ])
    data.push([])
    data.push(['日付'].concat([...new Set(state.streams.map(item => item.subAccountName))]))
    const dates = [...new Set(state.streams.map(item => item.transactionDate))]
    const column = []
    dates.forEach(d => {
      const c: any[] = [d]
      column.push(c)
      state.streams.map(stream => {
        if (d === stream.transactionDate) {
          c.push(stream.streams)
        }
      })
    })
    column.map(item => data.push(item))
    return data
  }
)
