import * as fromHomepagesGraph from '../reducers/homepages-graph.reducer'
import { createFeatureSelector, createSelector } from '@ngrx/store'
import { getCriteriaFrom, getCriteriaTo } from './criteria.selector'
import * as moment from 'moment'

export const getHomepagesGraph = createFeatureSelector<fromHomepagesGraph.State>('homepagesGraph')

export const getHomepages = createSelector(
  getHomepagesGraph,
  state => state.homepages
)

export const getLoading = createSelector(
  getHomepagesGraph,
  state => state.loading
)

export const getLoaded = createSelector(
  getHomepagesGraph,
  state => state.loaded
)

export const getHomepagesCsv = createSelector(
  getHomepagesGraph,
  getCriteriaFrom,
  getCriteriaTo,
  (state, from, to) => {
    const data = []
    data.push([
      '集計期間',
      moment(from).format('YYYY-MM-DD') + ' ~ ' + moment(to).format('YYYY-MM-DD'),
    ])
    data.push([])
    data.push(['日付'].concat([...new Set(state.homepages.map(item => item.siteName))]))
    const charts = convert2ChartData(state.homepages)
    charts.map(item => data.push(item))
    return data
  }
)

function convert2ChartData(obj: any[]): any[] {
  const dates = [...new Set(obj.map(item => item.transactionDate))]
  const column = []
  dates.forEach(d => {
    const data: any[] = [d]
    column.push(data)
    obj.map(units => {
      if (d === units.transactionDate) {
        data.push(units.units)
      }
    })
  })
  return column
}
