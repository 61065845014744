import { createAction, props } from '@ngrx/store'
import { LatestAggregateAmounts } from '../models/latest-aggregate-amounts.model'

export const loadLatestAggregateAamountsSuccess = createAction(
  '[LatestAggregateAamounts] Find LatestAggregateAamounts Success',
  props<{ latestAggregateAmounts: LatestAggregateAmounts }>()
)

export const loadLatestAggregateAamountsFailure = createAction(
  '[LatestAggregateAamounts] Find LatestAggregateAamounts Failure',
  props<{ error: any }>()
)
