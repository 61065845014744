import { createAction, props } from '@ngrx/store'
import { DownloadUnit } from '../models/download-unit.model'

export const loadDownloadUnitsSuccess = createAction(
  '[DownloadUnits Service] Load Download Units Success',
  props<{ downloadUnits: DownloadUnit[] }>()
)

export const loadDownloadUnitsFailure = createAction(
  '[DownloadUnits Service] Load Download Units Failure',
  props<{ error: any }>()
)
