import { Component, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/reducers'
import { Observable } from 'rxjs'
import { Artist } from 'src/app/models/artist.model'
import { environment } from 'src/environments/environment'
import { getSelectedArtist } from 'src/app/selectors/artist.selector'
import { Title } from '@angular/platform-browser'

@Component({
  selector: 'app-sp-charts',
  templateUrl: './sp-charts.component.html',
  styleUrls: ['./sp-charts.component.scss'],
})
export class SpChartsComponent implements OnInit {
  selectedArtist$: Observable<Artist>

  constructor(private store: Store<AppState>, private titleService: Title) {}

  ngOnInit() {
    this.selectedArtist$ = this.store.select(getSelectedArtist)
    this.selectedArtist$.subscribe(artist =>
      this.titleService.setTitle(artist.nameMain + ' | ' + environment.title)
    )
  }
}
