import { Component, OnInit, Input, ChangeDetectionStrategy, ViewChild } from '@angular/core'
import { TrackSale } from 'src/app/models/track-sale.model'
import { environment } from 'src/environments/environment'
import { MatBottomSheet } from '@angular/material'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/reducers'

@Component({
  selector: 'app-sp-track-sale',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <mat-card>
      <div
        *ngIf="!loading && !trackSale.title"
        class="no-product"
        fxLayout
        fxLayoutAlign="center center"
      >
        リリース済みの作品はありません。
      </div>

      <div
        *ngIf="loading || trackSale.title"
        fxLayout="column"
        fxFlex="100%"
        fxLayoutAlign="space-evenly start"
        fxLayoutGap="16px"
      >
        <div class="trend" fxLayout="row">
          <div fxFlex="1 1 auto" fxLayout="column" fxLayoutGap="16px">
            <div>
              <span class="title" *ngIf="!loading">{{ trackSale.title }}</span>
            </div>
          </div>
        </div>
        <div class="data-field">
          <app-sp-data-field legend="売上（NET）" fxFlex="1 1 100%">
            <div
              class="trend-item"
              fxLayout="row wrap"
              fxLayoutAlign="end baseline"
              fxLayoutGap="8px"
            >
              <ng-container *ngIf="!loading; else inProgress">
                <div class="trend-item-amount value">
                  {{ trackSale.salesCurrencyAmount | number }}
                  <span>&nbsp;</span>
                </div>
                <div fxLayout fxLayoutAlign="start center">
                  <ng-container *ngIf="trackSale.salesCurrencyAmountRate !== null; else noRate">
                    <div class="item-percent">
                      {{ trackSale.salesCurrencyAmountRate | number: '1.0-0' }}%
                    </div>
                    <mat-icon *ngIf="trackSale.salesCurrencyAmountRate > 100">trending_up</mat-icon>
                    <mat-icon *ngIf="trackSale.salesCurrencyAmountRate < 100"
                      >trending_down</mat-icon
                    >
                    <mat-icon *ngIf="trackSale.salesCurrencyAmountRate == 100"></mat-icon>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </app-sp-data-field>
        </div>
        <div class="data-field">
          <app-sp-data-field legend="アルバム換算数" fxFlex="1 1 100%">
            <div
              class="trend-item"
              fxLayout="row wrap"
              fxLayoutAlign="end baseline"
              fxLayoutGap="8px"
            >
              <ng-container *ngIf="!loading; else inProgress">
                <div class="trend-item-amount value">
                  {{ trackSale.trackAdjustedUnits | number }}
                  <span>&nbsp;</span>
                </div>
                <div fxLayout fxLayoutAlign="start center">
                  <ng-container *ngIf="trackSale.trackAdjustedUnitsRate !== null; else noRate">
                    <div class="item-percent">
                      {{ trackSale.trackAdjustedUnitsRate | number: '1.0-0' }}%
                    </div>
                    <mat-icon *ngIf="trackSale.trackAdjustedUnitsRate > 100">trending_up</mat-icon>
                    <mat-icon *ngIf="trackSale.trackAdjustedUnitsRate < 100"
                      >trending_down</mat-icon
                    >
                    <mat-icon *ngIf="trackSale.trackAdjustedUnitsRate == 100"></mat-icon>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </app-sp-data-field>
        </div>
        <div class="data-field">
          <app-sp-data-field legend="トラックダウンロード数" fxFlex="1 1 100%">
            <div
              class="trend-item"
              fxLayout="row wrap"
              fxLayoutAlign="end baseline"
              fxLayoutGap="8px"
            >
              <ng-container *ngIf="!loading; else inProgress">
                <div class="trend-item-amount value">
                  {{ trackSale.trackDownloads | number }}
                  <span>&nbsp;</span>
                </div>
                <div fxLayout fxLayoutAlign="start center">
                  <ng-container *ngIf="trackSale.trackDownloadsRate !== null; else noRate">
                    <div class="item-percent">
                      {{ trackSale.trackDownloadsRate | number: '1.0-0' }}%
                    </div>
                    <mat-icon *ngIf="trackSale.trackDownloadsRate > 100">trending_up</mat-icon>
                    <mat-icon *ngIf="trackSale.trackDownloadsRate < 100">trending_down</mat-icon>
                    <mat-icon *ngIf="trackSale.trackDownloadsRate == 100"></mat-icon>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </app-sp-data-field>
        </div>
        <div class="data-field">
          <app-sp-data-field legend="ストリーム数" fxFlex="1 1 100%">
            <div
              class="trend-item"
              fxLayout="row wrap"
              fxLayoutAlign="end baseline"
              fxLayoutGap="8px"
            >
              <ng-container *ngIf="!loading; else inProgress">
                <div class="trend-item-amount value">
                  {{ trackSale.streams | number }}
                  <span>&nbsp;</span>
                </div>
                <div fxLayout fxLayoutAlign="start center">
                  <ng-container *ngIf="trackSale.streamsRate !== null; else noRate">
                    <div class="item-percent">{{ trackSale.streamsRate | number: '1.0-0' }}%</div>
                    <mat-icon *ngIf="trackSale.streamsRate > 100">trending_up</mat-icon>
                    <mat-icon *ngIf="trackSale.streamsRate < 100">trending_down</mat-icon>
                    <mat-icon *ngIf="trackSale.streamsRate == 100"></mat-icon>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </app-sp-data-field>
        </div>
      </div>
    </mat-card>

    <ng-template #inProgress>
      <app-music-note></app-music-note>
    </ng-template>

    <ng-template #noRate>
      <div>- %</div>
    </ng-template>
  `,
  styleUrls: ['./sp-track-sale.component.scss'],
})
export class SpTrackSaleComponent implements OnInit {
  @Input()
  trackSale: TrackSale

  @Input()
  loading = true

  constructor(private store: Store<AppState>, private bottomSheet: MatBottomSheet) {}

  ngOnInit() {}
}
