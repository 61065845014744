import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { DataCollectionsService } from '../services/data-collections.service'
import * as dataCollectionsAction from '../actions/data-collections.actions'
import * as CriteriaActions from '../actions/criteria.actions'
import { switchMap, map, catchError } from 'rxjs/operators'
import { of } from 'rxjs'

@Injectable()
export class DataCollectionInfoEffects {
  loadChart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CriteriaActions.refreshData),
      switchMap(action =>
        this.dataCollectionsService.find(action.artistId, action.from, action.to).pipe(
          map(dataCollections =>
            dataCollectionsAction.loadDataCollectionsSuccess({ dataCollections })
          ),
          catchError(error => of(dataCollectionsAction.loadDataCollectionsFailure({ error })))
        )
      )
    )
  )

  constructor(private actions$: Actions, private dataCollectionsService: DataCollectionsService) {}
}
