import { createAction, props } from '@ngrx/store'
import { Homepages } from '../models/homepages.model'

export const loadHomepagesGraphsSuccess = createAction(
  '[HomepagesGraph] Load HomepagesGraphs Success',
  props<{ homepages: Homepages[] }>()
)

export const loadHomepagesGraphsFailure = createAction(
  '[HomepagesGraph] Load HomepagesGraphs Failure',
  props<{ error: any }>()
)
