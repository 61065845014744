import { Action, createReducer, on } from '@ngrx/store'
import * as CriteriaActions from '../actions/criteria.actions'
import * as ChartActions from '../actions/chart.actions'
import { ChartInfo, ChartPart } from '../models/chart.model'

export interface State {
  currentFrom: string
  currentTo: string
  spotifyInfo: ChartPart[]
  oriconPhysicalDailySingleInfo: ChartInfo[]
  oriconPhysicalDailyAlbumInfo: ChartInfo[]
  oriconPhysicalWeeklySingleInfo: ChartInfo[]
  oriconPhysicalWeeklyAlbumInfo: ChartInfo[]
  oriconPhysicalWeeklyDVDInfo: ChartInfo[]
  oriconStreamingDailyInfo: ChartInfo[]
  oriconStreamingWeeklyInfo: ChartInfo[]
  tiktokWeeklyInfo: ChartPart[]
  lineMusicInfo: ChartPart[]
  iTunesInfo: ChartPart[]
  appleMusicInfo: ChartPart[]
  amazonInfo: ChartPart[]
  shazamInfo: ChartPart[]
  loading: boolean
  loaded: boolean
}

export const initialState: State = {
  currentFrom: '',
  currentTo: '',
  spotifyInfo: [],
  oriconPhysicalDailySingleInfo: [],
  oriconPhysicalDailyAlbumInfo: [],
  oriconPhysicalWeeklySingleInfo: [],
  oriconPhysicalWeeklyAlbumInfo: [],
  oriconPhysicalWeeklyDVDInfo: [],
  oriconStreamingDailyInfo: [],
  oriconStreamingWeeklyInfo: [],
  tiktokWeeklyInfo: [],
  lineMusicInfo: [],
  iTunesInfo: [],
  appleMusicInfo: [],
  amazonInfo: [],
  shazamInfo: [],
  loading: true,
  loaded: false,
}

const chartReducer = createReducer(
  initialState,

  on(CriteriaActions.refreshData, (state, action) => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(ChartActions.loadChartSuccess, (state, action) => ({
    currentFrom: action.chart.currentFrom,
    currentTo: action.chart.currentTo,
    spotifyInfo: action.chart.spotifyInfo,
    oriconPhysicalDailySingleInfo: action.chart.oriconPhysicalDailySingleInfo,
    oriconPhysicalDailyAlbumInfo: action.chart.oriconPhysicalDailyAlbumInfo,
    oriconPhysicalWeeklySingleInfo: action.chart.oriconPhysicalWeeklySingleInfo,
    oriconPhysicalWeeklyAlbumInfo: action.chart.oriconPhysicalWeeklyAlbumInfo,
    oriconPhysicalWeeklyDVDInfo: action.chart.oriconPhysicalWeeklyDVDInfo,
    oriconStreamingDailyInfo: action.chart.oriconStreamingDailyInfo,
    oriconStreamingWeeklyInfo: action.chart.oriconStreamingWeeklyInfo,
    tiktokWeeklyInfo: action.chart.tiktokWeeklyInfo,
    lineMusicInfo: action.chart.lineMusicInfo,
    iTunesInfo: action.chart.iTunesInfo,
    appleMusicInfo: action.chart.appleMusicInfo,
    amazonInfo: action.chart.amazonInfo,
    shazamInfo: action.chart.shazamInfo,
    loading: false,
    loaded: true,
  })),
  on(ChartActions.loadChartFailure, (state, action) => state)
)

export function reducer(state: State | undefined, action: Action) {
  return chartReducer(state, action)
}
