import { createAction, props } from '@ngrx/store'
import { ProjectSale } from 'src/app/models/project-sale.model'
import {ArtistId} from 'src/app/models/artist.model';

export const loadProjectSales = createAction(
  '[ProjectSale] Load ProjectSales',
  props<{ artistId: ArtistId }>()
)

export const loadProjectSalesSuccess = createAction(
  '[ProjectSale] Load ProjectSales Success',
  props<{ sales: ProjectSale[] }>()
)

export const loadProjectSalesFailure = createAction(
  '[ProjectSale] Load ProjectSales Failure',
  props<{ error: any }>()
)
