import * as fromLatestAggregateAmounts from '../reducers/latest-aggregate-amounts.reducer'
import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as moment from 'moment'
import { getCriteriaFrom, getCriteriaTo } from './criteria.selector'

export const getLatestAggregateAmountsState = createFeatureSelector<
  fromLatestAggregateAmounts.State
>('latestAggregateAmounts')

// Twitterフォロワー数
export const getTwitterFollowers = createSelector(
  getLatestAggregateAmountsState,
  state => state.twitterFollowers
)

// Facebookフォロワー数
export const getFacebookFollowers = createSelector(
  getLatestAggregateAmountsState,
  state => state.facebookFollowers
)

// Instagramフォロワー数
export const getInstagramFollowers = createSelector(
  getLatestAggregateAmountsState,
  state => state.instagramFollowers
)

// Instagramハッシュタグ数
export const getInstagramHashtag = createSelector(
  getLatestAggregateAmountsState,
  state => state.instagramHashtag
)

// Youtubeチャンネル購読者数
export const getYoutubeChannelSubscribers = createSelector(
  getLatestAggregateAmountsState,
  state => state.youtubeChannelSubscribers
)

// ニュースレター購読者数

export const getNewsletterSubscriber = createSelector(
  getLatestAggregateAmountsState,
  state => state.newsletterSubscriber
)

// Spotifyフォロワー数
export const getSpotifyFollowers = createSelector(
  getLatestAggregateAmountsState,
  state => state.spotifyFollowers
)

// Lineフォロワー数
export const getLineFollowers = createSelector(
  getLatestAggregateAmountsState,
  state => state.lineFollowers
)

// Tiktokフォロワー数
export const getTiktokFollowers = createSelector(
  getLatestAggregateAmountsState,
  state => state.tiktokFollowers
)

export const getLoading = createSelector(
  getLatestAggregateAmountsState,
  state => state.loading
)

export const getLoaded = createSelector(
  getLatestAggregateAmountsState,
  state => state.loaded
)

export const getLatestAggregatedAmounts = createSelector(
  getLatestAggregateAmountsState,
  getCriteriaFrom,
  getCriteriaTo,
  (state, from, to) => {
    const data = []
    data.push([
      '集計期間',
      moment(from).format('YYYY-MM-DD') + ' ~ ' + moment(to).format('YYYY-MM-DD'),
    ])
    data.push([])
    data.push(['集計種別', '単位', '数量', '先週比（％）'])
    state.twitterFollowers.map(item =>
      data.push(
        convert2AggregatedDataArray(
          item.source == null
            ? 'Twitter'
            : 'Twitter' + '（' + item.userName == null
            ? item.objectId
            : item.userName + '）',
          'フォロワー数',
          item.units,
          item.rate,
          item.hasUnits
        )
      )
    )
    state.facebookFollowers.map(item =>
      data.push(
        convert2AggregatedDataArray(
          item.source == null
            ? 'Facebook'
            : 'Facebook' + '（' + item.userName == null
            ? item.objectId
            : item.userName + '）',
          'フォロワー数',
          item.units,
          item.rate,
          item.hasUnits
        )
      )
    )
    state.instagramFollowers.map(item =>
      data.push(
        convert2AggregatedDataArray(
          item.source == null
            ? 'Instagram'
            : 'Instagram' + '（' + item.userName == null
            ? item.objectId
            : item.userName + '）',
          'フォロワー数',
          item.units,
          item.rate,
          item.hasUnits
        )
      )
    )
    data.push(
      convert2AggregatedDataArray(
        'Instagram ハッシュタグ',
        '投稿数',
        state.instagramHashtag.units,
        state.instagramHashtag.rate,
        state.instagramHashtag.hasUnits
      )
    )
    data.push(
      convert2AggregatedDataArray(
        'LINE',
        'フォロワー数',
        state.lineFollowers.units,
        state.lineFollowers.rate,
        state.lineFollowers.hasUnits
      )
    )
    data.push(
      convert2AggregatedDataArray(
        'Youtube チャンネル',
        '登録者数',
        state.youtubeChannelSubscribers.units,
        state.youtubeChannelSubscribers.rate,
        state.youtubeChannelSubscribers.hasUnits
      )
    )
    data.push(
      convert2AggregatedDataArray(
        'Spotify',
        'フォロワー数',
        state.spotifyFollowers.units,
        state.spotifyFollowers.rate,
        state.spotifyFollowers.hasUnits
      )
    )
    data.push(
      convert2AggregatedDataArray(
        'TikTok',
        'フォロワー数',
        state.tiktokFollowers.units,
        state.tiktokFollowers.rate,
        state.tiktokFollowers.hasUnits
      )
    )
    data.push(
      convert2AggregatedDataArray(
        'ニュースレター',
        '購読者数',
        state.newsletterSubscriber.units,
        state.newsletterSubscriber.rate,
        state.newsletterSubscriber.hasUnits
      )
    )

    return data
  }
)

function convert2AggregatedDataArray(
  legend: string,
  unit: string,
  units: number,
  rate: number,
  hasUnits: boolean
): any[] {
  if (hasUnits) {
    return [legend, unit, units, rate]
  }
  return [legend, unit, '', '']
}
