import { createAction, props } from '@ngrx/store'
import { Units } from '../models/units.model'

export const loadUnitsGraphsSuccess = createAction(
  '[UnitsGraph] Load UnitsGraphs success',
  props<{ units: Units[] }>()
)
export const loadUnitsGraphsFailure = createAction(
  '[UnitsGraph] Load UnitsGraphs failure',
  props<{ error: any }>()
)
