import { createSelector, createFeatureSelector } from '@ngrx/store'
import * as fromTwitterTagCloud from '../reducers/twitter-tag-cloud.reducer'
import { CloudData } from 'angular-tag-cloud-module'
import { getCriteriaFrom, getCriteriaTo } from './criteria.selector'
import * as moment from 'moment'

export const getTwitterTagCloudState = createFeatureSelector<fromTwitterTagCloud.State>(
  'twitterTagCloud'
)

const colorTable: string[] = [
  '#775D67',
  '#775D67',
  '#775D67',
  '#775D67',
  '#775D67',
  '#CA4775',
  '#CA4775',
  '#CA4775',
  '#FFA000',
  '#FFA000',
  '#FF6F00',
]

export const getCloudData = createSelector(
  getTwitterTagCloudState,
  state => {
    const cd: CloudData[] = []
    if (state.twitterTagCloud.length > 0) {
      const sliceData = state.twitterTagCloud.slice(0, 50)
      sliceData.forEach((item, index) => {
        cd.push(convertCloudData(item, index))
      })
    }
    return cd
  }
)

function convertCloudData(obj: any, index: number): CloudData {
  let wei = 0
  if (index === 0) {
    wei = 50
  } else if (index < 5) {
    wei = 35
  } else if (index < 15) {
    wei = 25
  } else if (index < 40) {
    wei = 20
  } else {
    wei = 10
  }
  const data: CloudData = {
    text: obj.text,
    weight: wei,
    color: colorTable[Math.floor(((50 - index) / 50) * 10)],
  }

  return data
}

export const getLoading = createSelector(
  getTwitterTagCloudState,
  state => state.loading
)

export const getLoaded = createSelector(
  getTwitterTagCloudState,
  state => state.loaded
)

export const getTwitterTagCloudCsv = createSelector(
  getTwitterTagCloudState,
  getCriteriaFrom,
  getCriteriaTo,
  (state, from, to) => {
    const data = []
    data.push([
      '集計期間',
      moment(from).format('YYYY-MM-DD') + ' ~ ' + moment(to).format('YYYY-MM-DD'),
    ])
    data.push([])
    data.push(['キーワード', '投稿数'])
    state.twitterTagCloud.map(item => {
      data.push([item.text, item.weight])
    })
    return data
  }
)
