import { RouterAction, MinimalRouterStateSnapshot, ROUTER_NAVIGATED } from '@ngrx/router-store'
import { openEventDrawer, closeEventDrawer } from 'src/app/actions/shell.actions'
import { TypedAction } from '@ngrx/store/src/models'

const APP_TITLE = 'アーティスト ダッシュボード'

export type ArtistPageTypes = 'sales' | 'data' | 'graph' | 'charts' | 'demographics'

export interface State {
  title: string
  isArtistPage: boolean
  artistPage: ArtistPageTypes
  eventDrawerOpened: boolean
}

export const initialState: State = {
  title: APP_TITLE,
  isArtistPage: false,
  artistPage: 'sales',
  eventDrawerOpened: false,
}

export function reducer(
  state = initialState,
  action: RouterAction<MinimalRouterStateSnapshot> | TypedAction<string>
): State {
  switch (action.type) {
    case ROUTER_NAVIGATED:
      return getState(state, (action as RouterAction<MinimalRouterStateSnapshot>).payload.event.url)
    case openEventDrawer.type:
      return { ...state, eventDrawerOpened: true }
    case closeEventDrawer.type:
      return { ...state, eventDrawerOpened: false }
  }
  return state
}

const getState = (state: State, url: string): State => {
  if (!/^.*\/artists\/.*$/.test(url)) {
    return {
      ...state,
      title: APP_TITLE,
      isArtistPage: false,
      artistPage: 'sales',
      eventDrawerOpened: false,
    }
  }

  if (url.endsWith('sales')) {
    return { ...state, title: APP_TITLE, artistPage: 'sales', isArtistPage: true }
  } else if (url.endsWith('data')) {
    return { ...state, title: APP_TITLE, artistPage: 'data', isArtistPage: true }
  } else if (url.endsWith('graph')) {
    return { ...state, title: APP_TITLE, artistPage: 'graph', isArtistPage: true }
  } else if (url.endsWith('charts')) {
    return { ...state, title: APP_TITLE, artistPage: 'charts', isArtistPage: true }
  } else if (url.endsWith('demographics')) {
    return { ...state, title: APP_TITLE, artistPage: 'demographics', isArtistPage: true }
  } else {
    return { ...state, title: APP_TITLE, artistPage: 'sales', isArtistPage: true }
  }
}
