import { Component, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/reducers'
import { Artist } from 'src/app/models/artist.model'
import { getSelectedArtist } from 'src/app/selectors/artist.selector'
import {
  getCriteriaFrom,
  getCriteriaTo,
  getCriteriaPreviousFrom,
  getCriteriaPreviousTo,
  getCriteriaFromBehindOneDay,
} from 'src/app/selectors/criteria.selector'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ProjectSale } from 'src/app/models/project-sale.model'
import {
  getProjectSales,
  getProjectSaleLoading,
  getProjectSaleLoaded,
  getProjectTrendsCsv,
} from 'src/app/selectors/project-sale.selector'
import { Title } from '@angular/platform-browser'

@Component({
  selector: 'app-sp-project-sales',
  templateUrl: './sp-project-sales.component.html',
  styleUrls: ['./sp-project-sales.component.scss'],
})
export class SpProjectSalesComponent implements OnInit {
  criteriaFrom$: Observable<Date>
  criteriaTo$: Observable<Date>
  criteriaPreviousFrom$: Observable<Date>
  criteriaPreviousTo$: Observable<Date>
  criteriaFromBehindOneDay$: Observable<Date>

  projectSales$: Observable<ProjectSale[]>
  loading$: Observable<boolean>
  loaded$: Observable<boolean>
  today = new Date()

  csvId = 'trend'
  csvFileName = '作品動向'
  csvData$: Observable<any[]>
  csvLoading$: Observable<boolean>

  selectedArtist$: Observable<Artist>

  constructor(private store: Store<AppState>, private titleService: Title) {}

  ngOnInit() {
    this.criteriaFrom$ = this.store.select(getCriteriaFrom)
    this.criteriaTo$ = this.store.select(getCriteriaTo)
    this.criteriaPreviousFrom$ = this.store.select(getCriteriaPreviousFrom)
    this.criteriaPreviousTo$ = this.store.select(getCriteriaPreviousTo)
    this.criteriaFromBehindOneDay$ = this.store.select(getCriteriaFromBehindOneDay)

    this.selectedArtist$ = this.store.select(getSelectedArtist)

    this.projectSales$ = this.store.select(getProjectSales)
    this.loading$ = this.store.select(getProjectSaleLoading)
    this.loaded$ = this.store.select(getProjectSaleLoaded)
    this.csvData$ = this.store.select(getProjectTrendsCsv)
    this.selectedArtist$ = this.store.select(getSelectedArtist)
    this.selectedArtist$.subscribe(artist =>
      this.titleService.setTitle(artist.nameMain + ' | ' + environment.title)
    )
  }

  getJackeshaUrl(sale: ProjectSale): string {
    if (!sale) {
      return ''
    }

    if (!sale.upc) {
      return `${environment.storage.web.root}/jacket_pictures/no_printing.png`
    }

    return `${environment.storage.web.root}/jacket_pictures/${sale.projectId}.JPEG`
  }

  onJackeshaNotFound(event: any): void {
    event.target.src = `${environment.storage.web.root}/jacket_pictures/no_printing.png`
  }
}
