import { Component, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/reducers'
import { Observable } from 'rxjs'
import { ProjectSale } from 'src/app/models/project-sale.model'
import {
  getProjectSales,
  getProjectSaleLoading,
  getProjectSaleLoaded,
  getProjectTrendsCsv,
} from 'src/app/selectors/project-sale.selector'
import { Artist } from 'src/app/models/artist.model'
import { getSelectedArtist } from 'src/app/selectors/artist.selector'

@Component({
  selector: 'app-recent-project-trends',
  templateUrl: './recent-project-trends.component.html',
  styleUrls: ['./recent-project-trends.component.scss'],
})
export class RecentProjectTrendsComponent implements OnInit {
  projectSales$: Observable<ProjectSale[]>
  loading$: Observable<boolean>
  loaded$: Observable<boolean>
  today = new Date()

  csvId = 'trend'
  csvFileName = '作品動向'
  csvData$: Observable<any[]>
  csvLoading$: Observable<boolean>
  selectedArtist$: Observable<Artist>

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.projectSales$ = this.store.select(getProjectSales)
    this.loading$ = this.store.select(getProjectSaleLoading)
    this.loaded$ = this.store.select(getProjectSaleLoaded)
    this.csvData$ = this.store.select(getProjectTrendsCsv)
    this.selectedArtist$ = this.store.select(getSelectedArtist)
  }
}
