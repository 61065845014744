import { Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy } from '@angular/core'
import { MediaObserver, MediaChange } from '@angular/flex-layout'
import { ChartPart } from 'src/app/models/chart.model'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-chart-rank-items',
  template: `
    <mat-card>
      <div fxLayout="row wrap" fxLayoutGap="8px" fxLayoutAlign="start center">
        <h3>
          {{ chartName }}
        </h3>
        <div fxFlex="1 1 auto"></div>
        <mat-icon class="help" *ngIf="help" [matTooltip]="help">help_outline</mat-icon>
        <ng-container *ngIf="!dataCollections; then dateNoFix; else dateFix"></ng-container>
      </div>
      <div class="chart-rank-card">
        <mat-list *ngFor="let info of infos">
          <div mat-subheader>{{ info.chartNameJp }}</div>
          <mat-list-item *ngFor="let rankingItem of info.chartItems">
            <mat-icon mat-list-icon>{{ info.icon }}</mat-icon>
            <div mat-list-icon class="ranking">
              <span class="rank-number">{{ rankingItem.currentRank }}</span>
              位
            </div>
            <div class="mat-list-text">
              <h4 class="title" mat-line>
                {{ rankingItem.title }}
              </h4>
              <p ngClass class="rank" mat-line>
                <ng-container *ngIf="media.isActive('lt-md')">
                  <span class="sp-prev-number">
                    （ 前週
                    {{ rankingItem.previousRank === null ? '-' : rankingItem.previousRank }}
                    位、最高 {{ rankingItem.rankTop }} 位 ）
                  </span>
                </ng-container>
                <ng-container *ngIf="!media.isActive('lt-md')">
                  （ 前週
                  {{ rankingItem.previousRank === null ? '-' : rankingItem.previousRank }} 位、最高
                  {{ rankingItem.rankTop }} 位 ）
                </ng-container>
              </p>
              <ng-container *ngIf="rankingItem.creations_last_7_days">
                <h5 class="creations" mat-line>
                  {{ 'Creations ' + (rankingItem.creations_last_7_days | number) }}
                </h5>
              </ng-container>
            </div>
          </mat-list-item>
        </mat-list>
      </div>
    </mat-card>

    <ng-template #dateFix>
      <img
        class="datafix_icon"
        matTooltip="各項目において選択した期間のデータが全部揃っている場合に、チェックマークが表示されます。"
        src="./assets/data_collections.png"
      />
    </ng-template>

    <ng-template #dateNoFix></ng-template>
  `,
  styleUrls: ['./chart-rank-items.component.scss'],
})
export class ChartRankItemsComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = []

  constructor(public media: MediaObserver) {
    this.subscriptions.push(media.media$.subscribe((_: MediaChange) => {}))
  }

  @Input()
  infos: ChartPart[]

  @Input()
  chartName: string

  @Input()
  help: string

  @Input()
  dataCollections: boolean

  ngOnInit() {}

  ngOnDestroy() {
    this.subscriptions.forEach(it => it.unsubscribe())
  }
}
