import { Action, createReducer, on } from '@ngrx/store'
import * as TwitterTagCloudActions from '../actions/twitter-tag-cloud.actions'
import * as CriteriaActions from '../actions/criteria.actions'
import { TwitterTagCloud } from '../models/twitter-tag-cloud.model'

export interface State {
  twitterTagCloud: TwitterTagCloud[]
  loading: boolean
  loaded: boolean
}

export const initialState: State = {
  twitterTagCloud: [],
  loading: false,
  loaded: false,
}

const twitterTagCloudReducer = createReducer(
  initialState,

  on(CriteriaActions.refreshData, (state, action) => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(TwitterTagCloudActions.loadTwitterTagCloudSuccess, (state, action) => ({
    twitterTagCloud: action.tagcloud,
    loaded: true,
    loading: false,
  })),
  on(TwitterTagCloudActions.loadTwitterTagCloudFailure, (state, action) => state)
)

export function reducer(state: State | undefined, action: Action) {
  return twitterTagCloudReducer(state, action)
}
