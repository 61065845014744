import { Action, createReducer, on } from '@ngrx/store'
import {
  selectEventDate,
  unselectEventDate,
  loadEventsSuccess,
  loadEventsFailure,
} from 'src/app/actions/event.actions'
import { Event } from 'src/app/models/event.model'
import { refreshData } from 'src/app/actions/criteria.actions'

export interface State {
  events: Event[]
  selectedDate?: string
  loading: boolean
  loaded: boolean
}

export const initialState: State = {
  events: [],
  selectedDate: null,
  loading: false,
  loaded: false,
}

const eventsReducer = createReducer(
  initialState,

  on(refreshData, state => ({ ...state, loading: true, loaded: false })),

  on(loadEventsSuccess, (state, action) => ({
    ...state,
    events: action.events,
    loading: false,
    loaded: true,
  })),

  on(loadEventsFailure, (state, _) => ({
    ...state,
    events: [],
    loading: false,
    loaded: false,
  })),

  on(selectEventDate, (state, action) => ({ ...state, selectedDate: action.eventDate })),

  on(unselectEventDate, state => ({ ...state, selectedDate: null }))
)

export function reducer(state: State | undefined, action: Action) {
  return eventsReducer(state, action)
}
